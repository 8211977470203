import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AccuvThemeProvider, AccuvTypography } from '@accuv/shared-components';
import logo from 'assets/error-pages/accuv-error-logo.svg';
import image from 'assets/error-pages/accuv-maintenance-image.svg';

const useStyles = makeStyles(() => ({
  frame: {
    minHeight: '100vh',
    width: '100vw',
    position: 'relative',
    boxSizing: 'border-box',
    padding: '5.729vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  accuvErrorLogo: {
    position: 'absolute',
    top: 0,
    right: 0,
    maxWidth: '70vw',
    width: 'calc(160px + 15vw)',
  },
  accuvErrorImage: {
    maxWidth: '80%',
    width: '25%',
    marginTop: '4vw',
    marginBottom: 20,
  },
  errorMessageTitle: {
    margin: 0,
    fontSize: 'calc(48px + 2vw)',
    lineHeight: 1,
    color: '#250E62',
    fontWeight: 300,

    '& strong': {
      fontWeight: 700,
    },
  },
  errorMessageSubtitle: {
    margin: '20px 0 0',
    fontSize: 'calc(30px + 1vw)',
    lineHeight: 1,
    color: '#5839A0',
    fontWeight: 700,
  },
  errorMessageCta: {
    margin: '80px 0 0',
    fontSize: 22,
    color: '#250E62',
  },
  decoration: {
    width: 300,
    maxWidth: '100%',
    height: 2,
    display: 'block',
    background: '#250E62',
    marginBBottom: 30,
  },
}));

const NotFound = () => {
  const classes = useStyles();
  return (
    <AccuvThemeProvider>
      <div className={classes.frame}>
        <img className={classes.accuvErrorLogo} src={logo} alt="accuv-logo" />
        <div className={classes.accuvErrorImageWrapper}>
          <img className={classes.accuvErrorImage} src={image} alt="error" />
        </div>
        <div className={classes.accuvErrorCopyWrapper}>
          <AccuvTypography className={classes.errorMessageTitle}>
            Under <strong>Maintenance</strong>
          </AccuvTypography>
          <AccuvTypography className={classes.errorMessageSubtitle}>
            We’ll be back shortly
          </AccuvTypography>
          <AccuvTypography className={classes.errorMessageCta}>
            <span className={classes.decoration}></span>Maintenance scheduled from Friday,
            25 Oct 5 PM CT till Monday, 28 Oct 6 AM CT
          </AccuvTypography>
        </div>
      </div>
    </AccuvThemeProvider>
  );
};

export default NotFound;
