import WidgetsIcon from '@material-ui/icons/WidgetsOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntennaOutlined';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileOutlined';

const mockTodo = [
  {
    title: 'PO Approval: Pending Approval',
    jobNumber: 'MTSRA374002 - Home Cost Center',
    vendor: 'Kaseya US LLC',
    amount: '4797.59',
    dateTime: '2:26pm',
    icon: ShoppingCartIcon,
    color: 'secondary',
  },
  {
    title: 'Closeout Approval: Pending Approval',
    jobNumber: 'TSLLCS238302-A',
    vendor: 'Kaseya US LLC',
    amount: '12.59',
    dateTime: '12:45am',
    icon: WidgetsIcon,
    color: 'primary',
  },
  {
    title: 'VQA Approval: Pending Approval',
    jobNumber: 'SGA95000 - Home Cost Center',
    vendor: 'Kaseya US LLC',
    amount: '4797.59',
    dateTime: '06/19/2020',
    icon: SettingsInputAntennaIcon,
    color: 'gray',
  },
  {
    title: 'Document Approval: Pending Approval',
    jobNumber: 'MTSRA374002 - Home Cost Center',
    vendor: 'Kaseya US LLC',
    amount: '4797.59',
    dateTime: '05/27/2020',
    icon: InsertDriveFileIcon,
    color: 'accent',
  },
];

const mockTodoArchive = [
  {
    title: 'VQA Approval: Pending Approval',
    jobNumber: 'SGA95000 - Home Cost Center',
    vendor: 'Kaseya US LLC',
    amount: '4797.59',
    dateTime: '06/19/2020',
    icon: SettingsInputAntennaIcon,
    color: 'gray',
  },
  {
    title: 'Closeout Approval: Pending Approval',
    jobNumber: 'TSLLCS238302-A',
    vendor: 'Kaseya US LLC',
    amount: '12.59',
    dateTime: '12:45am',
    icon: WidgetsIcon,
    color: 'primary',
  },
  {
    title: 'Document Approval: Pending Approval',
    jobNumber: 'MTSRA374002 - Home Cost Center',
    vendor: 'Kaseya US LLC',
    amount: '4797.59',
    dateTime: '05/27/2020',
    icon: InsertDriveFileIcon,
    color: 'accent',
  },
  {
    title: 'PO Approval: Pending Approval',
    jobNumber: 'MTSRA374002 - Home Cost Center',
    vendor: 'Kaseya US LLC',
    amount: '4797.59',
    dateTime: '2:26pm',
    icon: ShoppingCartIcon,
    color: 'secondary',
  },
];

export { mockTodo, mockTodoArchive };
