import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  AccuvListItem,
  AccuvTypography,
  AccuvListItemIcon,
} from '@accuv/shared-components';

const useStyles = makeStyles((theme) => ({
  listItemIconWrapper: {
    minWidth: 40,
  },
  skeletonItem: {
    borderBottom: `1px solid ${theme.palette.gray.border}`,
    minHeight: '70px',
  },
  skeletonContainer: {
    width: '100%',
  },
  skeletonText: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

const AnnouncementSkeleton = () => {
  const classes = useStyles();
  let skeletonItems = [1, 2, 3, 4].map((index) => (
    <AccuvListItem className={classes.skeletonItem} key={index}>
      <AccuvListItemIcon className={classes.listItemIconWrapper}>
        <Skeleton animation="wave" variant="circle" width={30} height={30} />
      </AccuvListItemIcon>
      <AccuvTypography
        component="div"
        variant="caption"
        className={classes.skeletonContainer}
      >
        <Skeleton
          animation="wave"
          height={10}
          width="80%"
          className={classes.skeletonText}
        />
        <Skeleton
          animation="wave"
          height={10}
          width="40%"
          className={classes.skeletonText}
        />
        <Skeleton
          animation="wave"
          height={10}
          width="70%"
          className={classes.skeletonText}
        />
      </AccuvTypography>
    </AccuvListItem>
  ));

  return <>{skeletonItems}</>;
};

export default AnnouncementSkeleton;
