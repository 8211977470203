/**
 * App Config File
 */
const AppConfig = {
  brandName: 'AccuV',
  pageTitle: 'AccuV | Redefining Possible',
  copyRightText: 'Copyright © 2020, All Rights Reserved.',
  navCollapsed: true,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
  },
  firebase: {
    apiKey: 'AIzaSyDopiNO-jP3cQqA-Bzdv2l15GP22OGVW-c',
    projectId: 'accuv-3eb07',
    messagingSenderId: '384383994592',
    appId: '1:384383994592:android:6adccb238ab11d7de95e85',
  },
};

export default AppConfig;
