/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AccuvListItem,
  AccuvListItemText,
  AccuvTypography,
  AccuvIconButton,
} from '@accuv/shared-components';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemSecondaryAction, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Moment from 'react-moment';
import { useToast, ADD } from 'providers/ToastProvider';
import { Link } from 'react-router-dom';
import BackgroundJobStates from 'constants/BackgroundJobStates';
import { ImportResultsRoutes } from 'components/BulkImport/ImportResults';

const useStyles = makeStyles((theme) => ({
  listItem: {
    background: '#F0F1F8',
    paddingRight: theme.spacing(10),
    borderBottom: `1px solid ${theme.palette.gray.border}`,
  },
  lastListItem: {
    borderBottom: 'none',
  },
  listItemText: {
    '& .mainspa-MuiListItemText-primary': {
      fontSize: 12,
      color: theme.palette.gray.text,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .mainspa-MuiListItemText-secondary': {
      fontSize: 11,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  listItemRead: {
    background: theme.palette.white,
    '& .mainspa-MuiListItemText-primary': {
      fontWeight: 'normal',
    },
  },
  statusText: {
    fontSize: 11,
    minWidth: 50,
    padding: theme.spacing(0, 1),
    color: theme.palette.gray.text,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  statusTextRead: {
    fontWeight: 'normal',
  },
  runningColor: {
    color: theme.palette.secondary.main,
  },
  errorColor: {
    color: theme.palette.danger.main,
  },
  retryButton: {
    padding: theme.spacing(0.25, 0.5),
    minWidth: 0,
    fontSize: 14,
  },
  retryIcon: {
    fontSize: 16,
    marginRight: 2,
  },
}));

const ImportRequest = ({
  importItem,
  onClick,
  index,
  importsLength,
  markAsUnreadCallback,
  markAsReadCallback,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { toastDispatch } = useToast();

  const handleMoreOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleMarkNotificationAsRead = (read) => {
    handleMoreOptionsClose();
    if (read) {
      markAsUnreadCallback();
    } else {
      markAsReadCallback();
    }
  };

  const handleErrorDetails = () => {
    handleMoreOptionsClose();
    toastDispatch({
      type: ADD,
      payload: {
        content: {
          title: `Error on ${importItem.title}`,
          description: importItem.payload.errorMessage,
        },
        type: 'alert',
      },
    });
  };

  const renderResultLink = () => {
    return (
      <Link
        to={`${ImportResultsRoutes[importItem.title]}/${importItem.id}`}
        target="_blank"
      >
        <MenuItem>Show results in new tab</MenuItem>
      </Link>
    );
  };

  let subtitle = (
    <>
      <Moment format="lll">{importItem.dateTime}</Moment>
      {importItem.payload.programName
        ? ` - ${importItem.payload.programName}`
        : importItem.payload.program
        ? ` - ${importItem.payload.program}`
        : null}
    </>
  );

  return (
    <>
      <AccuvListItem
        button
        key={index}
        className={clsx(
          classes.listItem,
          importItem.read && classes.listItemRead,
          index === importsLength - 1 && classes.lastListItem
        )}
        onClick={() => {
          onClick();
          console.log('Import request click');
          if (importItem.payload.status.toLowerCase() === BackgroundJobStates.FAILED) {
            handleErrorDetails();
          }
        }}
      >
        <AccuvListItemText
          className={classes.listItemText}
          primary={importItem.title}
          secondary={subtitle}
        />
        <AccuvTypography
          className={clsx(
            classes.statusText,
            importItem.payload.status.toLowerCase() === BackgroundJobStates.RUNNING &&
              classes.runningColor,
            importItem.payload.status.toLowerCase() === BackgroundJobStates.FAILED &&
              classes.errorColor,
            importItem.read === true && classes.statusTextRead
          )}
        >
          {importItem.payload.status}
        </AccuvTypography>
        <ListItemSecondaryAction>
          {/* <AccuvButton
              variant="outlined"
              color="secondary"
              className={classes.retryButton}
            >
              <ReplayIcon className={classes.retryIcon} /> Retry
            </AccuvButton> */}
          {importItem.payload.status.toLowerCase() !== BackgroundJobStates.RUNNING ? (
            <AccuvIconButton
              edge="end"
              aria-label="delete"
              onClick={handleMoreOptionsClick}
            >
              <MoreVertIcon />
            </AccuvIconButton>
          ) : null}
        </ListItemSecondaryAction>
      </AccuvListItem>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMoreOptionsClose}
      >
        {importItem.payload.status.toLowerCase() === BackgroundJobStates.FAILED && (
          <MenuItem onClick={handleErrorDetails}>Show error details</MenuItem>
        )}
        {importItem.payload.status.toLowerCase() === BackgroundJobStates.COMPLETED &&
          importItem.payload.results &&
          renderResultLink()}
        {importItem.read && (
          <MenuItem onClick={() => handleMarkNotificationAsRead(true)}>
            Mark as unread
          </MenuItem>
        )}
        {!importItem.read && (
          <MenuItem onClick={() => handleMarkNotificationAsRead(false)}>
            Mark as read
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

ImportRequest.propTypes = {
  importItem: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    dateTime: PropTypes.string,
    read: PropTypes.bool,
    payload: PropTypes.object,
  }),
  onClick: PropTypes.func,
  index: PropTypes.number,
  importsLength: PropTypes.number,
  markAsUnreadCallback: PropTypes.func,
  markAsReadCallback: PropTypes.func,
};

export default ImportRequest;
