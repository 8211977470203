/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import withStyles from '@material-ui/core/styles/withStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ListItemText from '@material-ui/core/ListItemText';
import Fade from '@material-ui/core/Fade';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Select, MenuItem } from '@material-ui/core';
import { useToast, ADD } from 'providers/ToastProvider';
//import { useNavigate } from 'react-router-dom';
import {
  AccuvList,
  AccuvListItem,
  AccuvStatusMessageBox,
} from '@accuv/shared-components';

const textFontSize = 12;

const styles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.lighter,
  },
  rootFocus: {},
  iconButton: {
    color: theme.palette.action.active,
    transform: 'scale(1, 1)',
    transition: theme.transitions.create(['transform', 'color'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
    maxWidth: 30,
    height: 30,
  },
  iconButtonHidden: {
    transform: 'scale(0, 0)',
    '& > $icon': {
      opacity: 0,
    },
  },
  searchIconButton: {
    marginLeft: theme.spacing(0.7),
    marginRight: theme.spacing(0.7),
  },
  spinningIconButton: {
    marginLeft: theme.spacing(0.7),
    marginRight: theme.spacing(0.7),
    width: 40,
    textAlign: 'center',
  },
  icon: {
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  input: {
    height: 30,
    margin: 'auto 10px',
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: 250,
    },
  },
  inputFocus: {
    [theme.breakpoints.up('md')]: {
      width: 360,
    },
  },
  searchContainer: {
    width: '100%',
  },
  progress: {
    position: 'relative',
    top: 5,
    zIndex: 1,
  },
  listContainer: {
    fontSize: textFontSize,
    '& .MuiTypography-body1': {
      fontSize: textFontSize,
    },
  },
  listContainerSelect: {
    paddingLeft: '10px !important',
  },
  filterLabel: {
    fontSize: textFontSize,
  },
  rootResults: {
    maxWidth: 410,
    marginLeft: 40,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  contentResults: {
    maxHeight: 350,
    padding: '0!important',
    overflow: 'auto',
    ...theme.mixins.scrollbar,
  },
  searchButtonContainer: {
    backgroundColor: theme.palette.primary.lighter,
    width: 40,
  },
  closeButtonContainer: {},
  focusColor: {
    backgroundColor: theme.palette.common.white,
  },
});

/**
 * Material design search bar
 * @see [Search patterns](https://material.io/archive/guidelines/patterns/search.html)
 */
const SearchBar = React.forwardRef(
  (
    {
      cancelOnEscape,
      className,
      classes,
      closeIcon,
      disabled,
      onCancelSearch,
      onRequestSearch,
      searchIcon,
      style,
      options,
      results,
      searching,
      ...inputProps
    },
    ref
  ) => {
    const inputRef = React.useRef();
    const [focus, setFocus] = React.useState(false);
    const [value, setValue] = React.useState(inputProps.value);
    const [type, setType] = React.useState(options[0]?.title);
    const [searchResults, setSearchResults] = React.useState(results);
    //const navigate = useNavigate();
    const { toastDispatch } = useToast();

    React.useEffect(() => {
      if (results) {
        setFocus(true);
      }
      setSearchResults(results);
    }, [results]);

    React.useEffect(() => {
      setValue(inputProps.value);
    }, [inputProps.value]);

    const handleOptionsChange = (event) => {
      setType(event.target.value);
    };

    const handleFocus = React.useCallback(
      (e) => {
        setFocus(true);
        if (inputProps.onFocus) {
          inputProps.onFocus(e);
        }
      },
      [inputProps]
    );

    const handleBlur = React.useCallback(
      (e) => {
        setValue((v) => v.trim());
        if (inputProps.onBlur) {
          inputProps.onBlur(e);
        }
      },
      [inputProps]
    );

    const handleInput = React.useCallback(
      (e) => {
        setValue(e.target.value);
        if (inputProps.onChange) {
          inputProps.onChange(e.target.value);
        }
      },
      [inputProps]
    );

    const handleCancel = React.useCallback(() => {
      setValue('');
      if (onCancelSearch) {
        onCancelSearch();
      }
    }, [onCancelSearch]);

    const handleRequestSearch = React.useCallback(() => {
      if (onRequestSearch) {
        if (value === '') {
          toastDispatch({
            type: ADD,
            payload: {
              content: 'Please, type something to search',
              type: 'info',
            },
          });
        } else {
          onRequestSearch(value, type);
        }
      }
    }, [onRequestSearch, toastDispatch, value, type]);

    const handleKeyUp = React.useCallback(
      (e) => {
        if (e.charCode === 13 || e.key === 'Enter') {
          handleRequestSearch();
        } else if (cancelOnEscape && (e.charCode === 27 || e.key === 'Escape')) {
          handleCancel();
        }
        if (inputProps.onKeyUp) {
          inputProps.onKeyUp(e);
        }
      },
      [handleRequestSearch, cancelOnEscape, handleCancel, inputProps]
    );

    const handleClickAway = () => {
      setFocus(false);
    };

    const handleCloseSearch = () => {
      setValue('');
      setSearchResults(null);
      setFocus(false);
    };

    const handleResultClick = (route, program, programName) => {
      console.info(`You clicked ${route} | ${program}`);
      // TODO: do it properly once the PO module is integrated
      if (program) {
        localStorage.setItem('program', program);
        localStorage.setItem('program_name', programName);
      }
      // use window.open to open a new tab of search, this temporarily replaces the orginial line of:
      // navigate(route);
      // TODO: use a better approach if possble
      window.open(route);
      setFocus(false);
    };

    React.useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current.focus();
      },
      blur: () => {
        inputRef.current.blur();
      },
    }));

    let resultItems;
    if (searchResults && searchResults.length > 0) {
      resultItems = searchResults.map((result, index) => {
        return (
          <AccuvListItem
            key={index}
            button
            onClick={() =>
              handleResultClick(result.route, result.program, result.programName)
            }
          >
            <ListItemText primary={result.title} />
          </AccuvListItem>
        );
      });
    } else {
      resultItems = (
        <div className={classes.message}>
          <AccuvStatusMessageBox className={classes.messageText}>
            No results
          </AccuvStatusMessageBox>
        </div>
      );
    }

    return (
      <ClickAwayListener onClickAway={handleClickAway} disableReactTree={true}>
        <div>
          <Paper
            className={classNames(classes.root, focus && classes.rootFocus, className)}
            style={style}
            square
          >
            {!searching && (
              <div className={classes.searchButtonContainer}>
                <IconButton
                  onClick={handleRequestSearch}
                  className={classNames(classes.iconButton, classes.searchIconButton)}
                  disabled={disabled}
                >
                  {React.cloneElement(searchIcon, {
                    classes: { root: classes.icon },
                  })}
                </IconButton>
              </div>
            )}
            {searching && (
              <div className={classes.searchButtonContainer}>
                <div
                  className={classNames(classes.iconButton, classes.spinningIconButton)}
                >
                  <CircularProgress size={20} className={classes.progress} />
                </div>
              </div>
            )}
            <div
              className={classNames(classes.searchContainer, focus && classes.focusColor)}
            >
              <Input
                {...inputProps}
                inputRef={inputRef}
                onBlur={handleBlur}
                value={value}
                onChange={handleInput}
                onKeyUp={handleKeyUp}
                onFocus={handleFocus}
                disableUnderline
                disabled={disabled}
                className={clsx(classes.input, focus && classes.inputFocus)}
              />
            </div>
            <div
              className={clsx(classes.closeButtonContainer, focus && classes.focusColor)}
            >
              <IconButton
                onClick={handleCancel}
                className={classNames(classes.iconButton, {
                  [classes.iconButtonHidden]: value === '',
                })}
                disabled={disabled}
              >
                {React.cloneElement(closeIcon, {
                  classes: { root: classes.icon },
                })}
              </IconButton>
            </div>
            {options && (
              <Fade in={focus}>
                <Select
                  className={classes.listContainer}
                  value={type}
                  onChange={handleOptionsChange}
                  displayEmpty
                  disableUnderline
                  MenuProps={{
                    disablePortal: true,
                  }}
                  classes={{
                    root: classes.listContainerSelect,
                  }}
                  IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
                >
                  {options.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.title}
                      className={classes.listContainer}
                    >
                      <ListItemText
                        primary={item.title}
                        className={classes.listContainer}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </Fade>
            )}
          </Paper>
          {focus && searchResults && (
            <Fade in={focus}>
              <Card className={classes.rootResults}>
                <CardContent className={classes.contentResults}>
                  <AccuvList dense component="nav" aria-label="secondary mailbox folders">
                    {resultItems}
                  </AccuvList>
                </CardContent>
                <CardActions style={{ justifyContent: 'flex-end' }}>
                  <Button
                    end="true"
                    size="small"
                    color="primary"
                    onClick={handleCloseSearch}
                  >
                    Close
                  </Button>
                </CardActions>
              </Card>
            </Fade>
          )}
        </div>
      </ClickAwayListener>
    );
  }
);

SearchBar.defaultProps = {
  className: '',
  closeIcon: <ClearIcon />,
  disabled: false,
  placeholder: 'Search',
  searchIcon: <SearchIcon />,
  style: null,
  value: '',
  searching: false,
  results: null,
};

SearchBar.propTypes = {
  /** Whether to clear search on escape */
  cancelOnEscape: PropTypes.bool,
  /** Override or extend the styles applied to the component. */
  classes: PropTypes.object.isRequired,
  /** Custom top-level class */
  className: PropTypes.string,
  /** Override the close icon. */
  closeIcon: PropTypes.node,
  /** Disables text field. */
  disabled: PropTypes.bool,
  /** Fired when the search is cancelled. */
  onCancelSearch: PropTypes.func,
  /** Fired when the text value changes. */
  onChange: PropTypes.func,
  /** Fired when the search icon is clicked. */
  onRequestSearch: PropTypes.func,
  /** Sets placeholder text for the embedded text field. */
  placeholder: PropTypes.string,
  /** Override the search icon. */
  searchIcon: PropTypes.node,
  /** Override the inline-styles of the root element. */
  style: PropTypes.object,
  /** Options to show in the dropdown. */
  options: PropTypes.array,
  /** Options to show in the dropdown. */
  results: PropTypes.array,
  /** searching state. */
  searching: PropTypes.bool,
  /** The value of the text field. */
  value: PropTypes.string,
};

export default withStyles(styles)(SearchBar);
