/* eslint-disable no-undef */
import React, { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useAppState } from 'providers/AppProvider/AppProvider';
import { toggleMonolithicModule } from 'providers/AppProvider/ActionCreators';
import ProgramService from 'services/Program/ProgramService';
import { ProgramSelectorFullPage } from 'components';
import { AccuvLoadingIcon } from '@accuv/shared-components';
import { Unauthorized } from 'pages';
import env from '@beam-australia/react-env';
import { hasAccessToModule } from 'services/Authorization/UserAccess';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: 'calc(100vh - 48px) !important',
  },
  loading: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  error: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const MonoFrontendIFrame = ({
  id,
  name,
  viewUrl,
  subModule,
  didMount,
  willUnmount,
  programAgnostic,
}) => {
  console.log('MonoFrontendIFrame rendering...');
  document.title = 'AccuV | ' + name;
  const mvcBasePath = env('MONOLITHIC_BASE_ENDPOINT');
  const classes = useStyles();
  const selectedProgram = ProgramService.getSelectedProgramId();
  const monoEl = useRef();
  const [monoState, setMonoState] = useState({
    status: 'start',
    program: selectedProgram,
  });
  if (monoState.program !== selectedProgram) {
    setMonoState({
      status: 'start',
      program: selectedProgram,
    });
  }
  const [state, dispatch] = useAppState();

  //componentDidUpdate
  useEffect(() => {
    const element = monoEl.current;
    if (element) {
      element.addEventListener('loaded', didMount);
    }
    if (monoState.status === 'done' && monoState.program && programAgnostic === false) {
      if (state.monolithicModule.opened === false) {
        //We are notifying the layout that a mono module was opened mainly to show the program selector in the header
        dispatch(toggleMonolithicModule(id, true));
      }
    }
  });

  //componentWillUnmount
  useEffect(() => {
    const element = monoEl.current;
    // do any cleanup after module is unmount
    return function cleanup() {
      if (willUnmount) willUnmount();
      if (element) {
        element.removeEventListener('loaded', didMount);
      }
      dispatch(toggleMonolithicModule(id, false));
    };
  }, [dispatch, didMount, willUnmount, id]);

  //Check permission to the module
  if (
    state.permissions &&
    !hasAccessToModule(id, state.permissions, monoState.program) &&
    monoState.status !== 'unauthorized'
  ) {
    setMonoState({
      ...monoState,
      status: 'unauthorized',
    });
  }

  if (state.permissions && !monoState.program && programAgnostic === false) {
    let modulePrograms = state.permissions[id];
    return <ProgramSelectorFullPage programs={modulePrograms}></ProgramSelectorFullPage>;
  }

  if (monoState.status === 'start') {
    setMonoState({
      ...monoState,
      status: 'loading',
    });
  }
  let monolithicModule;
  if (monoState.status === 'unauthorized') {
    monolithicModule = <Unauthorized></Unauthorized>;
  } else {
    const authToken = localStorage.getItem('bearer');
    const url = `${mvcBasePath}${viewUrl}?projectId=${selectedProgram}&moduleId=${id}&subModule=${subModule}&auth=${authToken}`;
    monolithicModule = (
      <div className={classes.root}>
        {monoState.status === 'loading' ? (
          <div className={classes.loading}>
            <AccuvLoadingIcon></AccuvLoadingIcon>
          </div>
        ) : null}
        <iframe
          src={url}
          scrolling="no"
          frameBorder="0"
          style={{ overflow: 'hidden', width: '100%', height: '100%' }}
          onLoad={() => setMonoState({ ...monoState, status: 'done' })}
        />
      </div>
    );
  }
  return monolithicModule;
};

MonoFrontendIFrame.defaultProps = {
  programAgnostic: false,
  didMount: () => {},
  willUnmount: () => {},
};

MonoFrontendIFrame.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  viewUrl: PropTypes.string.isRequired,
  didMount: PropTypes.func,
  willUnmount: PropTypes.func,
  programAgnostic: PropTypes.bool,
};

export default memo(MonoFrontendIFrame);
