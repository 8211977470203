import React, { useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import {
  AccuvListItem,
  AccuvListItemText,
  AccuvListItemIcon,
} from '@accuv/shared-components';

import { useToast, ADD } from 'providers/ToastProvider';
import NotificationApi from 'services/Notification/NotificationApi';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: `1px solid ${theme.palette.gray.border}`,
    background: '#F0F1F8',
    minHeight: '70px',
  },
  listItemRead: {
    background: theme.palette.white,
  },
  lastListItem: {
    borderBottom: 'none',
  },
  listItemIconWrapper: {
    minWidth: 40,
  },
  infoPriority: {
    color: theme.palette.info.main,
    fontSize: 22,
  },
  lowPriority: {
    color: theme.palette.success.main,
    fontSize: 22,
  },
  mediumPriority: {
    color: theme.palette.warning.main,
    fontSize: 22,
  },
  highPriority: {
    color: theme.palette.danger.main,
    fontSize: 22,
  },
  listItemText: {
    '& .mainspa-MuiListItemText-primary': {
      fontSize: 14,
      color: theme.palette.gray.text,
      fontWeight: 'bold',
    },
    '& .mainspa-MuiListItemText-secondary': {
      fontSize: 12,
      color: theme.palette.gray.text,
    },
  },
  listItemTextRead: {
    '& .mainspa-MuiListItemText-primary': {
      fontWeight: 'normal',
    },
    '& .mainspa-MuiListItemText-secondary': {
      color: theme.palette.gray.light,
    },
  },
  message: {
    marginTop: 'calc(50% - 60px)',
  },
  messageText: {
    padding: '0',
  },
  tabs: {
    padding: theme.spacing(2, 1, 1, 2),
  },
}));

const notificationApi = new NotificationApi();

const AnnouncementItem = ({ index, announcement, isLastElement }) => {
  const classes = useStyles();

  const { toastDispatch } = useToast();

  const getIcon = useCallback((priority, classes) => {
    switch (priority) {
      case 'info':
        return <InfoIcon className={classes.infoPriority} />;
      case 'low':
        return <CheckCircleIcon className={classes.lowPriority} />;
      case 'medium':
        return <ErrorIcon className={classes.mediumPriority} />;
      case 'high':
        return <ReportProblemOutlinedIcon className={classes.highPriority} />;
      default:
        return <CheckCircleIcon />;
    }
  }, []);

  const markNotificationAsRead = useCallback((id) => {
    notificationApi
      .markAsRead(id)
      .then(() => {})
      .catch(() => {
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the notification as read',
            type: 'error',
          },
        });
      });
  }, []);

  const handleNotificationItemClick = useCallback(
    (notification) => () => {
      if (notification.read === false) {
        markNotificationAsRead(notification.id);
      }

      if (notification.payload.route) {
        window.open(notification.payload.route, '_blank');
      }
    },
    []
  );

  return (
    <AccuvListItem
      button
      key={index}
      className={clsx(
        classes.listItem,
        announcement.read === true && classes.listItemRead,
        isLastElement && classes.lastListItem
      )}
      onClick={handleNotificationItemClick(announcement)}
    >
      <AccuvListItemIcon className={classes.listItemIconWrapper}>
        {getIcon(announcement.payload.priority, classes)}
      </AccuvListItemIcon>
      <AccuvListItemText
        className={clsx(
          classes.listItemText,
          announcement.read === true && classes.listItemTextRead
        )}
        primary={announcement.title}
        secondary={announcement.payload.description}
      />
    </AccuvListItem>
  );
};

AnnouncementItem.propTypes = {
  isLastElement: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
  announcement: PropTypes.any.isRequired,
};

export default AnnouncementItem;
