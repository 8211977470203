/* eslint-disable no-undef */
import React, { useState, useEffect, memo } from 'react';
import { useParams } from 'react-router-dom';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useAppState } from 'providers/AppProvider/AppProvider';
import { hasAccessToDashboard } from 'services/Authorization/UserAccess';
import PropTypes from 'prop-types';
import { AccuvLoadingIcon, AccuvStatusMessageBox } from '@accuv/shared-components';
import { monoApp } from 'services/API';
import { makeStyles } from '@material-ui/core/styles';
import { NotFound } from 'pages';

const useStyles = makeStyles(() => ({
  root: {
    height: '99%',
    width: '100%',
  },
  loading: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  error: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const PowerBiDashboard = ({ programId, reportId, reportType }) => {
  // Getting parameters from URL
  const params = useParams();
  if (programId == null) {
    programId = params.programId;
  }
  if (reportType == null) {
    reportType = params.reportType;
  }
  if (reportId == null) {
    reportId = params.reportId;
  }

  // Initializing state
  const [pbiData, setPbiData] = useState({
    status: 'loading',
    reportType: reportType,
    embedUrl: '',
    accessToken: '',
    expirationTimestamp: 0,
    id: '',
    groupId: '',
  });

  const [state] = useAppState();

  // Effects
  // for getting the dashboard URL and token. This doesn't cover the case when a tile is clicked
  useEffect(() => {
    if (pbiData.status === 'authorized') {
      const reportDataUrl = `/Reports/GetPowerBiData?projectId=${programId}&reportId=${reportId}&type=${reportType}`;
      monoApp
        .get(reportDataUrl)
        .then((res) => {
          console.log(
            `after obtaining the data ${
              res.data.embedUrl
            } - Date: ${new Date().toISOString()}`
          );
          console.log(`EmbedUrl: ${res.data.embedUrl} Date: ${new Date().toISOString()}`);
          console.log(`Token: ${res.data.accessToken} Date: ${new Date().toISOString()}`);
          setPbiData({
            status: 'ready',
            reportType: reportType,
            embedUrl: res.data.embedUrl,
            accessToken: res.data.accessToken,
            expirationTimestamp: Date.now() + res.data.expiration * 1000,
            id: getParameterByName(
              reportType === 'report' ? 'reportId' : 'dashboardId',
              res.data.embedUrl
            ),
            groupId: getParameterByName('groupId', res.data.embedUrl),
          });
        })
        .catch((error) => {
          console.log(`Error: ${error}`);
          setPbiData({
            status: 'error',
            reportType: reportType,
            embedUrl: '',
            accessToken: '',
            expirationTimestamp: 0,
            id: '',
            groupId: '',
          });
        });
    }
  }, [pbiData.status, programId, reportType, reportId]);

  //Check permission to the module only when loading
  console.log(`right here: ${pbiData.status}`);
  if (pbiData.status === 'loading' && state.dashboards) {
    // TODO: replace state.permissions with state.dashboards depending on the approach adopted
    // TODO: use the app state to check the access to the dashboard, see PowerBiDashboardMenu (we can load the dashboard info in a similar way as part of the app state)
    // TODO: another approach to have a better security is to check always against the server sending the program and dashboard id, in this way we don't depend on the app state
    let authorized = hasAccessToDashboard(reportId, state.dashboards, programId);
    if (authorized) {
      setPbiData({
        ...pbiData,
        status: 'authorized',
      });
    } else {
      setPbiData({
        ...pbiData,
        status: 'unauthorized',
      });
    }
  }

  // Utility functions
  const getParameterByName = (name, url) => {
    name = name.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  };

  // the rendering part
  console.log(`PowerBiDashboard rendering... Date: ${new Date().toISOString()}`);
  const classes = useStyles();
  document.title = 'AccuV | Dashboards';

  if (pbiData.status === 'ready') {
    return (
      <PowerBIEmbed
        key={pbiData.reportType}
        cssClassName={classes.root}
        embedConfig={{
          type: pbiData.reportType, // Supported types: report, dashboard, tile, visual and qna
          id: pbiData.id,
          embedUrl: pbiData.embedUrl,
          accessToken: pbiData.accessToken,
          settings: {
            panes: {
              filters: {
                visible: false,
              },
              pageNavigation: {
                visible: true,
              },
            },
          },
        }}
        // Get reference to the embedded object
        getEmbeddedComponent={(embeddedReport) => {
          if (pbiData.reportType === 'dashboard') {
            embeddedReport.on('tileClicked', function (e) {
              const tile = e.detail;
              setPbiData({
                ...pbiData,
                reportType: 'report',
                id: getParameterByName('reportId', tile.reportEmbedUrl),
                embedUrl:
                  tile.reportEmbedUrl +
                  '&pageName=' +
                  tile.pageName +
                  '&filterPaneEnabled=false',
              });
            });
          }
        }}
      />
    );
  } else if (pbiData.status === 'error') {
    return (
      <div className={classes.error}>
        <AccuvStatusMessageBox>
          An error occurred loading the module, please refresh the page.
        </AccuvStatusMessageBox>
      </div>
    );
  } else if (pbiData.status === 'unauthorized') {
    //It's better to show NotFound instead of Unauthorized here
    return <NotFound></NotFound>;
  } else {
    // shown when loading or authorized
    return (
      <div className={classes.loading}>
        <AccuvLoadingIcon></AccuvLoadingIcon>
      </div>
    );
  }
};

PowerBiDashboard.defaultProps = {
  programId: null,
  reportId: null,
  reporttype: null,
};

PowerBiDashboard.propTypes = {
  programId: PropTypes.string,
  reportId: PropTypes.number,
  reportType: PropTypes.string,
};

export default memo(PowerBiDashboard);
