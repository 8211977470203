/**
 * Modules Config File - We are still relying on the monolithic module permissions
 */
export const Apps = {
  Ids: {
    //This means that the module doesn't have access requirements based on programs (so it's available for all programs)
    NO_SEC: -1,
    //User Management
    USRM: 3,
    //Daily Field Report
    DFR: 4,
    //Activity Management
    AM: 5,
    //GC Assignment
    GCA: 7,
    //Crew Foreman Assignment
    CFA: 8,
    //Admin modules
    ADM: 11,
    //Old Workflow - Closeout Management
    CM: 18,
    //User Notification
    UN: 19,
    //Project Management
    PM: 21,
    //Workflow Application - AccuV entry point
    WFLA: 22,
    //Dashboard Management - Power BI Management
    PBI: 23,
    //Deliverable Managemnent
    DM: 24,
    //Vendor Invoice
    VI: 25,
    //Scope Management
    SCOPM: 27,
    //Client Invoice
    CIM: 26,
    //Work Order
    WO: 28,
    //PO Workflow Approval
    POWA: 29,
    //User Guide Management
    UGM: 30,
    //Cost Center Management
    CCM: 31,
    //Crew Management
    CPL: 20,
    //Site Candidate Management
    SCM: 32,
    //Purchase Order Sync
    POS: 33,
    //Logistics Management
    LM: 35,
    //Field Servive Management
    FSM: 36,
    //Admin Center (User Access Management)
    UAM: 43,
    //Supply Chain - Item Management
    SCMIM: 44,
    //Inventory Management
    INVM: 45,
    //Field Service Operations
    FSO: 55,
    //Notification Center
    NC: 56,
    //New Workflow Management
    WFL: 58,
    //Scope Management 2.0
    SCOP2: 70,
    //Field Service Operations QUESTIONNAIRE
    FSOQUESTIONNAIRE: 71,
  },
};
