import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core/';
import {
  AccuvDrawer,
  AccuvTypography,
  AccuvIconButton,
  AccuvList,
} from '@accuv/shared-components';
import { useAppState } from 'providers/AppProvider';
import { toggleHelpPanel } from 'providers/AppProvider/ActionCreators';
import CloseIcon from '@material-ui/icons/Close';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import VisibilityIcon from '@material-ui/icons/Visibility';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { helpPanelWidth, userPanelHeight, topBarHeight } from '../../styleVariables';
import HelpItem from './HelpItem';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    minWidth: helpPanelWidth,
    minHeight: userPanelHeight,
    top: `${topBarHeight}px !important`,
    height: `calc(100% - ${topBarHeight}px)`,
    zIndex: theme.zIndex.drawer + 99,
  },
  modal: {
    top: `${topBarHeight}px !important`,
    zIndex: theme.zIndex.drawer + 99,
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: 360,
    overflowY: 'auto',
    ...theme.mixins.scrollbar,
  },
  drawerTitle: {
    padding: theme.spacing(1.5, 2),
    borderBottom: `1px solid ${theme.palette.gray.border}`,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(0.5),
  },
}));

const HelpPanel = () => {
  const classes = useStyles();

  const [state, dispatch] = useAppState();

  const helpItemsArray = [
    {
      key: 'helpItem1',
      Icon: MenuBookIcon,
      iconColor: '#2196f3',
      text: 'Browse Knowledge Base',
      tooltip: 'Browse our collection of help and support articles.',
      link: 'https://accuv.atlassian.net/servicedesk/customer/portal/2',
    },
    {
      key: 'helpItem2',
      Icon: ContactSupportIcon,
      iconColor: '#5839A0',
      text: 'Contact Support',
      tooltip: 'Raise a request for help from our support team.',
      link: 'https://accuv.atlassian.net/servicedesk/customer/portal/2/group/-1',
    },
    {
      key: 'helpItem3',
      Icon: VisibilityIcon,
      iconColor: '#1a922d',
      text: 'View Status Page',
      tooltip: 'View the Status page for operational updates.',
      link: 'http://status.accuv.com/',
    },
    {
      key: 'helpItem4',
      Icon: NewReleasesIcon,
      iconColor: '#ffc107',
      text: 'Review Release Notes',
      tooltip: 'View previous AccuV Release Notes',
      link: 'https://accuv.atlassian.net/servicedesk/customer/portal/2/article/1197899779?src=-1897904113',
    },
  ];

  const handleClickAway = useCallback(
    (evt) => {
      if (evt.path) {
        var isHelpPanelButton = Object.keys(evt.path).filter(function (key) {
          return evt.path[key].id === 'helpPanelButton';
        });
        if (state.helpPanelOpened && !isHelpPanelButton.length > 0) {
          handleToggleHelpPanel();
        }
      }
    },
    [state.helpPanelOpened]
  );

  const handleToggleHelpPanel = useCallback(() => {
    dispatch(toggleHelpPanel());
  }, [dispatch]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <AccuvDrawer
        variant="persistent"
        anchor="right"
        open={state.helpPanelOpened}
        PaperProps={{
          elevation: 8,
        }}
        ModalProps={{
          keepMounted: false,
          className: classes.modal,
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <AccuvTypography className={classes.drawerTitle}>
          <div className={classes.title}>Help</div>
        </AccuvTypography>

        <AccuvIconButton
          className={classes.closeButton}
          size="small"
          onClick={handleToggleHelpPanel}
        >
          <CloseIcon />
        </AccuvIconButton>
        <AccuvList className={classes.list}>
          {helpItemsArray.map((currItem) => {
            return (
              <HelpItem
                key={currItem.key}
                Icon={currItem.Icon}
                iconColor={currItem.iconColor}
                text={currItem.text}
                tooltip={currItem.tooltip}
                link={currItem.link}
              />
            );
          })}
        </AccuvList>
      </AccuvDrawer>
    </ClickAwayListener>
  );
};

HelpPanel.propTypes = {};

export default HelpPanel;
