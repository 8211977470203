/* eslint-disable no-undef */
const mockProgramsHash = {
  ATT_FIBER: 'AT&T-Fiber',
  ATT_MWV: 'AT&T-Microwave',
  ATTNT: 'AT&T-Non Turf',
  NDPATT: 'AT&T-Turf',
  ATC_LIMO: 'ATC-Limo',
  CTL: 'CenturyLink',
  CCI_MODS: 'Crown Castle',
  MOB: 'Mobilitie',
  NEXS_INT: 'Nexius',
  TILLMAN: 'Tillman Infrastructure',
  VLX: 'Velex',
  VZW: 'Verizon',
  EXTENET: 'Extenet',
  SAMSUNG: 'SAMSUNG',
  SPRINT: 'SPRINT',
  TMOBILE: 'TMobile',
  SIGFOX: 'SigFox',
  LENDLEASE: 'Lend Lease',
};

const mockPrograms = [
  {
    url: null,
    text: 'AT&T-Fiber',
    items: null,
    id: 'ATT_FIBER',
    moduleId: 0,
    subModuleId: null,
  },
  {
    url: null,
    text: 'AT&T-Microwave',
    items: null,
    id: 'ATT_MWV',
    moduleId: 0,
    subModuleId: null,
  },
  {
    url: null,
    text: 'AT&T-Non Turf',
    items: null,
    id: 'ATTNT',
    moduleId: 0,
    subModuleId: null,
  },
  {
    url: null,
    text: 'AT&T-Turf',
    items: null,
    id: 'NDPATT',
    moduleId: 0,
    subModuleId: null,
  },
  {
    url: null,
    text: 'ATC-Limo',
    items: null,
    id: 'ATC_LIMO',
    moduleId: 0,
    subModuleId: null,
  },
  {
    url: null,
    text: 'CenturyLink',
    items: null,
    id: 'CTL',
    moduleId: 0,
    subModuleId: null,
  },
  {
    url: null,
    text: 'Crown Castle',
    items: null,
    id: 'CCI_MODS',
    moduleId: 0,
    subModuleId: null,
  },
  {
    url: null,
    text: 'KC35',
    items: null,
    id: 'BBQ_SHRIMP',
    moduleId: 0,
    subModuleId: null,
  },
  {
    url: null,
    text: 'Mobilitie',
    items: null,
    id: 'MOB',
    moduleId: 0,
    subModuleId: null,
  },
  {
    url: null,
    text: 'Nexius',
    items: null,
    id: 'NEXS_INT',
    moduleId: 0,
    subModuleId: null,
  },
  {
    url: null,
    text: 'Tillman Infrastructure',
    items: null,
    id: 'TILLMAN',
    moduleId: 0,
    subModuleId: null,
  },
  {
    url: null,
    text: 'Velex',
    items: null,
    id: 'VLX',
    moduleId: 0,
    subModuleId: null,
  },
  {
    url: null,
    text: 'Verizon',
    items: null,
    id: 'VZW',
    moduleId: 0,
    subModuleId: null,
  },
];

export { mockProgramsHash, mockPrograms };
