/* eslint-disable no-undef */
import { LitElement, html } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { until } from 'lit-html/directives/until.js';
import { monoApp } from 'services/API';
import loadjs from 'loadjs';

function scriptTag() {
  const tag = document.createElement('script');
  var initConfigFunction = `
  function InitMonolithicGlobalConfig() {
    console.log("MonoContainer: Global variables from Container SPA...");
    var globalConfig = document.getElementById('global-config');
    if (globalConfig) {
      var newScript = document.createElement('script');
      var inlineScript = document.createTextNode(globalConfig.textContent);
      newScript.appendChild(inlineScript);
      document.body.appendChild(newScript);
    } else {
      console.warn("MonoContainer: Global config element not found!");
    }
  }`;
  tag.innerHTML = initConfigFunction;
  return tag;
}

class MonoContainer extends LitElement {
  static get properties() {
    return {
      id: { type: String },
      name: { type: String },
      html: { type: String },
      moduleBundles: { type: Promise },
      viewUrl: { type: String },
      scriptBundle: { type: String },
      cssBundle: { type: String },
      initFunction: { type: String },
      init: { type: Function },
      unMount: { type: String },
      bearer: { type: String },
      loadMessage: { type: String },
    };
  }

  createRenderRoot() {
    //This is to not use Shadow DOM
    return this;
  }

  updated() {
    let me = this;
    Promise.all([this.html, this.moduleBundles]).then(function () {
      console.log('MonoContainer: Initializing monolithic module...');

      window.InitMonolithicGlobalConfig();
      SecurityAccess.init(localStorage.getItem('bearer'));
      AccuvSession.Load().done(function (session) {
        console.log('MonoContainer: AccuV Session loaded from mono!');
        console.log(session);
        UserJobPanel.Init(session, false);
        let loadedEvent = new Event('loaded');
        me.dispatchEvent(loadedEvent);
      });
    });
  }

  render() {
    //Load module bundles
    const bundleId = `bundle_${this.id}`;
    if (!loadjs.isDefined(bundleId)) {
      this.moduleBundles = loadjs([this.scriptBundle, this.cssBundle], bundleId, {
        returnPromise: true,
      })
        .then(function () {
          console.log(`MonoContainer: Module bundle ${bundleId} loaded!`);
        })
        .catch(function (pathsNotFound) {
          console.log(`MonoContainer: Oops! Error loading module bundle ${bundleId}!`);
          console.log(pathsNotFound);
        });
    } else {
      console.log(`MonoContainer: Module bundles ${bundleId} already defined!`);
      this.moduleBundles = new Promise((resolve) => resolve());
    }

    this.html = monoApp.get(this.viewUrl).then((res) => {
      const html = res.data;
      console.log('MonoContainer: Html loaded!');
      var unsafeHtml = unsafeHTML(html);
      return unsafeHtml;
    });

    var loading = html`<style>
        #loadingIndicator span {
          background: transparent !important;
        }
      </style>
      <div id="loadingIndicator">
        <div>
          <span>
            <i class="fa fa-spinner fa-pulse fa-fw"></i>
            <text id="loadingIndicatorText"
              >${this.loadMessage && this.loadMessage.length > 0
                ? this.loadMessage
                : 'Loading, please wait...'}</text
            >
          </span>
        </div>
      </div>`;
    // Sample of scriptTag usage
    //https://stackblitz.com/edit/mytohf-frfwbd?file=my-element.js
    //https://stackblitz.com/edit/lit-html-script-tag?file=src%2Fscript.js
    const template = () => html` ${scriptTag()} ${until(this.html, loading)} `;
    return html` ${template()} `;
  }
}

customElements.get('mono-container') ||
  customElements.define('mono-container', MonoContainer);
