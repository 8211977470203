/* eslint-disable no-undef */
import {
  HubConnectionBuilder,
  IHttpConnectionOptions,
  HubConnection,
  JsonHubProtocol,
  LogLevel,
  HubConnectionState,
} from '@microsoft/signalr';

/**
 * Creates a signalr hub connection.
 * @param url The URL of the signalr hub endpoint to connect to.
 * @param options Options object to pass to connection builder.
 */
export const createConnection = (
  url: string,
  options: IHttpConnectionOptions = {}
): HubConnection => {
  const isDev = process.env.NODE_ENV === 'development';
  let _options = { ...options };
  _options.logger = isDev ? LogLevel.Debug : LogLevel.Error;
  _options.accessTokenFactory = () => {
    let token = localStorage.getItem('bearer');
    return token ?? '';
  };

  // Create the connection instance
  // withAutomaticReconnect will automatically try to reconnect
  // and generate a new socket connection if needed
  const connection = new HubConnectionBuilder()
    .withUrl(url, _options)
    .withAutomaticReconnect()
    .withHubProtocol(new JsonHubProtocol())
    .configureLogging(isDev ? LogLevel.Debug : LogLevel.Error)
    .build();

  // Note: to keep the connection open the serverTimeout should be
  // larger than the KeepAlive value that is set on the server
  // keepAliveIntervalInMilliseconds default is 15000 and we are using default
  // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
  connection.serverTimeoutInMilliseconds = 60000;

  // re-establish the connection if connection dropped
  connection.onclose((error) => {
    console.assert(connection.state === HubConnectionState.Disconnected);
    console.log(
      'Connection closed due to error. Try refreshing this page to restart the connection',
      error
    );
  });

  connection.onreconnecting((error) => {
    console.assert(connection.state === HubConnectionState.Reconnecting);
    console.log('Connection lost due to error. Reconnecting.', error);
  });

  connection.onreconnected((connectionId) => {
    console.assert(connection.state === HubConnectionState.Connected);
    console.log('Connection reestablished. Connected with connectionId', connectionId);
  });

  return connection;
};
