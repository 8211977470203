import ExportNotification from './Export';
import ImportNotification from './Import';
import AnnouncementNotification from './Announcement';
import ToDoNotification from './ToDo';
import GenericNotification from './Generic';

const Notifications = {
  export: ExportNotification,
  import: ImportNotification,
  announcement: AnnouncementNotification,
  todo: ToDoNotification,
};

const NotificationItems = (type: string) => {
  let Notification = Notifications[type];

  if (Notification) {
    return Notification;
  } else {
    return GenericNotification;
  }
};

export { NotificationItems, Notifications, GenericNotification };
