import React, { Component } from 'react';
import env from '@beam-australia/react-env';
import {
  ConsoleTransport,
  FetchTransport,
  // initializeFaro,
  // getWebInstrumentations,
} from '@grafana/faro-web-sdk';
// import {
//   TracingInstrumentation,
//   // FaroSessionSpanProcessor,
//   // FaroTraceExporter,
// } from '@grafana/faro-web-tracing';
// import { trace, context } from '@opentelemetry/api';
// import { ZoneContextManager } from '@opentelemetry/context-zone';
// import { W3CTraceContextPropagator } from '@opentelemetry/core';
// import { registerInstrumentations } from '@opentelemetry/instrumentation';
// import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
// import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
// import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
// import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
// import { Resource } from '@opentelemetry/resources';
// import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
// import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import {
  getWebInstrumentations,
  initializeFaro,
  ReactIntegration,
  ReactRouterVersion,
  FaroErrorBoundary,
} from '@grafana/faro-react';
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * This Component provides telemetry with Grafana Faro
 *
 * NOTE: the package '@grafana/faro-react' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class FaroProvider extends Component {
  state = {
    initialized: false,
  };

  componentDidMount() {
    const { initialized } = this.state;
    //const SECRET = env('GRAFANA_AGENT_FARO_SECRET');
    const COLLECTOR_URL = env('GRAFANA_FARO_COLLECTOR');

    if (!initialized && COLLECTOR_URL) {
      // eslint-disable-next-line no-undef
      const ENVIRONMENT = env('ENVIRONMENT');
      const VERSION = env('VERSION_INFO');
      const NAME = 'MainSpa';
      // const BASE_URL = env('BASE_URL');
      //const COLLECTOR_URL = BASE_URL + 'grafana-agent-faro/collect';
      var webInstrumentations = getWebInstrumentations();
      // initialize faro
      const faro = initializeFaro({
        instrumentations: [
          ...webInstrumentations,
          // new TracingInstrumentation({
          //   instrumentationOptions: {
          //     // Requests to these URLs will have tracing headers attached.
          //     propagateTraceHeaderCorsUrls: [
          //       new RegExp('https?://([a-z0-9-]+[.])*accuv[.]com/*'),
          //       BASE_URL,
          //     ],
          //   },
          // }),
          new ReactIntegration({
            // Only needed if you want to use the React Router instrumentation
            router: {
              version: ReactRouterVersion.V6,
              dependencies: {
                createRoutesFromChildren,
                matchRoutes,
                Routes,
                useLocation,
                useNavigationType,
              },
            },
          }),
        ],
        transports: [
          new FetchTransport({
            url: COLLECTOR_URL,
          }),
          new ConsoleTransport(),
        ],
        app: {
          name: NAME,
          version: VERSION,
          environment: ENVIRONMENT,
        },
      });

      // set up otel
      // const resource = Resource.default().merge(
      //   new Resource({
      //     [SemanticResourceAttributes.SERVICE_NAME]: NAME,
      //     [SemanticResourceAttributes.SERVICE_VERSION]: VERSION,
      //   })
      // );
      // const provider = new WebTracerProvider({ resource });
      // provider.addSpanProcessor(
      //   new FaroSessionSpanProcessor(
      //     new BatchSpanProcessor(new FaroTraceExporter({ ...faro })),
      //     // The Faro metas object which for example contains the Session Meta with the configured sessionId.
      //     faro.metas
      //   )
      // );
      // provider.register({
      //   propagator: new W3CTraceContextPropagator(),
      //   contextManager: new ZoneContextManager(),
      // });
      // const ignoreUrls = [COLLECTOR_URL];
      // registerInstrumentations({
      //   instrumentations: [
      //     new DocumentLoadInstrumentation(),
      //     new FetchInstrumentation({ ignoreUrls }),
      //     new XMLHttpRequestInstrumentation({ ignoreUrls }),
      //     new UserInteractionInstrumentation(),
      //   ],
      // });

      if (faro) {
        // register OTel with Faro
        // faro.api.initOTEL(trace, context);
        console.log('Faro initialized');
      }

      this.setState({ initialized: true });
    }
  }

  render() {
    const { children } = this.props;
    const SECRET = env('GRAFANA_AGENT_FARO_SECRET');
    if (SECRET) {
      return <FaroErrorBoundary>{children}</FaroErrorBoundary>;
    } else {
      return <>{children}</>;
    }
  }
}

FaroProvider.propTypes = {
  children: PropTypes.element,
};

export default FaroProvider;
