/* eslint-disable no-undef */
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import env from '@beam-australia/react-env';
import AuthService from 'services/Authentication/AuthService';
import ProgramService from 'services/Program/ProgramService';
import * as AxiosLogger from 'axios-logger';

const mvcBasePath = env('MONOLITHIC_BASE_ENDPOINT');
const appApiBasePath = env('APPLICATION_SERVICE_ENDPOINT');
const userAccessApiBasePath = env('USERACCESS_ENDPOINT');
const axiosLoggerEnabled = env('AXIOS_LOGGER_ENABLED') === 'true';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

const logger = (log: any) => {
  // Since this is a console.info, will go to Grafana Faro
  console.info(log);
};

AxiosLogger.setGlobalConfig({
  status: true,
  headers: false,
  data: false,
  logger: logger.bind(this),
});

const handleRequest = (request: AxiosRequestConfig) => {
  let accessToken = AuthService.getAccessToken();
  if (!accessToken) console.warn('Access token not found');
  request.headers['Authorization'] = `Bearer ${AuthService.getAccessToken()}`;
  let program = ProgramService.getSelectedProgramId();
  if (program) {
    request.headers['Program'] = ProgramService.getSelectedProgramId();
  }
  return request;
};

const handleResponse = (response: AxiosResponse) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  return response;
};

const handleError = (error: any) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  if (401 === error.response?.status) {
    console.info('Session has expired!');
    window.location.replace('/Error/sessionExpired');
  }
  console.error(error.toJSON());
  return Promise.reject(error);
};

abstract class ApiBase {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });

    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  private _initializeRequestInterceptor = () => {
    if (axiosLoggerEnabled) {
      this.instance.interceptors.request.use(
        AxiosLogger.requestLogger,
        AxiosLogger.errorLogger
      );
    }

    this.instance.interceptors.request.use(this._handleRequest, this._handleError);
  };

  private _initializeResponseInterceptor = () => {
    if (axiosLoggerEnabled) {
      this.instance.interceptors.response.use(
        AxiosLogger.responseLogger,
        AxiosLogger.errorLogger
      );
    }

    this.instance.interceptors.response.use(this._handleResponse, this._handleError);
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    return handleRequest(config);
  };

  private _handleResponse = ({ data }: AxiosResponse) => data;

  protected _handleError = (error: any) => {
    return handleError(error);
  };
}

const setInterceptors = (axiosInstance: AxiosInstance) => {
  // Request interceptors
  if (axiosLoggerEnabled) {
    axiosInstance.interceptors.request.use(
      AxiosLogger.requestLogger,
      AxiosLogger.errorLogger
    );
  }
  axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    return handleRequest(request);
  });

  //Response interceptors
  if (axiosLoggerEnabled) {
    axiosInstance.interceptors.response.use(
      AxiosLogger.responseLogger,
      AxiosLogger.errorLogger
    );
  }
  axiosInstance.interceptors.response.use(
    (response) => {
      return handleResponse(response);
    },
    (error) => {
      return handleError(error);
    }
  );
};

const monoApp = axios.create({
  baseURL: mvcBasePath,
});
setInterceptors(monoApp);

const appApi = axios.create({
  baseURL: appApiBasePath,
});
setInterceptors(appApi);

const userAccessApi = axios.create({
  baseURL: userAccessApiBasePath,
});
setInterceptors(userAccessApi);

const simpleApi = axios.create();
setInterceptors(simpleApi);

export { ApiBase, monoApp, appApi, simpleApi, userAccessApi };
