/* eslint-disable no-undef */
import React, { useCallback } from 'react'; //useState,useEffect
import PropTypes from 'prop-types';
import {
  Header,
  // NotificationCenter,
  UserPanel,
  HelpPanel,
  Sidebar,
  Workspace,
  ProgramSelectorPanel,
} from 'components';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { topBarHeight } from 'styleVariables';
import useMountEffect from 'hooks/mountEffect';
import { useAppState } from 'providers/AppProvider';
import {
  toggleMainMenu,
  toggleUserPanel,
  toggleProgramSelector,
  permissionsLoaded,
  groupsLoaded,
  // notificationsRefreshed,
  updateUserProfilePicture,
} from 'providers/AppProvider/ActionCreators';
import env from '@beam-australia/react-env';
// import moment from 'moment';
import { createDictionary } from 'services/Authorization/UserAccess';
import ApplicationApi from 'services/Application/ApplicationApi';
// import NotificationApi from 'services/Notification/NotificationApi';
import UserAccessApi from 'services/UserAccessApi/UserAccessApi';
// import { connectToSignalr } from 'services/SignalR';
import ProgramService from 'services/Program/ProgramService';
import { dashboardsLoaded } from 'providers/AppProvider/ActionCreators';
import { createDashboardDictionary } from 'services/Authorization/UserAccess';
import NotificationCenterNew from 'components/NotificationCenter/NotificationCenterNew';

const useStyles = makeStyles((theme) => ({
  panel: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    maxWidth: '100%',
    ...theme.mixins.scrollbar,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      minHeight: `calc(100vh - ${topBarHeight}px)`,
      paddingTop: topBarHeight,
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      minHeight: `calc(100vh - ${topBarHeight}px)`,
      paddingTop: topBarHeight,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: `calc(100vh - ${topBarHeight}px)`,
    },
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

const applicationApi = new ApplicationApi();
// const notificationApi = new NotificationApi();
const userAccessApi = new UserAccessApi();
const selectedProgram = ProgramService.getSelectedProgramId();

const Layout = ({ children, userId, userName, userEmail, isLocalAuth }) => {
  // console.log('Layout rendering...');
  const byPassModuleSecurity = env('BYPASS_MODULE_SECURITY');
  // const notificationDays = env('NOTIFICATION_GET_LAST_X_DAYS');
  // const notificationService = env('NOTIFICATION_SERVICE_ENDPOINT');
  // const notificationHubName = env('NOTIFICATION_HUB_NAME');
  const blobStorageSystem = env('BLOB_STORAGE_SYSTEM');
  // const notificationHub = `${notificationService}/${notificationHubName}`;
  const classes = useStyles();
  const [state, dispatch] = useAppState();
  // const [notifications, setNotifications] = useState(null);
  // const mediaMatcher = matchMedia(`(max-width: ${MobileBreakpoint}px)`);
  // const { on } = useSignalr(notificationHub);

  const handleFullscreenToggle = useCallback(() => {
    const element = document.querySelector('#main-spa-container');
    const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;

    element.requestFullScreen =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      function () {
        return false;
      };
    document.cancelFullScreen =
      document.cancelFullScreen ||
      document.webkitCancelFullScreen ||
      document.mozCancelFullScreen ||
      function () {
        return false;
      };
    try {
      isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
    } catch {
      console.info('Fullscreen not supported');
    }
  }, []);

  const handleToggleUserPanel = useCallback(() => {
    dispatch(toggleUserPanel());
  }, [dispatch]);

  const handleToggleMainMenu = useCallback(() => {
    dispatch(toggleMainMenu());
  }, [dispatch]);

  const handleToggleProgramSelector = useCallback(() => {
    dispatch(toggleProgramSelector());
  }, [dispatch]);

  // const handleOnNotification = (notification) => {
  //   setNotifications((notifications) => {
  //     const newNotifications = [...notifications];
  //     const index = notifications.findIndex((n) => n.id === notification.id);
  //     console.log('new notification id:', notification.id);
  //     console.log('new notification:', notification);
  //     if (index !== -1) {
  //       //if the notification has delete flag and todo type, update
  //       if (notification.delete && notification.type === 'todo') {
  //         console.log('notification todo delete:', notification);
  //         newNotifications[index].payload.status = 'done';
  //       } else newNotifications[index] = notification;
  //     } else {
  //       newNotifications.unshift(notification);
  //     }
  //     return newNotifications;
  //   });
  // };

  // const handleOnNotificationList = (notificationList) => {
  //   setNotifications((notifications) => {
  //     const newNotifications = [...notifications];
  //     notificationList.forEach((notification) => {
  //       const index = notifications.findIndex((n) => n.id === notification.id);
  //       console.log('new notification id:', notification.id);
  //       console.log('new notification:', notification);
  //       if (index !== -1) {
  //         //if the notification has delete flag and todo type, update
  //         if (notification.delete && notification.type === 'todo') {
  //           console.log('notification todo delete:', notification);
  //           newNotifications[index].payload.status = 'done';
  //         } else newNotifications[index] = notification;
  //       } else {
  //         newNotifications.unshift(notification);
  //       }
  //     });
  //     return newNotifications;
  //   });
  // };

  // useEffect(() => {
  //   dispatch(notificationsRefreshed(notifications));
  // }, [dispatch, notifications]);

  // useEffect(() => {
  //   if (state.refreshNotifications) {
  //     // Fetch notifications
  //     const date = moment().subtract(notificationDays, 'day').format('YYYY-MM-DD');
  //     console.info('Fetching user notifications...');
  //     notificationApi
  //       .getNotifications(userId, date)
  //       .then((initNotifications) => {
  //         console.log('Total notifications before filter:', initNotifications.length);
  //         let notifications = initNotifications.filter((n) => n.delete != true);
  //         console.log('Total notifications after filter:', notifications.length);
  //         setNotifications(notifications);
  //         connectToSignalr(notificationHub).then((connection) => {
  //           connection.off('Notification');
  //           connection.on('Notification', handleOnNotification);
  //           connection.off('NotificationList');
  //           connection.on('NotificationList', handleOnNotificationList);
  //         });
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         dispatch(notificationsRefreshed('error'));
  //       });
  //   }
  // }, [state.refreshNotifications]);

  useMountEffect(() => {
    // Fetch user permissions
    console.info('Fetching user permissions...');
    userAccessApi
      .getUserProfilePicture()
      .then((response) => {
        const pictureUrl = `${blobStorageSystem}${response}`;
        dispatch(updateUserProfilePicture(pictureUrl));
      })
      .catch((error) => {
        console.error(error);
        dispatch(updateUserProfilePicture('error'));
      });
    if (byPassModuleSecurity === 'true') {
      setTimeout(function () {
        dispatch(permissionsLoaded({}));
      }, 1000);
    } else {
      if (
        (selectedProgram && !state.dashboards) ||
        (selectedProgram && state.dashboards && !state.dashboards[selectedProgram])
      ) {
        applicationApi
          .getDashboardsByProgram(selectedProgram)
          .then((dashboards) => {
            let dashboardsHash = {};
            if (dashboards) {
              dashboardsHash = createDashboardDictionary(
                selectedProgram,
                state.dashboards,
                dashboards
              );
            }
            //console.log(dashboardsHash);
            dispatch(dashboardsLoaded(dashboardsHash));
          })
          .catch((error) => {
            console.error(error);
            dispatch(dashboardsLoaded('error'));
          });
      }
      applicationApi
        .getPermissions()
        .then((permissions) => {
          let hash = {};
          let groups = {};
          if (permissions) {
            groups = permissions.MenuItems;
            hash = createDictionary(permissions.SiteMapEntry, permissions.Dashboard);
          }
          //console.log(hash);
          dispatch(groupsLoaded(groups));
          dispatch(permissionsLoaded(hash));
        })
        .catch((error) => {
          console.error(error);
          dispatch(permissionsLoaded('error'));
        });
    }
  });

  return (
    <>
      <Header
        userId={userId}
        logoAltText="AccuV"
        logo={`${process.env.PUBLIC_URL}/static/images/accuv-inv.svg`}
        toggleFullscreen={handleFullscreenToggle}
        profilePicture={state.userProfilePicture}
      />
      <div className={classNames(classes.panel)}>
        <Sidebar
          mainMenuOpened={state.mainMenuOpened}
          toggleMainMenu={handleToggleMainMenu}
        />

        {/* START - Here is where the micro (and mono) frontend resides */}
        <Workspace>{children}</Workspace>
        {/* END - Here is where the micro (and mono) frontend resides*/}

        <UserPanel
          userName={userName}
          userEmail={userEmail}
          profilePicture={state.userProfilePicture}
          userPanelOpened={state.userPanelOpened}
          toggleUserPanel={handleToggleUserPanel}
          isLocalAuth={isLocalAuth}
        />

        <HelpPanel />

        <NotificationCenterNew />
        {/* <NotificationCenter handleOnNotification={handleOnNotification} /> */}
        {/* This program selector is used only for the monolithic modules, remove it once possible! */}
        <ProgramSelectorPanel
          programSelectorOpened={state.programSelectorOpened}
          toggleProgramSelector={handleToggleProgramSelector}
        />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object,
  userId: PropTypes.string,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  userProfilePicture: PropTypes.string,
  isLocalAuth: PropTypes.bool,
};

export default React.memo(Layout);
