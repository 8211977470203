/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import { MicroFrontend, MonoFrontend, MonoFrontendIFrame } from 'components';
import { Apps } from 'constants/Apps';
import { useQueryStringParams } from 'utils/helpers';
import { useParams, useNavigate } from 'react-router-dom';
import env from '@beam-australia/react-env';

const adminCenterHost = env('ADMIN_CENTER_HOST');
const supplyChainHost = env('SUPPLY_CHAIN_HOST');
const fieldServiceOperationsHost = env('FIELD_SERVICE_OPERATIONS_HOST');
const workflowHost = env('WORKFLOW_HOST');
const fieldServiceOperationsQuestionnaireHost = env(
  'FIELD_SERVICE_OPERATIONS_QUESTIONNAIRE_HOST'
);
const notificationCenterHost = env('NOTIFICATION_CENTER_HOST');
const scopeManagementHost = env('SCOPE_MANAGEMENT_HOST');
const debug = env('DEBUG');
const min = debug === 'true' ? '' : '.min';
console.log('notificationCenterHost', notificationCenterHost);
//#region Mono-frontends
const ProjectManagement = () => {
  let jobSid;
  const params = useParams();

  if (params.jobSid) {
    jobSid = params.jobSid;
  }

  return (
    <MonoFrontend
      id={Apps.Ids.PM}
      name="Project Management"
      viewUrl="/ProjectManagement/TrackerView"
      scriptBundle={`/Scripts/Bundles/ProjectManagement${min}.js`}
      cssBundle={`/Content/Bundles/ProjectManagement${min}.css`}
      didMount={() => {
        console.log('ProjectManagement didMount callback');
        Modules.Schedule.Controllers.ScheduleController.InitScheduleAsync(jobSid);
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('ProjectManagement unMount callback');
      }}
    ></MonoFrontend>
  );
};

const CostCenterManagement = () => {
  return (
    <MonoFrontend
      id={Apps.Ids.CCM}
      name="Cost Center Management"
      viewUrl="/CostCenterManagement/CostCenterManagementView"
      scriptBundle={`/Scripts/Bundles/CostCenterManagement${min}.js`}
      cssBundle={`/Content/Bundles/CostCenterManagement${min}.css`}
      didMount={() => {
        console.log('CostCenterManagement didMount callback');
        Modules.CostCenterManagement.Controllers.CostCenterManagementController.InitAsync();
      }}
      willUnmount={() => {
        //Cleanup resources if needed
      }}
    ></MonoFrontend>
  );
};

const FieldServiceManagement = () => {
  const { workorderid: woId, roomId } = useQueryStringParams('workorderid', 'roomId');

  return (
    <MonoFrontend
      id={Apps.Ids.FSM}
      name="Field Service Management"
      viewUrl="/FieldServiceManagement/FieldServiceManagementView"
      scriptBundle={`/Scripts/Bundles/FieldServiceManagement${min}.js`}
      cssBundle={`/Content/Bundles/FieldServiceManagement${min}.css`}
      didMount={() => {
        console.log(
          `FieldServiceManagement didMount callback. Entry params -> WO[${woId}] - Room[${roomId}]`
        );

        Modules.FieldServiceManagement.Controllers.FieldServiceManagementController.InitAsync(
          woId,
          roomId
        );
      }}
      willUnmount={() => {
        //Cleanup resources
        Modules.FieldServiceManagement.Controllers.FieldServiceManagementController.Destroy();
      }}
    ></MonoFrontend>
  );
};

const WorkOrderManagement = () => {
  const { workOrderId } = useQueryStringParams('workOrderId');

  return (
    <MonoFrontend
      id={Apps.Ids.WO}
      name="Work Order Management"
      viewUrl="/WorkOrder/WorkOrderMainView"
      scriptBundle={`/Scripts/Bundles/WorkOrderManagement${min}.js`}
      cssBundle={`/Content/Bundles/WorkOrderManagement${min}.css`}
      didMount={() => {
        let isUserGC = Session.IsUserGC;
        console.log(
          `WorkOrderManagement didMount callback. Entry params: isUserGC[${isUserGC}] - workOrderId[${workOrderId}]`
        );
        Modules.WorkOrder.Controllers.WorkOrderController.InitHome(isUserGC, workOrderId);
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('WorkOrderManagement unMount callback');
      }}
    ></MonoFrontend>
  );
};

const UserManagement = () => (
  <MonoFrontend
    id={Apps.Ids.USRM}
    name="User Management"
    viewUrl="/UserManagement/UserManagementView"
    scriptBundle={`/Scripts/Bundles/Admin${min}.js`}
    cssBundle={`/Content/Bundles/Admin${min}.css`}
    didMount={() => {
      console.log('UserManagement didMount callback');
      Modules.Admin.Controllers.UserManagement.InitAsync();
    }}
    willUnmount={() => {
      //Cleanup resources if needed
      console.log('UserManagement unMount callback');
    }}
  ></MonoFrontend>
);

const UserLogout = () => {
  let navigate = useNavigate();
  return (
    <MonoFrontend
      id={-1}
      name="Logout"
      viewUrl="/UserManagement/UserManagementView"
      scriptBundle={`/Scripts/Bundles/LogoutMainSpa${min}.js`}
      cssBundle={`/Content/Bundles/Admin${min}.css`}
      didMount={async () => {
        console.log('UserManagement Logout didMount callback');
        await Modules.Admin.Controllers.LogoutMainSpa.Logout();
        navigate('/logout');
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('UserManagement Logout unMount callback');
      }}
      loadMessage="Logging out, please wait..."
      programAgnostic={true}
    ></MonoFrontend>
  );
};

const CrewForemanAssignment = () => (
  <MonoFrontend
    id={Apps.Ids.CFA}
    name="Crew ForemanA ssignment"
    viewUrl="/CrewForemanAssignment/CrewForemanAssignmentView"
    scriptBundle={`/Scripts/Bundles/CrewForemanAssignment${min}.js`}
    cssBundle={`/Content/Bundles/CrewForemanAssignment${min}.css`}
    didMount={() => {
      // console.log('UserManagement didMount callback');
      Modules.Admin.Controllers.GcSiteAssignment.InitAsync('adminView_8', Apps.Ids.CFA);
    }}
    willUnmount={() => {
      //Cleanup resources if needed
      // console.log('UserManagement unMount callback');
    }}
  ></MonoFrontend>
);
const DailyFieldReport = () => {
  return (
    <MonoFrontendIFrame
      id={Apps.Ids.DFR}
      name="Daily Field Reports"
      viewUrl="/HybridModules/AccuV.DFR/index.html"
    ></MonoFrontendIFrame>
  );
};

const JobSafetyAnalysis = () => {
  return (
    <MonoFrontendIFrame
      id={Apps.Ids.DFR}
      name="Daily Field Reports"
      subModule="JSA"
      viewUrl="/HybridModules/AccuV.JSA/index.html"
    ></MonoFrontendIFrame>
  );
};

const VendorCreation = () => {
  const { company } = useQueryStringParams('company');
  return (
    <MonoFrontend
      id={Apps.Ids.VI}
      name="Vendor Creation"
      viewUrl="/VendorInvoice/SummaryView"
      scriptBundle={`/Scripts/Bundles/VendorInvoice${min}.js`}
      cssBundle={`/Content/Bundles/VendorInvoice${min}.css`}
      didMount={() => {
        let poNumber = undefined;
        let invoiceNumber = undefined;
        let view = undefined;
        let vendorStatusID = 0;

        Modules.VendorInvoice.Controllers.VendorInvoiceController.InitHomeAsync(
          poNumber,
          invoiceNumber,
          view,
          company,
          vendorStatusID
        );
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('VendorInvoiceManagement unMount callback');
      }}
    ></MonoFrontend>
  );
};

const VendorInvoiceManagement = () => {
  const [vendorStatusId, setVendorStatusId] = useState(0);
  const { company, view } = useQueryStringParams('company', 'view');

  console.log('company', company);
  console.log('vendorStatusID', vendorStatusId);
  return (
    <MonoFrontend
      id={Apps.Ids.VI}
      name="Vendor Invoice Management"
      viewUrl={`/VendorInvoice/SummaryView?vendorStatusID=${vendorStatusId}`}
      scriptBundle={`/Scripts/Bundles/VendorInvoice${min}.js`}
      cssBundle={`/Content/Bundles/VendorInvoice${min}.css`}
      didMount={() => {
        setVendorStatusId(Session.VendorStatusID);

        let poNumber, invoiceNumber;
        console.log('Modules.VendorInvoice.Controllers. ', company, view, poNumber);
        Modules.VendorInvoice.Controllers.VendorInvoiceController.InitHomeAsync(
          poNumber,
          invoiceNumber,
          view,
          company,
          vendorStatusId
        );
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('VendorInvoice unMount callback');
      }}
    ></MonoFrontend>
  );
};
const VendorInvoiceManagementVendorPortal = () => {
  let item = 'VendorPortal';
  let headerId, view, sin;
  return (
    <MonoFrontend
      id={Apps.Ids.VI}
      name="Vendor Management | Vendor Portal"
      viewUrl="/VendorInvoice/VendorPortalView"
      scriptBundle={`/Scripts/Bundles/VendorInvoice${min}.js`}
      cssBundle={`/Content/Bundles/VendorInvoice${min}.css`}
      didMount={() => {
        console.log(`VendorPortal didMount callback.`);

        Modules.VendorInvoice.Controllers.VendorInvoiceController.openVendorPortalViewMgmt(
          item,
          headerId,
          view,
          sin
        );
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('VendorInvoiceManagementVendorPortal unMount callback');
      }}
    ></MonoFrontend>
  );
};
const VendorInvoiceManagementPO = () => {
  let item = 'PurchaseOrder';
  let headerId, view, sin;
  const params = useParams();

  if (params.poNumber) {
    headerId = params.poNumber;
  }
  return (
    <MonoFrontend
      id={Apps.Ids.VI}
      name="Vendor Management | Purchase Orders"
      viewUrl="/VendorInvoice/POView"
      scriptBundle={`/Scripts/Bundles/VendorInvoice${min}.js`}
      cssBundle={`/Content/Bundles/VendorInvoice${min}.css`}
      didMount={() => {
        console.log(`VendorInvoice didMount callback.`);

        Modules.VendorInvoice.Controllers.VendorInvoiceController.openPOViewMgmt(
          item,
          headerId,
          view,
          sin
        );
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('ClientInvoiceManagementPOView unMount callback');
      }}
    ></MonoFrontend>
  );
};

const VendorInvoiceManagementInvoice = () => {
  let item = 'Invoice';
  let view, sin;

  let { headerId } = useQueryStringParams('headerId');

  return (
    <MonoFrontend
      id={Apps.Ids.VI}
      name="Vendor Management | Invoices"
      viewUrl="/VendorInvoice/InvoiceView"
      scriptBundle={`/Scripts/Bundles/VendorInvoice${min}.js`}
      cssBundle={`/Content/Bundles/VendorInvoice${min}.css`}
      didMount={() => {
        console.log(`VendorInvoice didMount callback.`);

        Modules.VendorInvoice.Controllers.VendorInvoiceController.openInvoiceViewMgmt(
          item,
          headerId,
          view,
          sin
        );
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('ClientInvoiceManagementPOView unMount callback');
      }}
    ></MonoFrontend>
  );
};

const VendorInvoiceManagementSubmitInvoice = () => {
  let item = 'SubmitInvoice';
  let view, sin;

  let { headerId } = useQueryStringParams('headerId');
  let { hideFileAttachment } = useQueryStringParams('hideFileAttachment');

  return (
    <MonoFrontend
      id={Apps.Ids.VI}
      name="Vendor Management | Submit Invoice"
      viewUrl="/VendorInvoice/SubmitInvoiceView"
      scriptBundle={`/Scripts/Bundles/VendorInvoice${min}.js`}
      cssBundle={`/Content/Bundles/VendorInvoice${min}.css`}
      didMount={() => {
        console.log(`VendorInvoice didMount callback.`);

        Modules.VendorInvoice.Controllers.VendorInvoiceController.openSubmitInvoiceViewMgmt(
          item,
          headerId,
          view,
          sin,
          hideFileAttachment
        );
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('ClientInvoiceManagementPOView unMount callback');
      }}
    ></MonoFrontend>
  );
};

const LogisticsManagement = () => {
  const { isUserTV, view } = useQueryStringParams('isUserTV', 'view');
  return (
    <MonoFrontend
      id={Apps.Ids.LM}
      name="Logistics Management"
      viewUrl="/LogisticsManagement/LogisticsManagementMainView"
      scriptBundle={`/Scripts/Bundles/LogisticsManagement${min}.js`}
      cssBundle={`/Content/Bundles/LogisticsManagement${min}.css`}
      didMount={() => {
        let isUserGC = Session.IsUserGC;
        console.log(
          `LogisticsManagement didMount callback. isUserGC[${isUserGC}] - isUserTV[${isUserTV}] -view[${view}]`
        );

        Modules.LogisticsManagement.Controllers.LogisticsManagementController.InitHome(
          isUserGC,
          isUserTV,
          view
        );
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('LogisticsManagement unMount callback');
      }}
    ></MonoFrontend>
  );
};

const SiteCandidateManagement = () => (
  <MonoFrontend
    id={Apps.Ids.SCM}
    name="Site Candidate Management"
    viewUrl="/SiteCandidateManagement/SiteCandidateManagementView"
    scriptBundle={`/Scripts/Bundles/SiteCandidateManagement${min}.js`}
    cssBundle={`/Content/Bundles/SiteCandidateManagement${min}.css`}
    didMount={() => {
      console.log('SiteCandidateManagement init callback');
      Modules.SiteCandidateManagement.Controllers.SiteCandidateManagementController.InitSiteCandidateManagementAsync();
    }}
    willUnmount={() => {
      //Cleanup resources if needed
      console.log('SiteCandidateManagement unMount callback');
    }}
  ></MonoFrontend>
);

const DocumentManagement = () => {
  return (
    <MonoFrontend
      id={Apps.Ids.DM}
      name="Document Management"
      viewUrl="/DeliverableManagement/DeliverableManagementView"
      scriptBundle={`/Scripts/Bundles/DocumentManagement${min}.js`}
      cssBundle={`/Content/Bundles/DocumentManagement${min}.css`}
      didMount={() => {
        console.log('DeliverableManagement didMount callback.');
        Modules.DeliverableManagement.Controllers.DeliverableManagementController.InitDeliverableManagement();
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('DeliverableManagement unMount callback');
      }}
    ></MonoFrontend>
  );
};

const ScopeManagement = () => {
  return (
    <MonoFrontend
      id={Apps.Ids.SCOPM}
      name="Scope Management"
      viewUrl="/ScopingManagement/ScopeView"
      scriptBundle={`/Scripts/Bundles/ScopingAndCloseoutManagement${min}.js`}
      cssBundle={`/Content/Bundles/ScopingAndCloseoutManagement${min}.css`}
      didMount={() => {
        console.log('ScopeManagement init callback');
        Modules.ScopingManagement.Controllers.ScopingMgmtController.InitAsync();
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('ScopeManagement unMount callback');
        Modules.ScopingManagement.Controllers.ScopingMgmtController.Destroy();
      }}
    ></MonoFrontend>
  );
};

const CloseoutManagement = () => {
  return (
    <MonoFrontend
      id={Apps.Ids.CM}
      name="Closeout Management"
      viewUrl="/Workflow/WorkflowView"
      scriptBundle={`/Scripts/Bundles/ScopingAndCloseoutManagement${min}.js`}
      cssBundle={`/Content/Bundles/ScopingAndCloseoutManagement${min}.css`}
      didMount={() => {
        console.log('CloseoutManagement init callback');
        Modules.Workflow.Controllers.WorkflowController.InitAsync();
      }}
      willUnmount={() => {
        //Cleanup resources
        console.log('CloseoutManagement unMount callback');
        Modules.Workflow.Controllers.WorkflowController.Destroy();
      }}
    ></MonoFrontend>
  );
};

const PoApproval = (props) => {
  let { poNumber } = useQueryStringParams('poNumber');
  const params = useParams();
  if (params.poNumber) {
    poNumber = params.poNumber;
  }
  const viewType = 'PO_Approval';
  const { kpiValue } = props;
  const marketList = null;
  return (
    <MonoFrontend
      id={Apps.Ids.POWA}
      name="PO Approval"
      viewUrl={`/POWorkflowApproval/POWorkflowApprovalsView?viewType=${viewType}&viewId=poView_${kpiValue}`}
      scriptBundle={`/Scripts/Bundles/POWorkflowApproval${min}.js`}
      cssBundle={`/Content/Bundles/POWorkflowApproval${min}.css`}
      didMount={() => {
        // console.log('POApproval didMount callback');
        Modules.POWorkflowApproval.Controllers.POWorkflowApprovalController.InitAsync(
          viewType,
          kpiValue,
          marketList,
          poNumber
        );
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        // console.log('POApproval unMount callback');
      }}
    ></MonoFrontend>
  );
};

const CloseoutApproval = (props) => {
  const viewType = 'Closeout_Approval';
  const { kpiValue } = props;
  const marketList = null;
  const { poNumber } = useQueryStringParams('poNumber');

  return (
    <MonoFrontend
      id={Apps.Ids.POWA}
      name="Closeout Approval"
      viewUrl={`/POWorkflowApproval/POWorkflowApprovalsView?viewType=${viewType}&viewId=poView_${kpiValue}`}
      scriptBundle={`/Scripts/Bundles/POWorkflowApproval${min}.js`}
      cssBundle={`/Content/Bundles/POWorkflowApproval${min}.css`}
      didMount={() => {
        console.log('CloseoutApproval didMount callback');
        Modules.POWorkflowApproval.Controllers.POWorkflowApprovalController.InitAsync(
          viewType,
          kpiValue,
          marketList,
          poNumber
        );
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('CloseoutApproval unMount callback');
      }}
    ></MonoFrontend>
  );
};

const DashboardManagement = () => (
  <MonoFrontend
    id={Apps.Ids.PBI}
    name="Dashboard Management"
    viewUrl="/PowerBiManagement/PowerBiView"
    scriptBundle={`/Scripts/Bundles/DashboardManagement${min}.js`}
    cssBundle={`/Content/Bundles/DashboardManagement${min}.css`}
    didMount={() => {
      // Start initializing PowerBI Dashboard integration
      console.log('Dashboard Management didMount callback');
      Modules.Admin.Controllers.PowerBiReportsManagement.InitAsync();
    }}
    willUnmount={() => {
      //Cleanup resources if needed
      console.log('Dashboard Management unMount callback');
    }}
  ></MonoFrontend>
);

const ClientInvoiceManagement = () => {
  return (
    <MonoFrontend
      id={Apps.Ids.CIM}
      name="Client Invoice Management"
      viewUrl="/ClientInvoiceManagement/SummaryView"
      scriptBundle={`/Scripts/Bundles/ClientInvoiceManagement${min}.js`}
      cssBundle={`/Content/Bundles/ClientInvoiceManagement${min}.css`}
      didMount={() => {
        console.log(`ClientInvoiceManagement didMount callback.`);
        Modules.ClientInvoiceManagement.Controllers.ClientInvoiceManagementController.InitHomeAsync();
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('ClientInvoiceManagement unMount callback');
      }}
    ></MonoFrontend>
  );
};

const ClientInvoiceManagementPurchaseOrder = () => {
  let item = 'PurchaseOrder';
  let view, sin;

  let { headerId } = useQueryStringParams('headerId');

  return (
    <MonoFrontend
      id={Apps.Ids.CIM}
      name="Client Management | Purchase Orders"
      viewUrl="/ClientInvoiceManagement/POView"
      scriptBundle={`/Scripts/Bundles/ClientInvoiceManagement${min}.js`}
      cssBundle={`/Content/Bundles/ClientInvoiceManagement${min}.css`}
      didMount={() => {
        console.log(`ClientInvoiceManagementPOView didMount callback.`);

        Modules.ClientInvoiceManagement.Controllers.ClientInvoiceManagementController.initPOViewMgmt(
          item,
          headerId,
          view,
          sin
        );
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('ClientInvoiceManagementPOView unMount callback');
      }}
    ></MonoFrontend>
  );
};

const ClientInvoiceManagementInvoice = () => {
  let item = 'Invoice';
  let view, sin;

  let { headerId } = useQueryStringParams('headerId');
  let { NexiusInvoiceNumber } = useQueryStringParams('NexiusInvoiceNumber');

  return (
    <MonoFrontend
      id={Apps.Ids.CIM}
      name="Client Management | Invoices"
      viewUrl="/ClientInvoiceManagement/InvoiceView"
      scriptBundle={`/Scripts/Bundles/ClientInvoiceManagement${min}.js`}
      cssBundle={`/Content/Bundles/ClientInvoiceManagement${min}.css`}
      didMount={() => {
        console.log(`ClientInvoiceManagementInvoiceView didMount callback.`);
        Modules.ClientInvoiceManagement.Controllers.ClientInvoiceManagementController.initInvoiceViewMgmt(
          item,
          headerId,
          NexiusInvoiceNumber,
          view,
          sin
        );
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('ClientInvoiceManagementInvoiceView unMount callback');
      }}
    ></MonoFrontend>
  );
};

const ClientInvoiceManagementSubmitInvoice = () => {
  let item = 'SubmitInvoice';
  let NexiusInvoiceNumber, view, sin;

  let { headerId } = useQueryStringParams('headerId');

  return (
    <MonoFrontend
      id={Apps.Ids.CIM}
      name="Client Management | Submit Invoice"
      viewUrl="/ClientInvoiceManagement/SubmitInvoiceView"
      scriptBundle={`/Scripts/Bundles/ClientInvoiceManagement${min}.js`}
      cssBundle={`/Content/Bundles/ClientInvoiceManagement${min}.css`}
      didMount={() => {
        console.log(`ClientInvoiceManagementSubmitInvoice didMount callback.`);
        Modules.ClientInvoiceManagement.Controllers.ClientInvoiceManagementController.initSubmitInvoiceViewMgmt(
          item,
          headerId,
          NexiusInvoiceNumber,
          view,
          sin
        );
      }}
      willUnmount={() => {
        //Cleanup resources if needed
        console.log('ClientInvoiceManagementInvoiceView unMount callback');
      }}
    ></MonoFrontend>
  );
};

const AssignSites = () => {
  return (
    <MonoFrontend
      id={Apps.Ids.CM}
      name="Closeout Management"
      viewUrl="/Workflow/AssignSitesView"
      scriptBundle={`/Scripts/Bundles/ScopingAndCloseoutManagement${min}.js`}
      cssBundle={`/Content/Bundles/ScopingAndCloseoutManagement${min}.css`}
      didMount={() => {
        console.log('AssignSites init callback');
        Modules.Workflow.Controllers.WorkflowController.InitAssignSitesAsync();
      }}
      willUnmount={() => {
        //Cleanup resources
        console.log('AssignSites unMount callback');
      }}
    ></MonoFrontend>
  );
};

//#endregion Mono-frontends

//#region Micro-frontends

const AdminCenter = ({ history }) => {
  return (
    <MicroFrontend
      id={Apps.Ids.UAM}
      name="Admin Center"
      microId="AdminCenter"
      host={adminCenterHost}
      basePath="apps/adminCenter"
      loadType="not-optimized"
      buildMode="library"
      history={history}
    />
  );
};

const SupplyChain = ({ history }) => {
  return (
    <MicroFrontend
      id={Apps.Ids.SCMIM}
      name="Supply Chain"
      microId="SupplyChain"
      host={supplyChainHost}
      basePath="apps/supplyChain/main"
      loadType="not-optimized"
      buildMode="library"
      history={history}
    />
  );
};

const FieldServiceOperations = ({ history }) => {
  return (
    <MicroFrontend
      id={Apps.Ids.FSO}
      name="Field Service Operation"
      microId="FSO"
      host={fieldServiceOperationsHost}
      basePath="apps/fieldServiceOperations"
      loadType="not-optimized"
      buildMode="library"
      history={history}
    />
  );
};

const Workflow = ({ history }) => {
  return (
    <MicroFrontend
      id={Apps.Ids.WFL}
      name="Workflow"
      microId="WFL"
      host={workflowHost}
      basePath="apps/workflow"
      loadType="not-optimized"
      buildMode="library"
      history={history}
    />
  );
};

const MicroScopeManagement = ({ history }) => {
  return (
    <MicroFrontend
      id={Apps.Ids.SCOP2}
      name="Scope 2.0"
      microId="microscopemanagement"
      host={scopeManagementHost}
      basePath="apps/microScopeManagement"
      loadType="not-optimized"
      buildMode="library"
      history={history}
    />
  );
};
const NotificationCenter = ({ history }) => {
  return (
    <MicroFrontend
      id={-1}
      name="Notification Center"
      microId="NOTIFICATIONCENTER"
      host={notificationCenterHost}
      basePath="apps/notificationCenter"
      loadType="not-optimized"
      buildMode="library"
      history={history}
    />
  );
};

const FieldServiceOperationsQuestionnaire = ({ history }) => {
  return (
    <MicroFrontend
      id={Apps.Ids.FSOQUESTIONNAIRE}
      name="FSOQUESTIONNAIRE"
      microId="FSOQUESTIONNAIRE"
      host={fieldServiceOperationsQuestionnaireHost}
      basePath="apps/questionnaire"
      loadType="not-optimized"
      buildMode="library"
      history={history}
    />
  );
};
//#endregion

//#endregion  Micro-frontends

export {
  ProjectManagement,
  CostCenterManagement,
  ScopeManagement,
  CloseoutManagement,
  FieldServiceManagement,
  AdminCenter,
  WorkOrderManagement,
  UserManagement,
  UserLogout,
  LogisticsManagement,
  SiteCandidateManagement,
  DocumentManagement,
  DailyFieldReport,
  JobSafetyAnalysis,
  PoApproval,
  CloseoutApproval,
  DashboardManagement,
  ClientInvoiceManagement,
  ClientInvoiceManagementPurchaseOrder,
  ClientInvoiceManagementInvoice,
  ClientInvoiceManagementSubmitInvoice,
  VendorCreation,
  VendorInvoiceManagement,
  VendorInvoiceManagementPO,
  VendorInvoiceManagementInvoice,
  VendorInvoiceManagementSubmitInvoice,
  VendorInvoiceManagementVendorPortal,
  SupplyChain,
  CrewForemanAssignment,
  AssignSites,
  FieldServiceOperations,
  NotificationCenter,
  MicroScopeManagement,
  FieldServiceOperationsQuestionnaire,
  Workflow,
};
