import React from 'react';
import PropTypes from 'prop-types';
import {
  AccuvListItem,
  AccuvListItemIcon,
  AccuvListItemText,
} from '@accuv/shared-components';
import { NavLink } from 'react-router-dom';
import { capitalize } from 'utils/helpers';
import { makeStyles } from '@material-ui/core/styles';
import { useAppState } from 'providers/AppProvider';
import SidebarSubmenu from './Submenu';

const useStyles = makeStyles((theme) => ({
  homeLinkWrapper: {
    padding: theme.spacing(0, 1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  menuLink: {
    flex: '0 0 50%',
  },
  menuIconWrapper: {
    minWidth: 28,
  },
  menuIconWrapperHome: {
    minWidth: 22,
  },
  menuIcon: {
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  menuIconHome: {
    fontSize: 18,
    color: theme.palette.secondary.main,
    position: 'relative',
    top: -1,
  },
  menuTextWrapper: {
    '& .mainspa-MuiTypography-root': {
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  menuActive: {
    backgroundColor: theme.palette.action.hover,
  },
}));

const SidebarItem = ({ route, index, toggleMainMenu }) => {
  console.log('SidebarItem rendering...');

  const classes = useStyles();
  const [state] = useAppState();

  if (route.type === 'external') {
    return (
      <a
        href={route.path}
        target="_blank"
        rel="noopener noreferrer"
        key={index}
        className={classes.menuLink}
        data-test={route.name}
      >
        <AccuvListItem button>
          <AccuvListItemIcon className={classes.menuIconWrapper}>
            <route.icon className={classes.menuIcon} />
          </AccuvListItemIcon>
          <AccuvListItemText className={classes.menuTextWrapper}>
            {capitalize(route.name)}
          </AccuvListItemText>
        </AccuvListItem>
      </a>
    );
  }

  if (route.type === 'submenu') {
    return (
      <SidebarSubmenu
        route={route}
        permissions={state.permissions}
        toggleMainMenu={toggleMainMenu}
        groups={state.groups}
      ></SidebarSubmenu>
    );
  }

  if (route.type === 'homeLink') {
    return (
      <div className={classes.homeLinkWrapper}>
        <NavLink to={route.path} exact="true" className={classes.homeLink} key={index}>
          <AccuvListItem button onClick={toggleMainMenu}>
            <AccuvListItemIcon className={classes.menuIconWrapperHome}>
              <route.icon className={classes.menuIconHome} />
            </AccuvListItemIcon>
            <AccuvListItemText className={classes.menuTextWrapperHome}>
              {capitalize(route.name)}
            </AccuvListItemText>
          </AccuvListItem>
        </NavLink>
      </div>
    );
  }

  return (
    <NavLink
      to={route.path}
      exact="true"
      className={classes.menuLink}
      activeClassName={classes.menuActive}
      key={index}
    >
      <AccuvListItem button>
        <AccuvListItemIcon className={classes.menuIconWrapper}>
          <route.icon className={classes.menuIcon} />
        </AccuvListItemIcon>
        <AccuvListItemText className={classes.menuTextWrapper}>
          {capitalize(route.name)}
        </AccuvListItemText>
      </AccuvListItem>
    </NavLink>
  );
};

SidebarItem.propTypes = {
  route: PropTypes.object,
  index: PropTypes.number,
  toggleMainMenu: PropTypes.func,
};

export default SidebarItem;
