import React, { useEffect, useState, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AccuvList,
  AccuvButton,
  AccuvLoadingIcon,
  AccuvStatusMessageBox,
} from '@accuv/shared-components';

import RequestsSkeleton from './RequestsSkeleton';
import ExportRequestNew from './ExportRequestNew';

import { useAppState } from 'providers/AppProvider';
import { useToast, ADD } from 'providers/ToastProvider';
import NotificationApi from 'services/Notification/NotificationApi';
import {
  refreshRequestCompleted,
  updateReuqestBlockCompleted,
} from 'providers/AppProvider/ActionCreators';

const notificationApi = new NotificationApi();

const pageSize = 50; //by default fetch 50 rows
const notificationType = 'export';

const useStyles = makeStyles((theme) => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: 360,
    overflowY: 'auto',
    ...theme.mixins.scrollbar,
  },
  message: {
    marginTop: 'calc(50% - 60px)',
  },
  messageText: {
    padding: '0',
  },
  moreHyperlinkSection: {
    textAlign: 'center',
    marginLeft: '4px',
    marginRight: '4px',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  moreHyperlink: {
    width: '100%',
  },
}));

const ExportPanelComponent = (props) => {
  const classes = useStyles();

  const { toastDispatch } = useToast();

  const [state, dispatch] = useAppState();

  const totalPages = useRef(0);
  const currentPage = useRef(1);
  const exportsRefArray = useRef([]);

  const [exports, setExports] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleLoadMore = useCallback(() => {
    if (totalPages.current > 0 && currentPage.current < totalPages.current) {
      currentPage.current++;
      setIsLoadingMore(true);
    }
  }, []);

  const markNotificationAsRead = (id) => {
    notificationApi
      .markAsRead(id)
      .then(() => {})
      .catch(() => {
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the notification as read',
            type: 'error',
          },
        });
      });
  };

  const markNotificationAsUnread = (id) => {
    notificationApi
      .markAsUnread(id)
      .then(() => {})
      .catch(() => {
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the export as unread',
            type: 'error',
          },
        });
      });
  };

  const handleMarkNotificationAsRead = (notification) => () => {
    if (notification.read === false) {
      markNotificationAsRead(notification.id);
    }
  };

  const handleMarkNotificationAsUnread = (notification) => () => {
    if (notification.read === true) {
      markNotificationAsUnread(notification.id);
    }
  };

  const handleExportItemClick = (notification) => () => {
    if (notification.read === false) {
      markNotificationAsRead(notification.id);
    }
  };

  const filterNotificationsByProgram = () => {
    let not;

    if (props.filterProgramVal.length > 0) {
      not = exportsRefArray.current.filter(
        (n) =>
          props.filterProgramVal.filter((p) => p.programId === n.payload.program).length >
          0
      );
    } else {
      not = exportsRefArray.current;
    }

    return not;
  };

  const loadNotifications = useCallback(
    async (filterValues) => {
      try {
        if (exportsRefArray.current.length > 0) {
          let lastElementCreatedDate =
            exportsRefArray.current[exportsRefArray.current.length - 1];

          filterValues.toDate = lastElementCreatedDate.createdDate;
        }

        const data = await notificationApi.getFilteredNotifications(filterValues);
        if (totalPages.current === 0) {
          totalPages.current = data.totalPages;
        }
        exportsRefArray.current.push(...data.notifications);
        setExports(filterNotificationsByProgram());
      } catch (error) {
        toastDispatch({
          type: ADD,
          payload: {
            content: error.message,
            type: 'error',
          },
        });
      } finally {
        if (isLoadingMore) {
          setIsLoadingMore(false);
        }

        if (state.refreshRequest) {
          dispatch(refreshRequestCompleted());
        }
      }
    },
    [filterNotificationsByProgram, isLoadingMore, state.refreshRequest]
  );

  const markAllNotificationAsRead = (ids) => {
    notificationApi
      .markAllAsRead(ids)
      .then(() => {})
      .catch(() => {
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the notifications as read',
            type: 'error',
          },
        });
      })
      .finally(() => {
        props.markAllReadCallback();
      });
  };

  //below function is executed on the first load and on each more click to get data
  useEffect(() => {
    if (
      (totalPages.current === 0 && !isLoadingMore) || //first load
      isLoadingMore //on more click
    ) {
      loadNotifications({
        pageSize,
        typeStatus: [{ type: notificationType }],
      });
    }
  }, [isLoadingMore]);

  //below function is executed if we click refresh notification from not pane or my activity section
  useEffect(() => {
    if (state.refreshRequest) {
      currentPage.current = 1;
      exportsRefArray.current = [];
      totalPages.current = 0;

      loadNotifications({
        pageSize,
        typeStatus: [{ type: notificationType }],
      });
    }
  }, [state.refreshRequest]);

  //below function is executed when the filter by program changes
  useEffect(() => {
    try {
      if (totalPages.current !== 0) {
        let filteredNotificationsByProgram = filterNotificationsByProgram();
        setExports(filteredNotificationsByProgram);
      }
    } catch (error) {
      setExports([]);
    }
  }, [props.filterProgramVal]);

  //below function is executed if any notification changed and we should update it (updates returned from signal r)
  useEffect(() => {
    if (state.notificationsToUpdate && state.notificationsToUpdate.length > 0) {
      if (exports && Array.isArray(exports) && exportsRefArray.current.length > 0) {
        let newExports = [...exportsRefArray.current];
        let notificationsToUpdateExport = state.notificationsToUpdate.filter(
          (y) => y.type === 'export'
        );

        notificationsToUpdateExport.map((x) => {
          let itemToUpdateIndex = newExports.findIndex((elem) => elem.id === x.id);
          if (itemToUpdateIndex > -1) {
            newExports[itemToUpdateIndex] = x;
          }
          //if it s a new notification: add it on the top:  createdDate === lastModifiedBy only on creation
          else if (x.lastModifiedDate === x.createdDate) {
            newExports.unshift(x);
          }
        });

        exportsRefArray.current = newExports;
        setExports(newExports);
      }

      dispatch(updateReuqestBlockCompleted());
    }
  }, [state.notificationsToUpdate]);

  useEffect(() => {
    if (props.markAllReadFromParent) {
      if (Array.isArray(exports) && exports.length > 0) {
        const ids = exports.filter((not) => not.read === false).map((opt) => opt.id);
        ids.length > 0 && markAllNotificationAsRead(ids);
      }
    }
  }, [props.markAllReadFromParent]);

  let showMore = currentPage.current < totalPages.current;

  let exportPanel;

  if (!exports) {
    // Show the skeleton if the items are not available yet
    exportPanel = <RequestsSkeleton />;
  } else if (exports && Array.isArray(exports) && exports.length > 0) {
    let exportItems = exports.map((exportItem, index) => {
      return (
        <ExportRequestNew
          key={index}
          exportItem={exportItem}
          index={index}
          exportsLength={exports.length}
          onClick={handleExportItemClick(exportItem)}
          markAsUnreadCallback={handleMarkNotificationAsUnread(exportItem)}
          markAsReadCallback={handleMarkNotificationAsRead(exportItem)}
        />
      );
    });
    exportPanel = (
      <AccuvList dense className={classes.list}>
        {exportItems}
        {showMore && (
          <div className={classes.moreHyperlinkSection}>
            {isLoadingMore ? (
              <AccuvLoadingIcon size={20} />
            ) : (
              <AccuvButton
                variant="outlined"
                color="primary"
                className={classes.moreHyperlink}
                onClick={handleLoadMore}
              >
                LOAD MORE ...
              </AccuvButton>
            )}
          </div>
        )}
      </AccuvList>
    );
  } else if (Array.isArray(exports) && exports.length === 0) {
    exportPanel = (
      <div className={classes.message}>
        <AccuvStatusMessageBox className={classes.messageText}>
          No Pending Export Request
        </AccuvStatusMessageBox>
      </div>
    );
  } else {
    exportPanel = (
      <div className={classes.message}>
        <AccuvStatusMessageBox className={classes.messageText}>
          Something went wrong
        </AccuvStatusMessageBox>
      </div>
    );
  }

  return exportPanel;
};

export default ExportPanelComponent;
