import React, { memo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core/';
import PropTypes from 'prop-types';
import {
  AccuvTypography,
  AccuvDrawer,
  AccuvAvatar,
  AccuvLink,
  AccuvIconButton,
} from '@accuv/shared-components';
import { userPanelWidth, userPanelHeight, topBarHeight } from '../../styleVariables';
import { getInitials } from 'utils/helpers';
import CloseIcon from '@material-ui/icons/Close';
import UserAccessApi from 'services/UserAccessApi/UserAccessApi';
import env from '@beam-australia/react-env';
import { useAppState } from 'providers/AppProvider';
import {
  updateUserProfilePicture,
  deleteUserProfilePicture,
} from 'providers/AppProvider/ActionCreators';
import BounceLoader from 'react-spinners/BounceLoader';
import { useToast, ADD } from 'providers/ToastProvider';
import { capitalize } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    minWidth: userPanelWidth,
    minHeight: userPanelHeight,
    top: `${topBarHeight}px !important`,
    height: `auto`,
    zIndex: theme.zIndex.drawer + 99,
  },
  modal: {
    top: `${topBarHeight}px !important`,
    zIndex: theme.zIndex.drawer + 99,
  },
  container: {
    position: 'relative',
    overflowX: 'initial',
    overflowY: 'auto',
    zIndex: 1,
    flexGrow: 1,
    ...theme.mixins.scrollbar,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
  },
  avatarArea: {
    width: 110,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(3, 0),
    position: 'absolute',
  },
  avatar: {
    width: 56,
    height: 56,
    marginBottom: 5,
  },
  avatarLink: {
    fontSize: 10,
    color: theme.palette.gray.light,
    cursor: 'pointer',
  },
  deleteProfilePictureLink: {
    fontSize: 10,
    cursor: 'pointer',
  },
  userInfoArea: {
    flex: '1 1 auto',
    padding: theme.spacing(3, 5, 3, 15),
  },
  userName: {
    fontSize: 20,
  },
  email: {
    fontSize: 12,
    lineHeight: 1,
    color: theme.palette.gray.light,
    marginBottom: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  version: {
    position: 'absolute',
    fontSize: 12,
    lineHeight: 1,
    color: theme.palette.gray.light,
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  link: {
    fontSize: 12,
    color: theme.palette.secondary.main,
  },
}));

const userAccessApi = new UserAccessApi();
const blobStorageSystem = env('BLOB_STORAGE_SYSTEM');
const version = env('VERSION_INFO');
console.log('Version info: ' + version);

const UserPanel = ({
  userName,
  userEmail,
  userPanelOpened,
  toggleUserPanel,
  isLocalAuth,
}) => {
  console.log('UserPanel rendering...');
  const classes = useStyles();
  const { toastDispatch } = useToast();
  const userInitials = getInitials(userName);
  const [state, dispatch] = useAppState();
  const [isImageLoading, setImageLoading] = useState(false);

  const handleClickAway = (evt) => {
    if (evt.path) {
      var isUserPanelButton = Object.keys(evt.path).filter(function (key) {
        return evt.path[key].id === 'userPanelButton';
      });
      if (userPanelOpened && !isUserPanelButton.length > 0) {
        toggleUserPanel();
      }
    }
  };

  const handleChangeProfileImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageLoading(true);
      var file = event.target.files[0];
      var fileName = file.name;
      var reader = new FileReader();
      reader.onload = function (e) {
        // The file's text will be printed here
        //console.log(e.target.result);
        userAccessApi
          .updateUserProfilePicture(e.target.result, fileName)
          .then((response) => {
            const profilePictureUrlFromResponse = `${blobStorageSystem}${response}`;
            console.log(profilePictureUrlFromResponse);
            dispatch(updateUserProfilePicture(profilePictureUrlFromResponse));
            setImageLoading(false);
          })
          .catch((error) => {
            setImageLoading(false);
            var errorMessage = 'Image Upload Failed';
            if (error.response && error.response.data)
              errorMessage = `${errorMessage} - ${error.response.data}`;
            toastDispatch({
              type: ADD,
              payload: {
                content: errorMessage,
                type: 'error',
              },
            });
          });
      };
      reader.readAsDataURL(file);
    }
  };

  const identityEndpoint = env('IDENTITY_AUTH_ENDPOINT');
  const clientBaseEndpoint = env('IDENTITY_CLIENT_BASE_ENDPOINT');
  const changePwdEndpoint = `${identityEndpoint}/Account/ChangePassword/?returnUrl=${clientBaseEndpoint}`;

  const handleDeleteProfileImage = () => {
    setImageLoading(true);
    userAccessApi
      .deleteUserProfilePicture()
      .then(() => {
        dispatch(deleteUserProfilePicture());
        setImageLoading(false);
      })
      .catch((error) => {
        setImageLoading(false);
        var errorMessage = 'Image Delete Failed';
        if (error.response && error.response.data)
          errorMessage = `${errorMessage} - ${error.response.data}`;
        toastDispatch({
          type: ADD,
          payload: {
            content: errorMessage,
            type: 'error',
          },
        });
      });
  };

  const renderAccuvAvatar = () => {
    if (isImageLoading) {
      return <BounceLoader size={60} loading={true} />;
    } else {
      return (
        <AccuvAvatar className={classes.avatar} src={state.userProfilePicture}>
          {userInitials}
        </AccuvAvatar>
      );
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <AccuvDrawer
        variant="persistent"
        anchor="right"
        open={userPanelOpened}
        PaperProps={{
          elevation: 8,
        }}
        ModalProps={{
          keepMounted: false,
          className: classes.modal,
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.container}>
          <AccuvIconButton
            className={classes.closeButton}
            size="small"
            onClick={toggleUserPanel}
          >
            <CloseIcon />
          </AccuvIconButton>
          <div className={classes.avatarArea}>
            {renderAccuvAvatar()}
            <AccuvLink
              className={classes.avatarLink}
              variant="body2"
              underline="none"
              component="label"
            >
              Edit Image
              <input
                type="file"
                accept="image/x-png,image/jpeg"
                hidden
                onChange={handleChangeProfileImage}
              ></input>
            </AccuvLink>
            {state.userProfilePicture && state.userProfilePicture.length > 1 && (
              <AccuvLink
                className={classes.deleteProfilePictureLink}
                variant="body2"
                underline="none"
                color="error"
                onClick={handleDeleteProfileImage}
              >
                Delete Image
              </AccuvLink>
            )}
          </div>
          <div className={classes.userInfoArea}>
            <AccuvTypography className={classes.userName}>
              {capitalize(userName)}
            </AccuvTypography>
            <AccuvTypography className={classes.email}>{userEmail}</AccuvTypography>
            <div className={classes.actions}>
              {isLocalAuth && (
                <AccuvTypography variant="body2">
                  <AccuvLink
                    className={classes.link}
                    underline="none"
                    href={changePwdEndpoint}
                  >
                    Change My Password
                  </AccuvLink>
                </AccuvTypography>
              )}
              <AccuvTypography variant="body2">
                <AccuvLink
                  className={classes.link}
                  underline="none"
                  href="/apps/userLogout"
                >
                  Logout
                </AccuvLink>
              </AccuvTypography>
            </div>
          </div>
          <AccuvTypography className={classes.version}>
            {version ?? '0.0.1-local'}
          </AccuvTypography>
        </div>
      </AccuvDrawer>
    </ClickAwayListener>
  );
};

UserPanel.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  userPanelOpened: PropTypes.bool,
  toggleUserPanel: PropTypes.func,
  profilePicture: PropTypes.string,
  isLocalAuth: PropTypes.bool,
};

export default memo(UserPanel);
