import React from 'react';
import clsx from 'clsx';
import {
  AccuvContainer,
  AccuvButton,
  AccuvTypography,
  AccuvIcon,
} from '@accuv/shared-components';
import { useAppState } from 'providers/AppProvider';
import { selectProgram } from 'providers/AppProvider/ActionCreators';
import { Container } from '@material-ui/core';
import { Wrapper } from 'components';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import env from '@beam-australia/react-env';
import programIconMap from './ProgramIconMap';
import { mockProgramsHash } from 'utils/mock';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    minHeight: '100%',
  },
  message: {
    fontSize: 20,
    fontWeight: 'normal',
    marginBottom: 15,
  },
  flexContainer: {
    padding: '0px',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    marginLeft: -9,
    marginRight: -9,
    marginBottom: 30,
  },
  blockMenuItemRoot: {
    marginLeft: 9,
    marginRight: 9,
    marginTop: 5,
    marginBottom: 15,
    height: 100,
    borderRadius: 5,
    flex: '0 0 140px',
    background: '#FFF',
    color: '#404040',
    border: '1px solid #FFFFFF',
    transition: theme.transitions.create('all', {
      duration: '.3s',
      easing: theme.transitions.easing.easeOut,
      delay: 0,
    }),
    '&:last-child': {
      marginRight: 0,
    },
    '& .mainspa-MuiButton-label': {
      display: 'block',
    },
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px #FFFFFF, inset 0 -1px 0 #FFFFFF',
      border: '1px solid #5839A0',
      background: '#FFF',
      color: '#404040',
    },
  },
  iconContainer: {
    height: 50,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  labelContainer: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelInnerContainer: {
    fontSize: 13,
    lineHeight: 1.2,
    overflow: 'hidden',
    textAlign: 'center',
    whiteSpace: 'normal',
    textTransform: 'capitalize',
  },
  icon: {
    color: '#5839A0',
    fontSize: 35,
  },
}));

const ProgramSelectorFullPage = ({ programs }) => {
  const classes = useStyles();
  const byPassModuleSecurity = env('BYPASS_MODULE_SECURITY');
  const [, dispatch] = useAppState();

  const handleClick = (e) => {
    //alert(`Program selected: ${e.currentTarget.value} / ${e.currentTarget.textContent}`);
    dispatch(
      selectProgram({
        id: e.currentTarget.value,
        name: e.currentTarget.textContent,
      })
    );
  };

  if (byPassModuleSecurity === 'true') {
    programs = mockProgramsHash;
  }
  let programButtons = [];
  var index = 0;
  for (var key in programs) {
    if (key == 'groupId') continue;
    var value = programs[key];
    programButtons.push(
      <AccuvButton
        key={index++}
        variant="contained"
        color="secondary"
        className={classes.blockMenuItemRoot}
        classes={{
          containedPrimary: clsx(classes.buttonBlockMenuItem),
        }}
        value={key}
        onClick={handleClick}
      >
        <div className={clsx(classes.iconContainer, classes.icon)}>
          {programIconMap[key] ? (
            <AccuvIcon icon={programIconMap[key]} />
          ) : (
            <AccuvIcon icon="default" />
          )}
        </div>
        <div className={classes.labelContainer}>
          <div className={classes.labelInnerContainer}>{value}</div>
        </div>
      </AccuvButton>
    );
  }

  return (
    <Wrapper padding>
      <Container className={classes.content}>
        <div data-test="welcome-container">
          <AccuvTypography className={classes.message}>
            Please select a program to continue
          </AccuvTypography>
        </div>
        <AccuvContainer className={classes.flexContainer}>
          {programButtons}
        </AccuvContainer>
      </Container>
    </Wrapper>
  );
};

ProgramSelectorFullPage.propTypes = {
  programs: PropTypes.object,
};

export default ProgramSelectorFullPage;
