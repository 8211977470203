import React from 'react';
import PropTypes from 'prop-types';
import {
  AccuvSnackbar,
  AccuvAlert,
  AccuvDialog,
  AccuvDialogTitle,
  AccuvDialogContent,
  AccuvDialogContentText,
  AccuvDialogActions,
  AccuvButton,
} from '@accuv/shared-components';
import { topBarHeight } from 'styleVariables';
import { useToast, REMOVE } from 'providers/ToastProvider';

const Toast = ({ toast }) => {
  const { toastDispatch } = useToast();

  const handleClose = (t) => {
    toastDispatch({ type: REMOVE, payload: { id: t.id } });
  };

  const renderItem = (content) => {
    if (typeof content === 'function') {
      return content();
    } else {
      return content;
    }
  };
  const vertical = 'top';
  const horizontal = 'right';
  const top = topBarHeight + 10;
  let distance = 60;
  let index = 0;

  return (
    <div>
      {toast.map((t) => {
        if (t.type === 'alert') {
          return (
            <AccuvDialog
              key={t.id}
              open={true}
              onClose={() => handleClose(t)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <AccuvDialogTitle id="alert-dialog-title">
                {t.content.title}
              </AccuvDialogTitle>
              <AccuvDialogContent primaryTextColor>
                <AccuvDialogContentText id="alert-dialog-description">
                  {t.content.description}
                </AccuvDialogContentText>
              </AccuvDialogContent>
              <AccuvDialogActions>
                <AccuvButton
                  onClick={() => handleClose(t)}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  Ok
                </AccuvButton>
              </AccuvDialogActions>
            </AccuvDialog>
          );
        } else {
          let snackTop = top + distance * index;
          index++;
          return (
            <AccuvSnackbar
              key={t.id}
              autoHideDuration={5000}
              open={true}
              onClose={() => handleClose(t)}
              anchorOrigin={{ vertical, horizontal }}
              style={{ top: snackTop }}
            >
              <AccuvAlert onClose={() => handleClose(t)} severity={t.type}>
                {renderItem(t.content)}
              </AccuvAlert>
            </AccuvSnackbar>
          );
        }
      })}
    </div>
  );
};

Toast.propTypes = {
  toast: PropTypes.array,
};

export default Toast;
