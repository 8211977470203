/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import {
  AccuvButton,
  AccuvDialog,
  AccuvDialogActions,
  AccuvDialogContent,
  AccuvList,
  AccuvListItem,
  AccuvStatusMessageBox,
} from '@accuv/shared-components';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: 48,
    width: 250,
    maxHeight: '50%',
    boxShadow: theme.shadows[5],
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
    [theme.breakpoints.down('sm')]: {
      top: 96,
    },
  },
  content: {
    padding: '0!important',
    ...theme.mixins.scrollbar,
  },
  message: {
    padding: 40,
  },
  messageText: {
    padding: 10,
  },
  actions: {
    paddingTop: 5,
  },
}));

const SearchResults = ({ results, open, handleClose }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = (route) => {
    console.info(`You clicked ${route}`);
    navigate(route);
    handleClose();
  };

  let resultItems;
  if (results.length > 0) {
    resultItems = results.map((result, index) => {
      return (
        <AccuvListItem key={index} button onClick={() => handleClick(result.route)}>
          <ListItemText primary={result.title} />
        </AccuvListItem>
      );
    });
  } else {
    resultItems = (
      <div className={classes.message}>
        <AccuvStatusMessageBox className={classes.messageText}>
          No results
        </AccuvStatusMessageBox>
      </div>
    );
  }

  return (
    <AccuvDialog
      open={open}
      panelStyle
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        panelStylePaper: classes.paper,
      }}
    >
      <AccuvDialogContent className={classes.content}>
        <AccuvList dense component="nav" aria-label="secondary mailbox folders">
          {resultItems}
        </AccuvList>
      </AccuvDialogContent>
      <AccuvDialogActions className={classes.actions}>
        <AccuvButton onClick={handleClose} variant="text" color="primary">
          Close
        </AccuvButton>
      </AccuvDialogActions>
    </AccuvDialog>
  );
};

SearchResults.defaultProps = {
  results: [],
  open: false,
};

SearchResults.propTypes = {
  results: PropTypes.array,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default SearchResults;
