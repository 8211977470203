import { ApiBase } from '../API';
import env from '@beam-australia/react-env';

export interface IUserNotificationApi {
  sendTokenToServer: (parameter: any) => Promise<void>;
}

const userNotificationBasePath = env('MONOLITHIC_BASE_ENDPOINT');

class UserNotificationApi extends ApiBase implements IUserNotificationApi {
  constructor() {
    super(userNotificationBasePath);
  }

  public sendTokenToServer = async (parameter: any) => {
    return this.instance.post<void>(
      '/api/UserNotification/RegisterUserDeviceForPushNotifications',
      parameter
    );
  };
}

export default UserNotificationApi;
