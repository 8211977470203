import React from 'react';
import PropTypes from 'prop-types';
import { AccuvBox } from '@accuv/shared-components';

const RequestTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <AccuvBox>{children}</AccuvBox>}
    </div>
  );
};

RequestTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export default RequestTabPanel;
