/* eslint-disable no-undef */
import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useAppState } from 'providers/AppProvider/AppProvider';
import { toggleMonolithicModule } from 'providers/AppProvider/ActionCreators';
import ProgramService from 'services/Program/ProgramService';
import { ProgramSelectorFullPage, PowerBiSelectorFullPage } from 'components';
import { AccuvLoadingIcon, AccuvStatusMessageBox } from '@accuv/shared-components';
import { makeStyles } from '@material-ui/core/styles';
import ApplicationApi from 'services/Application/ApplicationApi';
import { dashboardsLoaded } from 'providers/AppProvider/ActionCreators';
import { createDashboardDictionary } from 'services/Authorization/UserAccess';
import useMountEffect from 'hooks/mountEffect';

const useStyles = makeStyles(() => ({
  root: {
    height: 'calc(100vh - 48px) !important',
  },
  loading: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  error: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const applicationApi = new ApplicationApi();

const PowerBiDashboardMenu = ({ id }) => {
  console.log('PowerBiDashboardMenu rendering...');
  document.title = 'AccuV | Dashboards';
  const classes = useStyles();
  const selectedProgram = ProgramService.getSelectedProgramId();
  console.log(selectedProgram);
  const [dashboardMenuState, setDashboardMenuState] = useState({
    status: 'start',
    program: selectedProgram,
    items: [],
  });

  if (dashboardMenuState.program !== selectedProgram) {
    setDashboardMenuState({
      status: 'start',
      program: selectedProgram,
    });
  }

  const [state, dispatch] = useAppState();

  useMountEffect(() => {
    //Use this effect if we fetch the dashboards for all programs in one trip to the server
  });

  useEffect(() => {
    if (
      (dashboardMenuState.program && !state.dashboards) ||
      (dashboardMenuState.program &&
        state.dashboards &&
        !state.dashboards[dashboardMenuState.program])
    ) {
      // Dashboard list is not loaded in the app state yet, let's load it!
      // TODO: maybe it's better to fetch the dashboards for all programs in one trip (we have to refactor the application.api microservice to do that)
      console.info('Fetching user dashboards by program...');
      // Fetch dashboards by program
      applicationApi
        .getDashboardsByProgram(dashboardMenuState.program)
        .then((dashboardsByProgram) => {
          //console.log(dashboardsByProgram);
          let dashboardsHash = {};
          if (dashboardsByProgram) {
            dashboardsHash = createDashboardDictionary(
              dashboardMenuState.program,
              state.dashboards,
              dashboardsByProgram
            );
          }
          //console.log(dashboardsHash);
          dispatch(dashboardsLoaded(dashboardsHash));
          setDashboardMenuState({
            ...dashboardMenuState,
            status: 'done',
            items: dashboardsByProgram,
          });
        })
        .catch((error) => {
          console.error(error);
          dispatch(dashboardsLoaded('error'));
          setDashboardMenuState({
            ...dashboardMenuState,
            status: 'error',
          });
        });
    } else if (
      // Dashboards are already loaded in the app state, let's proceed!
      dashboardMenuState.program &&
      state.dashboards &&
      state.dashboards[dashboardMenuState.program]
    ) {
      setDashboardMenuState({
        ...dashboardMenuState,
        status: 'done',
        items: state.dashboards[dashboardMenuState.program],
      });
    }
  }, [dashboardMenuState.program]);

  //componentDidUpdate
  useEffect(() => {
    if (dashboardMenuState.status === 'done' && dashboardMenuState.program) {
      if (state.monolithicModule.opened === false) {
        //We are notifying the layout that a mono module was opened mainly to show the program selector in the header
        dispatch(toggleMonolithicModule(id, true));
      }
    }
  });

  //componentWillUnmount
  useEffect(() => {
    // do any cleanup after module is unmount
    return function cleanup() {
      dispatch(toggleMonolithicModule(id, false));
    };
  }, [dispatch, id]);

  //Show the program selector if program is not selected yet (all available programs)
  if (state.permissions && !dashboardMenuState.program) {
    let modulePrograms = state.permissions[id];
    return <ProgramSelectorFullPage programs={modulePrograms}></ProgramSelectorFullPage>;
  }

  if (dashboardMenuState.status === 'start') {
    setDashboardMenuState({
      ...dashboardMenuState,
      status: 'loading',
    });
  }
  let dashboardMenu;
  if (dashboardMenuState.status === 'loading') {
    dashboardMenu = (
      <div className={classes.loading}>
        <AccuvLoadingIcon></AccuvLoadingIcon>
      </div>
    );
  } else if (dashboardMenuState.status === 'error') {
    dashboardMenu = (
      <div className={classes.error}>
        <AccuvStatusMessageBox>
          An error occurred loading the module, please refresh the page.
        </AccuvStatusMessageBox>
      </div>
    );
  } else {
    dashboardMenu = (
      <PowerBiSelectorFullPage items={dashboardMenuState.items}></PowerBiSelectorFullPage>
    );
  }
  return dashboardMenu;
};

PowerBiDashboardMenu.defaultProps = {
  id: -1,
};

PowerBiDashboardMenu.propTypes = {
  id: PropTypes.number,
};

export default memo(PowerBiDashboardMenu);
