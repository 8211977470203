import React from 'react';
import PropTypes from 'prop-types';
import { AuthConsumer } from 'providers/AuthProvider';

const PrivateRoute = ({ children }) => {
  const protectedRoute = (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect }) => {
        if (isAuthenticated()) {
          return children;
        } else {
          signinRedirect();
          return (
            <div className="loader-container">
              <span className="loader-spinner"></span>
              <span className="loader-text">Loading ...</span>
            </div>
          );
        }
      }}
    </AuthConsumer>
  );
  return protectedRoute;
};

PrivateRoute.propTypes = {
  component: PropTypes.any,
};

export default PrivateRoute;
