import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  AccuvContainer,
  AccuvButton,
  AccuvStatusMessageBox,
  AccuvIcon,
} from '@accuv/shared-components';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { AccuvTypography } from '@accuv/shared-components';
import { useAppState } from 'providers/AppProvider';
import { getAppsRoutes } from 'routes/Routes';
import { moduleInGroup } from 'services/Authorization/UserAccess';
import { capitalize } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {},
  flexContainer: {
    padding: '0px',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    marginLeft: -9,
    marginRight: -9,
    marginBottom: 30,
  },
  blockMenuItemRoot: {
    marginLeft: 9,
    marginRight: 9,
    marginTop: 5,
    marginBottom: 15,
    height: 100,
    borderRadius: 5,
    flex: '0 0 140px',
    background: '#FFF',
    color: '#404040',
    border: '1px solid #FFFFFF',
    transition: theme.transitions.create('all', {
      duration: '.3s',
      easing: theme.transitions.easing.easeOut,
      delay: 0,
    }),
    '&:last-child': {
      marginRight: 0,
    },
    '& .mainspa-MuiButton-label': {
      display: 'block',
    },
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px #FFFFFF, inset 0 -1px 0 #FFFFFF',
      border: '1px solid #5839A0',
      background: '#FFF',
      color: '#404040',
    },
  },
  blockMenuItemSkeleton: {
    marginLeft: 9,
    marginRight: 9,
    marginBottom: 15,
    height: 100,
    borderRadius: 5,
    flex: '0 0 140px',
    background: '#FFF',
    color: '#404040',
    transition: theme.transitions.create('all', {
      duration: '.3s',
      easing: theme.transitions.easing.easeOut,
      delay: 0,
    }),
    '&:last-child': {
      marginRight: 0,
    },
    '& .mainspa-MuiButton-label': {
      display: 'block',
    },
  },
  blockMenuItemSkeleton2: {
    marginLeft: 9,
    marginRight: 9,
    marginBottom: 15,
    height: 100,
    borderRadius: 5,
    flex: '0 0 140px',
    background: '#fafafa',
    color: '#404040',
    transition: theme.transitions.create('all', {
      duration: '.3s',
      easing: theme.transitions.easing.easeOut,
      delay: 0,
    }),
    '&:last-child': {
      marginRight: 0,
    },
    '& .mainspa-MuiButton-label': {
      display: 'block',
    },
  },
  iconContainer: {
    height: 50,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  labelContainer: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelInnerContainer: {
    fontSize: 13,
    lineHeight: 1.2,
    overflow: 'hidden',
    textAlign: 'center',
    whiteSpace: 'normal',
    textTransform: 'capitalize',
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: 35,
  },
  skeleton: {
    width: '100%',
  },
  message: {
    fontSize: 20,
    fontWeight: 'normal',
    marginBottom: 15,
  },
  message2: {
    fontSize: 20,
    fontWeight: 'normal',
    marginBottom: 15,
    color: '#fafafa',
  },
}));

// eslint-disable-next-line react/prop-types
const ModulePageMenu = () => {
  const classes = useStyles();
  const [state] = useAppState();
  const apps = getAppsRoutes();
  let appsButtons;
  let menu;

  const createNavButton = (index, route, parent) => {
    return (
      <AccuvButton
        component={Link}
        to={`/${parent}/${route.path}`}
        key={index}
        variant="contained"
        color="secondary"
        className={classes.blockMenuItemRoot}
        classes={{
          containedPrimary: clsx(classes.buttonBlockMenuItem),
        }}
      >
        <div className={clsx(classes.iconContainer, classes.icon)}>
          {route.iconId ? (
            <AccuvIcon icon={route.iconId} className={classes.icon} />
          ) : (
            <route.icon className={classes.icon} />
          )}
        </div>
        <div className={classes.labelContainer}>
          <div className={classes.labelInnerContainer}>{route.name}</div>
        </div>
      </AccuvButton>
    );
  };
  if (!state.permissions) {
    // Show the skeleton if the items are not available yet
    const skeletonItems = [1, 2, 3, 4, 5, 6, 7];
    menu = skeletonItems.map((index) => (
      <Skeleton variant="rect" key={index} className={classes.blockMenuItemSkeleton} />
    ));
  } else if (state.permissions === 'error') {
    return (
      <div className={classes.error}>
        <AccuvStatusMessageBox>
          An error occurred loading the menu, please try again.
        </AccuvStatusMessageBox>
      </div>
    );
  } else if (!state.group) {
    // Show the skeleton if the group is not present to have a smooth transition
    const skeletonItems = [1, 2];
    menu = skeletonItems.map((index) => (
      <Skeleton variant="rect" key={index} className={classes.blockMenuItemSkeleton2} />
    ));
    return (
      <>
        <AccuvTypography className={classes.message2}>...</AccuvTypography>
        <AccuvContainer className={classes.flexContainer}>{menu}</AccuvContainer>
      </>
    );
  } else {
    appsButtons = apps.map((route, index) => {
      return route.display && moduleInGroup(route.id, state.permissions, state.group?.Id)
        ? createNavButton(index, route, 'apps')
        : null;
    });
    menu = appsButtons;
  }

  return (
    <>
      <AccuvTypography className={classes.message}>
        {capitalize(state.group ? state.group.Name : '...')}
      </AccuvTypography>
      <AccuvContainer className={classes.flexContainer}>{menu}</AccuvContainer>
    </>
  );
};

ModulePageMenu.propTypes = {};

export default ModulePageMenu;
