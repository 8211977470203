const BackgroundJobStates = {
  ENQUEUED: 'enqueued',
  RUNNING: 'running',
  RETRYING: 'retrying',
  COMPLETED: 'completed',
  FAILED: 'failed',
  DELETED: 'deleted',
  CANCELLING: 'cancelling',
  CANCELLED: 'cancelled',
};

export default BackgroundJobStates;
