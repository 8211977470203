const programIconMap = {
  ACCUV: 'accuv',
  ALLIOS: 'default',
  ATC_LIMO: 'atc',
  ATC_MAINT: 'atc',
  ATC_SHARK: 'atc',
  ATT_FIBER: 'att',
  ATT_MWV: 'att',
  ATT_MXC: 'att',
  ATTNT: 'att',
  BBQ_SHRIMP: 'default',
  BEYOND: 'beyond',
  CALIENTE: 'caliente',
  CCI_MODS: 'cci-mods',
  CTL: 'ctl',
  DEMO: 'accuv',
  EPC: 'default',
  ERICSSON: 'ericsson',
  EXTENET: 'extenet',
  HDC: 'hdc',
  IBEX: 'default',
  INTG: 'default',
  JHC_VTRS: 'default',
  LENDLEASE: 'lendlease',
  MOB: 'mob',
  NDPATT: 'att',
  NETDEP: 'default',
  NETENG: 'default',
  NEXS_INT: 'nexius',
  SAMSUNG: 'samsung',
  SIGFOX: 'sigfox',
  SPRINT: 'sprint',
  TENWIRE: 'default',
  TILLMAN: 'tillman',
  TMOBILE: 'tmobile',
  VLX: 'nexius',
  VLXSI: 'nexius',
  VZW: 'verizon',
};

export default programIconMap;
