import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotificationsIcon from '@material-ui/icons/Notifications';

import TodosNew from './TodosNew/TodosNew';
import RequestsNew from './RequestsNew/RequestsNew';
import AnnouncementsNew from './AnnouncementsNew/AnnouncementsNew';

import ErrorBoundary from 'hoc/Error/ErrorBoundary';
import {
  AccuvTypography,
  AccuvPaper,
  AccuvGrid,
  AccuvIconButton,
} from '@accuv/shared-components';

import { useAppState } from 'providers/AppProvider';
import { refreshNotifications } from 'providers/AppProvider/ActionCreators';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  sectionTitle: {
    fontSize: 20,
    marginBottom: 10,
  },
  columnTitle: {
    marginBottom: 5,
    fontSize: 16,
    fontWeight: 'bold',
  },
  paperWithScroll: {
    color: theme.palette.text.secondary,
    height: 420,
    overflowY: 'auto',
    ...theme.mixins.scrollbar,
  },
  paperWithoutScroll: {
    color: theme.palette.text.secondary,
    height: 420,
  },
  ActivityTitle: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconButton: {
    borderRadius: 0,
    '&:hover': {
      color: theme.palette.white,
      background: theme.palette.secondary.main,
      paddingRight: '6px',
      '&::before': {
        borderRadius: 0,
        transform: 'scale(1)',
      },
    },
  },
  spinningIconButton: {
    marginLeft: theme.spacing(3),
    lineHeight: '20px',
    verticalAlign: 'middle',
  },
}));

const MyActivityNew = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [state, dispatch] = useAppState();

  const handleRefreshNotifications = () => {
    dispatch(refreshNotifications());
  };

  return (
    <div>
      <div className={classes.ActivityTitle}>
        <AccuvTypography variant="h5" className={classes.sectionTitle}>
          My Activity
        </AccuvTypography>
        <div>
          <AccuvIconButton
            className={classes.iconButton}
            size="small"
            onClick={() => {
              navigate('/apps/notificationCenter');
            }}
            style={{ marginRight: 8 }}
          >
            <NotificationsIcon />
            <AccuvTypography>Notification Center</AccuvTypography>
          </AccuvIconButton>
          <AccuvIconButton
            className={classes.iconButton}
            size="small"
            onClick={handleRefreshNotifications}
          >
            <RefreshIcon />
            <AccuvTypography>Refresh</AccuvTypography>
          </AccuvIconButton>
        </div>
      </div>
      <div className={classes.root}>
        <AccuvGrid container spacing={3}>
          <AccuvGrid item xs={12} md={6} lg={4}>
            <AccuvTypography variant="h6" className={classes.columnTitle}>
              To Do
              {state.refreshTodo && (
                <span className={classes.spinningIconButton}>
                  <CircularProgress size={20} className={classes.progress} />
                </span>
              )}
            </AccuvTypography>
            <AccuvPaper className={classes.paperWithoutScroll}>
              <ErrorBoundary>
                <TodosNew />
              </ErrorBoundary>
            </AccuvPaper>
          </AccuvGrid>
          <AccuvGrid item xs={12} md={6} lg={4}>
            <AccuvTypography variant="h6" className={classes.columnTitle}>
              Requests
              {state.refreshRequest && (
                <span className={classes.spinningIconButton}>
                  <CircularProgress size={20} className={classes.progress} />
                </span>
              )}
            </AccuvTypography>
            <AccuvPaper className={classes.paperWithoutScroll}>
              <ErrorBoundary>
                <RequestsNew />
              </ErrorBoundary>
            </AccuvPaper>
          </AccuvGrid>
          <AccuvGrid item xs={12} lg={4}>
            <AccuvTypography variant="h6" className={classes.columnTitle}>
              Announcements
              {state.refreshAnnouncement && (
                <span className={classes.spinningIconButton}>
                  <CircularProgress size={20} className={classes.progress} />
                </span>
              )}
            </AccuvTypography>
            <AccuvPaper className={classes.paperWithoutScroll}>
              <ErrorBoundary>
                <AnnouncementsNew />
              </ErrorBoundary>
            </AccuvPaper>
          </AccuvGrid>
        </AccuvGrid>
      </div>
    </div>
  );
};

export default MyActivityNew;
