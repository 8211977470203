import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {
  AccuvTypography,
  AccuvListItem,
  AccuvListItemText,
} from '@accuv/shared-components';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: `1px solid ${theme.palette.gray.border}`,
  },
  helpItemTitle: {
    fontSize: 14,
    position: 'relative',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  helpItemIcon: {
    position: 'relative',
    fontSize: '16px',
    marginRight: '8px',
    top: '3px',
  },
  helpItemLinkIcon: {
    position: 'absolute',
    right: '0px',
    fontSize: '16px',
    top: '9px',
  },
}));

const HelpItem = ({ text, tooltip, link, Icon, iconColor }) => {
  const classes = useStyles();

  return (
    <AccuvListItem
      button
      className={classes.listItem}
      onClick={() => {
        if (link) {
          window.open(link, '_blank');
        }
      }}
    >
      <Tooltip title={tooltip} enterDelay={700}>
        <AccuvListItemText>
          <AccuvTypography className={classes.helpItemTitle}>
            {Icon && (
              <span style={iconColor ? { color: `${iconColor}` } : {}}>
                <Icon className={classes.helpItemIcon} />
              </span>
            )}
            {text}
            <LaunchIcon className={classes.helpItemLinkIcon} />
          </AccuvTypography>
        </AccuvListItemText>
      </Tooltip>
    </AccuvListItem>
  );
};

HelpItem.propTypes = {
  text: PropTypes.string,
  tooltip: PropTypes.string,
  link: PropTypes.string,
  Icon: PropTypes.any,
  iconColor: PropTypes.string,
};

export default HelpItem;
