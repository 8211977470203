import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  AccuvList,
  AccuvButton,
  AccuvLoadingIcon,
  AccuvStatusMessageBox,
} from '@accuv/shared-components';

import ToDo from '../Todos/ToDo';
import TodosNewSkeleton from './TodosNewSkeleton';

import NotificationApi from 'services/Notification/NotificationApi';
import { useToast, ADD } from 'providers/ToastProvider';
import { makeStyles } from '@material-ui/core/styles';
import { useAppState } from 'providers/AppProvider';

import {
  refreshTodoCompleted,
  updateTodoBlockCompleted,
} from 'providers/AppProvider/ActionCreators';

const useStyles = makeStyles((theme) => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: 360,
    overflowY: 'auto',
    ...theme.mixins.scrollbar,
  },
  message: {
    marginTop: 'calc(50% - 60px)',
  },
  messageText: {
    padding: '0',
  },
  moreHyperlinkSection: {
    textAlign: 'center',
    marginLeft: '4px',
    marginRight: '4px',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  moreHyperlink: {
    width: '100%',
  },
}));

const notificationApi = new NotificationApi();

const pageSize = 50; //by default fetch 50 rows
const notificationType = 'todo';
const notificationStatus = 'done';

const TodosArchive = (props) => {
  const classes = useStyles();
  const { toastDispatch } = useToast();
  const [state, dispatch] = useAppState();

  const currentPage = useRef(1);
  const totalPages = useRef(0);
  const todoArchiveRefArray = useRef([]);

  const [todosArchive, setTodosArchive] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleLoadMore = useCallback(() => {
    if (totalPages.current > 0 && currentPage.current < totalPages.current) {
      currentPage.current++;
      setIsLoadingMore(true);
    }
  }, []);

  const markNotificationAsRead = (id) => {
    notificationApi
      .markAsRead(id)
      .then((notification) => {
        console.log(`Notification ${notification.id} read!`);
      })
      .catch((error) => {
        console.error(error);
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the notification as read',
            type: 'error',
          },
        });
      });
  };

  const handleNotificationItemClick = (notification) => () => {
    if (notification.read === false) {
      markNotificationAsRead(notification.id);
    }
  };

  const filterNotificationsByProgram = () => {
    let not;

    if (props.filterProgramVal.length > 0) {
      not = todoArchiveRefArray.current.filter(
        (n) =>
          props.filterProgramVal.filter((p) => p.programId === n.payload.program).length >
          0
      );
    } else {
      not = todoArchiveRefArray.current;
    }

    return not;
  };

  const loadNotifications = useCallback(
    async (filterValues) => {
      try {
        if (todoArchiveRefArray.current.length > 0) {
          let lastElementCreatedDate =
            todoArchiveRefArray.current[todoArchiveRefArray.current.length - 1];

          filterValues.toDate = lastElementCreatedDate.createdDate;
        }

        const data = await notificationApi.getFilteredNotifications(filterValues);
        if (totalPages.current === 0) {
          totalPages.current = data.totalPages;
        }
        todoArchiveRefArray.current.push(...data.notifications);
        setTodosArchive(filterNotificationsByProgram());
        // setTodosArchive(todoArchiveRefArray.current);
        //
      } catch (error) {
        toastDispatch({
          type: ADD,
          payload: {
            content: error.message,
            type: 'error',
          },
        });
      } finally {
        if (isLoadingMore) {
          setIsLoadingMore(false);
        }

        if (state.refreshTodo) {
          dispatch(refreshTodoCompleted());
        }
      }
    },
    [filterNotificationsByProgram, isLoadingMore, state.refreshTodo]
  );

  const markAllNotificationAsRead = (ids) => {
    notificationApi
      .markAllAsRead(ids)
      .then(() => {})
      .catch(() => {
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the notification as read',
            type: 'error',
          },
        });
      })
      .finally(() => {
        props.markAllReadCallback();
      });
  };

  useEffect(() => {
    if (
      (totalPages.current === 0 && !isLoadingMore) || //first load
      isLoadingMore //on more click
    ) {
      loadNotifications({
        pageSize,
        typeStatus: [{ type: notificationType, status: [notificationStatus] }],
      });
    }
  }, [isLoadingMore]);

  useEffect(() => {
    if (state.refreshTodo) {
      currentPage.current = 1;
      todoArchiveRefArray.current = [];
      totalPages.current = 0;

      loadNotifications({
        pageSize,
        typeStatus: [{ type: notificationType, status: [notificationStatus] }],
      });
    }
  }, [state.refreshTodo]);

  useEffect(() => {
    if (state.notificationsToUpdate && state.notificationsToUpdate.length > 0) {
      if (
        todosArchive &&
        Array.isArray(todosArchive) &&
        todoArchiveRefArray.current.length > 0
      ) {
        let newTodosArchive = [...todoArchiveRefArray.current];
        let notificationsToUpdateTodoArchive = state.notificationsToUpdate.filter(
          (y) => y.type === 'todo' && y.payload.status === 'done'
        );

        notificationsToUpdateTodoArchive.map((x) => {
          let itemToUpdateIndex = newTodosArchive.findIndex((elem) => elem.id === x.id);
          if (itemToUpdateIndex > -1) {
            if (x.delete) {
              x.payload.status = 'done';
            }

            newTodosArchive[itemToUpdateIndex] = x;
          }
          //if it s a new notification: add it on the top:  createdDate === lastModifiedBy only on creation
          else if (x.lastModifiedDate === x.createdDate) {
            newTodosArchive.unshift(x);
          }
        });

        todoArchiveRefArray.current = newTodosArchive;
        setTodosArchive(newTodosArchive);
      }

      dispatch(updateTodoBlockCompleted());
    }
  }, [state.notificationsToUpdate]);

  useEffect(() => {
    try {
      if (totalPages.current !== 0) {
        let filteredNotificationsByProgram = filterNotificationsByProgram();
        setTodosArchive(filteredNotificationsByProgram);
      }
    } catch (error) {
      setTodosArchive([]);
    }
  }, [props.filterProgramVal]);

  useEffect(() => {
    if (props.markAllReadFromParent) {
      if (Array.isArray(todosArchive) && todosArchive.length > 0) {
        const ids = todosArchive
          .filter(
            (todo) => todo.read === false && todo.payload.status.toLowerCase() === 'done'
          )
          .map((opt) => opt.id);
        ids.length > 0 && markAllNotificationAsRead(ids);
      }
    }
  }, [props.markAllReadFromParent]);

  let todosArchivePanel;
  let showMore = currentPage.current < totalPages.current;

  if (!todosArchive) {
    todosArchivePanel = <TodosNewSkeleton />;
  } else if (Array.isArray(todosArchive) && todosArchive.length >= 0) {
    let todosArchiveItems = todosArchive.map((todo, index) => {
      return (
        <ToDo
          key={index}
          todo={todo}
          index={index}
          todosLength={todosArchive.length}
          onClick={handleNotificationItemClick(todo)}
        />
      );
    });
    if (todosArchiveItems.length > 0) {
      todosArchivePanel = (
        <AccuvList dense className={classes.list}>
          {todosArchiveItems}
          {showMore && (
            <div className={classes.moreHyperlinkSection}>
              {isLoadingMore ? (
                <AccuvLoadingIcon size={20} />
              ) : (
                <AccuvButton
                  variant="outlined"
                  color="primary"
                  className={classes.moreHyperlink}
                  onClick={handleLoadMore}
                >
                  LOAD MORE ...
                </AccuvButton>
              )}
            </div>
          )}
        </AccuvList>
      );
    } else {
      todosArchivePanel = (
        <div className={classes.message}>
          <AccuvStatusMessageBox className={classes.messageText}>
            No archive todos here
          </AccuvStatusMessageBox>
        </div>
      );
    }
  } else {
    todosArchivePanel = (
      <div className={classes.message}>
        <AccuvStatusMessageBox className={classes.messageText}>
          Something went wrong
        </AccuvStatusMessageBox>
      </div>
    );
  }

  return todosArchivePanel;
};

export default TodosArchive;
