/* eslint-disable no-undef */
import React, { useCallback, useEffect } from 'react';
import { MonoFrontend } from 'components';
import { Apps } from 'constants/Apps';
import ApplicationApi from 'services/Application/ApplicationApi';
import { useToast, ADD } from 'providers/ToastProvider';
import { useParams, useNavigate } from 'react-router-dom';
import env from '@beam-australia/react-env';
import SCMBKGTASKNAMES from '../../constants/BackgroundJobTasks';

const debug = env('DEBUG');
const min = debug === true ? '' : '.min';
const applicationApi = new ApplicationApi();

// eslint-disable-next-line react/prop-types
const ProjectManagementImportResults = ({ id, type, standalone, handleClose }) => {
  const { toastDispatch } = useToast();
  const params = useParams();
  if (id == null) {
    id = params.id;
  }

  return (
    <MonoFrontend
      id={Apps.Ids.PM}
      programAgnostic={true}
      name="Project Management"
      viewUrl="/ProjectManagement/TrackerView"
      scriptBundle={`/Scripts/Bundles/ProjectManagement${min}.js`}
      cssBundle={`/Content/Bundles/ProjectManagement${min}.css`}
      didMount={useCallback(() => {
        console.log('ProjectManagement init callback');
        applicationApi
          .getUserBackgroundJob(id)
          .then((bkgJob) => {
            //console.log(bkgJob);
            let importType = type;
            if (importType == null) importType = bkgJob.Description;
            if (bkgJob.ResultData) {
              if (importType === 'Project Management - Jobs Import') {
                Modules.Schedule.ViewModels.ScheduleSitesPod.openCsvResultWindow(
                  bkgJob.ResultData,
                  standalone
                );
              }
              if (importType === 'Project Management - Job Commments Import') {
                Modules.Schedule.ViewModels.ScheduleSitesPod.openCsvCommentResultWindow(
                  bkgJob.ResultData,
                  standalone
                );
              }
            } else {
              toastDispatch({
                type: ADD,
                payload: {
                  content: 'Import results not available',
                  type: 'error',
                },
              });
            }
          })
          .catch((error) => {
            console.error(error);
            toastDispatch({
              type: ADD,
              payload: {
                content: 'Error fetching import data',
                type: 'error',
              },
            });
          });
        if (handleClose) handleClose();
      }, [id, handleClose, standalone, toastDispatch, type])}
    ></MonoFrontend>
  );
};

// eslint-disable-next-line react/prop-types
const CosCenterManagementImportResults = ({ id, type, standalone, handleClose }) => {
  const { toastDispatch } = useToast();
  const params = useParams();
  if (id == null) {
    id = params.id;
  }

  return (
    <MonoFrontend
      id={Apps.Ids.CCM}
      programAgnostic={true}
      name="Cost Center Management"
      viewUrl="/CostCenterManagement/CostCenterManagementView"
      scriptBundle={`/Scripts/Bundles/CostCenterManagement${min}.js`}
      cssBundle={`/Content/Bundles/CostCenterManagement${min}.css`}
      didMount={useCallback(() => {
        console.log('Cost Center Management init callback');
        applicationApi
          .getUserBackgroundJob(id)
          .then((bkgJob) => {
            console.log(bkgJob);
            let importType = type;
            if (importType == null) importType = bkgJob.Description;
            if (bkgJob.ResultData) {
              if (importType === 'Cost Center Management - Sites Bulk Import') {
                Modules.CostCenterManagement.ViewModels.CCSiteMasterManagementGrid.openCsvResultWindow(
                  bkgJob.ResultData,
                  standalone
                );
              }
              if (importType === 'Cost Center Management - Jobs Bulk Import') {
                Modules.CostCenterManagement.ViewModels.CCJobsManagementGrid.openCsvResultWindow(
                  bkgJob.ResultData,
                  standalone
                );
              }
            } else {
              toastDispatch({
                type: ADD,
                payload: {
                  content: 'Import results not available',
                  type: 'error',
                },
              });
            }
          })
          .catch((error) => {
            console.error(error);
            toastDispatch({
              type: ADD,
              payload: {
                content: 'Error fetching import data',
                type: 'error',
              },
            });
          });
        if (handleClose) handleClose();
      }, [id, handleClose, standalone, toastDispatch, type])}
    ></MonoFrontend>
  );
};

// eslint-disable-next-line react/prop-types
const ScopeManagementImportResults = ({ id, type, standalone, handleClose }) => {
  const { toastDispatch } = useToast();
  const params = useParams();
  if (id == null) {
    id = params.id;
  }

  return (
    <MonoFrontend
      id={Apps.Ids.SCOPM}
      programAgnostic={true}
      name="Scope Management"
      viewUrl="/ScopingManagement/ScopeView"
      scriptBundle={`/Scripts/Bundles/ScopingAndCloseoutManagement.js`}
      cssBundle={`/Content/Bundles/ScopingAndCloseoutManagement.css`}
      didMount={useCallback(() => {
        console.log('Scope Management init callback');
        applicationApi
          .getUserBackgroundJob(id)
          .then((bkgJob) => {
            console.log(bkgJob);
            console.log(Modules);
            let importType = type;
            if (importType == null) importType = bkgJob.Description;
            if (bkgJob.ResultData) {
              if (importType === 'Scoping Management - Bulk Import') {
                Modules.ScopingManagement.ViewModels.ScopingMgmtViewModel.openCsvResultWindow(
                  bkgJob.ResultData,
                  standalone
                );
              }
              if (
                importType === 'Scoping Management - GC Firm Update' ||
                importType.startsWith('Firming PO')
              ) {
                Modules.ScopingManagement.ViewModels.ScopingMgmtViewModel.openGCsFIRMCsvResultWindow(
                  bkgJob.ResultData,
                  standalone
                );
              }
            } else {
              toastDispatch({
                type: ADD,
                payload: {
                  content: 'Import results not available',
                  type: 'error',
                },
              });
            }
          })
          .catch((error) => {
            console.error(error);
            toastDispatch({
              type: ADD,
              payload: {
                content: 'Error fetching import data',
                type: 'error',
              },
            });
          });
        if (handleClose) handleClose();
      }, [id, handleClose, standalone, toastDispatch, type])}
    ></MonoFrontend>
  );
};

// eslint-disable-next-line react/prop-types
const ClientInvoiceManagementImportResults = ({ id, type, standalone, handleClose }) => {
  const { toastDispatch } = useToast();
  const params = useParams();
  if (id == null) {
    id = params.id;
  }

  return (
    <MonoFrontend
      id={Apps.Ids.CIM}
      programAgnostic={true}
      name="Client Management | Submit Invoice"
      viewUrl="/ClientInvoiceManagement/SubmitInvoiceView"
      scriptBundle={`/Scripts/Bundles/ClientInvoiceManagement${min}.js`}
      cssBundle={`/Content/Bundles/ClientInvoiceManagement${min}.css`}
      didMount={useCallback(() => {
        console.log('Scope Management init callback');
        applicationApi
          .getUserBackgroundJob(id)
          .then((bkgJob) => {
            console.log(bkgJob);
            console.log(Modules);
            let importType = type;
            if (importType == null) importType = bkgJob.Description;
            if (bkgJob.ResultData) {
              if (importType === 'Client Management - Submit Invoices Bulk Import') {
                Modules.ClientInvoiceManagement.ViewModels.POPodHeaders.openBulkImportCsvResultWindow(
                  bkgJob.ResultData,
                  standalone
                );
              }
            } else {
              toastDispatch({
                type: ADD,
                payload: {
                  content: 'Import results not available',
                  type: 'error',
                },
              });
            }
          })
          .catch((error) => {
            console.error(error);
            toastDispatch({
              type: ADD,
              payload: {
                content: 'Error fetching import data',
                type: 'error',
              },
            });
          });
        if (handleClose) handleClose();
      }, [id, handleClose, standalone, toastDispatch, type])}
    ></MonoFrontend>
  );
};

// eslint-disable-next-line react/prop-types
const ItemManagementImportResults = ({ id, type, standalone, handleClose }) => {
  const { toastDispatch } = useToast();
  const params = useParams();
  if (id == null) {
    id = params.id;
  }

  return (
    <MonoFrontend
      id={Apps.Ids.SCMIM}
      programAgnostic={true}
      name="Item Management - Bulk Import"
      viewUrl="/SCMItemManagement/SCMItemManagementView"
      scriptBundle={`/Scripts/Bundles/SupplyChainManagement${min}.js`}
      //Since SupplyChainManagement doesn't have a css
      //Use ProjectManagement as Bundle for supplyChainManagement as temp solution
      cssBundle={`/Content/Bundles/ProjectManagement${min}.css`}
      didMount={useCallback(() => {
        console.log('Supply Chain Management init callback');
        applicationApi
          .getUserBackgroundJob(id)
          .then((bkgJob) => {
            console.log(bkgJob);
            let importType = type;
            if (importType == null) importType = bkgJob.Description;
            if (bkgJob.ResultData) {
              switch (importType) {
                case SCMBKGTASKNAMES.ITMMNGSAVE:
                  SupplyChainManagementHelper.viewResults(
                    bkgJob.ResultData,
                    standalone,
                    'ItemNumber',
                    'Item Number'
                  );
                  break;
                case SCMBKGTASKNAMES.WHMGTLOCATIONS:
                  SupplyChainManagementHelper.viewResults(
                    bkgJob.ResultData,
                    standalone,
                    'LocationNumber',
                    'Location Number'
                  );
                  break;
                default:
                  break;
              }
            } else {
              toastDispatch({
                type: ADD,
                payload: {
                  content: 'Import results not available',
                  type: 'error',
                },
              });
            }
          })
          .catch((error) => {
            console.error(error);
            toastDispatch({
              type: ADD,
              payload: {
                content: 'Error fetching import data',
                type: 'error',
              },
            });
          });
        if (handleClose) handleClose();
      }, [id, handleClose, standalone, toastDispatch, type])}
    ></MonoFrontend>
  );
};

// eslint-disable-next-line react/prop-types
const WorkOrderImportResults = ({ id, type, standalone, handleClose }) => {
  const { toastDispatch } = useToast();
  const params = useParams();
  if (id == null) {
    id = params.id;
  }

  return (
    <MonoFrontend
      id={Apps.Ids.WO}
      programAgnostic={true}
      name="Work Order"
      viewUrl="/WorkOrder/WorkOrderMainView"
      scriptBundle={`/Scripts/Bundles/WorkOrderManagement.js`}
      cssBundle={`/Content/Bundles/WorkOrderManagement.css`}
      didMount={useCallback(() => {
        console.log('Work Order init callback');
        applicationApi
          .getUserBackgroundJob(id)
          .then((bkgJob) => {
            console.log(bkgJob);
            console.log(Modules);
            let importType = type;
            if (importType == null) importType = bkgJob.Description;
            if (bkgJob.ResultData) {
              if (importType === 'Work Order - Bulk Import') {
                Modules.WorkOrder.ViewModels.WorkOrderListPod.openBulkImportCsvResultWindow(
                  bkgJob.ResultData,
                  standalone
                );
              } else if (importType === 'Work Order - Bulk Create') {
                Modules.WorkOrder.ViewModels.WorkOrderListPod.openBulkImportCsvResultWindow(
                  bkgJob.ResultData,
                  standalone,
                  'Create'
                );
              } else if (importType === 'Work Order - Bulk Update') {
                Modules.WorkOrder.ViewModels.WorkOrderListPod.openBulkImportCsvResultWindow(
                  bkgJob.ResultData,
                  standalone,
                  'Update'
                );
              }
            } else {
              toastDispatch({
                type: ADD,
                payload: {
                  content: 'Import results not available',
                  type: 'error',
                },
              });
            }
          })
          .catch((error) => {
            console.error(error);
            toastDispatch({
              type: ADD,
              payload: {
                content: 'Error fetching import data',
                type: 'error',
              },
            });
          });
        if (handleClose) handleClose();
      }, [id, handleClose, standalone, toastDispatch, type])}
    ></MonoFrontend>
  );
};

const RouteToImportResults = ({ id, type, handleClose }) => {
  const navigate = useNavigate();
  let route = ImportResultsRoutes[type];

  useEffect(() => {
    if (route) {
      navigate(`${route}/${id}`);
    }
    return () => {
      // Code to run on component unmount
      handleClose();
    };
  }, []);

  return null;
};

const ImportResultsMap = {
  'Project Management - Jobs Import': ProjectManagementImportResults,
  'Project Management - Job Commments Import': ProjectManagementImportResults,
  'Scoping Management - Bulk Import': ScopeManagementImportResults,
  'Scoping Management - GC Firm Update': ScopeManagementImportResults,
  'Client Management - Submit Invoices Bulk Import': ClientInvoiceManagementImportResults,
  'Cost Center Management - Sites Bulk Import': CosCenterManagementImportResults,
  'Cost Center Management - Jobs Bulk Import': CosCenterManagementImportResults,
  'SCM - Item Management Save': ItemManagementImportResults,
  'SCM - Warehosue Management Import Locations': ItemManagementImportResults,
  'Workflow - Work Orders Import': RouteToImportResults,
  'Work Order - Bulk Import': WorkOrderImportResults,
  'Work Order - Bulk Create': WorkOrderImportResults,
  'Work Order - Bulk Update': WorkOrderImportResults,
};

const ImportResultsRoutes = {
  'Project Management - Jobs Import': '/apps/projectManagement/jobsImportResults',
  'Project Management - Job Commments Import':
    '/apps/projectManagement/jobsImportResults',
  'Scoping Management - Bulk Import': 'apps/scopeManagement/ScopeManagementImportResults',
  'Scoping Management - GC Firm Update':
    'apps/scopeManagement/ScopeManagementImportResults',
  'Scoping Management - Firming PO': 'apps/scopeManagement/ScopeManagementImportResults',
  'Client Management - Submit Invoices Bulk Import':
    'apps/clientManagement/submitInvoice/SubmitInvoiceImportResults',
  'Cost Center Management - Sites Bulk Import':
    '/apps/costCenterManagement/costCenterManagementImportResults',
  'Cost Center Management - Jobs Bulk Import':
    '/apps/costCenterManagement/costCenterManagementImportResults',
  'SCM - Item Management Save': '/apps/supplyChain/main/itemManagementImportResult',
  'SCM - Warehosue Management Import Locations':
    '/apps/supplyChain/main/itemManagementImportResult',
  'FSO-Workforce Management - Bulk save resources':
    '/apps/fieldServiceOperations/bulkImportresult',
  'FSO-Workforce Management - Bulk save crews':
    '/apps/fieldServiceOperations/bulkImportresult',
  'Workflow - Work Orders Import': '/apps/workflow/workorder/bulk/importResults',
  'Work Order - Bulk Import': '/apps/workOrder/WorkOrderImportResults',
  'Work Order - Bulk Create': '/apps/workOrder/WorkOrderImportResults',
  'Work Order - Bulk Update': '/apps/workOrder/WorkOrderImportResults',
};

const ImportResultsComponents = (type) => {
  let ImportResults = ImportResultsMap[type];

  if (ImportResults) {
    return ImportResults;
  } else {
    return null;
  }
};

export {
  ProjectManagementImportResults,
  CosCenterManagementImportResults,
  ScopeManagementImportResults,
  WorkOrderImportResults,
  ClientInvoiceManagementImportResults,
  ImportResultsComponents,
  ItemManagementImportResults,
  ImportResultsRoutes,
};
