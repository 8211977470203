import React, { useState } from 'react';
import { AccuvTabs, AccuvTab, AccuvIconButton } from '@accuv/shared-components';
import RequestTabPanel from './RequestTabPanel';
import { Menu, MenuItem, ListItemSecondaryAction } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExportPanelComponent from './ExportPanelComponent';
import ImportPanelComponent from './ImportPanelComponent';
import NotificationFilterNew from 'components/NotificationCenter/NotificationItems/NotificationFilterNew';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    padding: theme.spacing(2, 1.5, 1, 2),
  },
  toolbarIconButton: {
    width: 24,
    height: 24,
    borderRadius: 0,
    position: 'relative',
    marginLeft: 15,
    '&:hover': {
      color: theme.palette.white,
      background: theme.palette.secondary.main,

      '&::before': {
        borderRadius: 0,
        transform: 'scale(1)',
      },
    },
  },
  popper: {
    padding: theme.spacing(3),
    width: '300px',
    maxWidth: '300px',
    backgroundColor: '#fff',
    boxShadow: '0 1px 8px rgba(0,0,0,.3)',
  },
  activeFilter: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
  },
}));

const RequestsNew = () => {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterProgramVal, setFilterProgramVal] = useState([]);
  const [anchorElPopover, setAnchorElPopover] = useState(null);
  const [markAllReadFromParent, setMarkAllReadFromParent] = useState(false);

  const openPopover = Boolean(anchorElPopover);
  const idPopover = openPopover ? 'request-filter-notifications-popover' : undefined;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickPopover = (event) => {
    if (anchorElPopover != null) {
      setAnchorElPopover(null);
    } else {
      setAnchorElPopover(event.currentTarget);
    }
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const handleMoreOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAllNotificationsAsRead = () => {
    handleMoreOptionsClose();
    setMarkAllReadFromParent(true);
  };

  const handleGetFilterValue = (filterValue) => {
    setFilterProgramVal(filterValue);
  };

  const markAllReadCallback = () => {
    setMarkAllReadFromParent(false);
  };

  return (
    <div className={classes.root}>
      <AccuvTabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        className={classes.tabs}
      >
        <AccuvTab label="Exports" />
        <AccuvTab label="Imports" />
        <ListItemSecondaryAction>
          <AccuvIconButton
            edge="end"
            aria-label="filer options"
            onClick={handleClickPopover}
            className={classes.toolbarIconButton}
          >
            <FilterListIcon
              className={filterProgramVal.length > 0 ? classes.activeFilter : ''}
            />
          </AccuvIconButton>
          <AccuvIconButton
            edge="end"
            aria-label="more options"
            onClick={handleMoreOptionsClick}
            className={classes.toolbarIconButton}
          >
            <MoreVertIcon />
          </AccuvIconButton>
        </ListItemSecondaryAction>
      </AccuvTabs>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMoreOptionsClose}
      >
        <MenuItem onClick={handleMarkAllNotificationsAsRead}>Mark all as read</MenuItem>
      </Menu>
      <Popper
        id={idPopover}
        open={openPopover}
        anchorEl={anchorElPopover}
        onClose={handleClosePopover}
        transition
        placement={'bottom-end'}
        className={classes.popper}
      >
        <NotificationFilterNew
          handleClosePopover={handleClosePopover}
          handleGetFilterValue={handleGetFilterValue}
          filterProgramVal={filterProgramVal}
        />
      </Popper>
      <RequestTabPanel value={value} index={0}>
        <ExportPanelComponent
          filterProgramVal={filterProgramVal}
          markAllReadFromParent={markAllReadFromParent}
          markAllReadCallback={markAllReadCallback}
        />
      </RequestTabPanel>
      <RequestTabPanel value={value} index={1}>
        <ImportPanelComponent
          filterProgramVal={filterProgramVal}
          markAllReadFromParent={markAllReadFromParent}
          markAllReadCallback={markAllReadCallback}
        />
      </RequestTabPanel>
    </div>
  );
};

export default RequestsNew;
