import React from 'react';

import { AuthConsumer } from 'providers/AuthProvider';

export const Callback = () => {
  return (
    <AuthConsumer>
      {({ signinRedirectCallback }) => {
        signinRedirectCallback();
        return (
          <div className="loader-container">
            <span className="loader-spinner"></span>
            <span className="loader-text">Loading ...</span>
          </div>
        );
      }}
    </AuthConsumer>
  );
};
