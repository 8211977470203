import React, { memo } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import SidebarItem from './SidebarItem';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { drawerWidth, topBarHeight } from '../../styleVariables';
import { makeStyles } from '@material-ui/core/styles';
import { getAllRoutes } from 'routes/Routes';

// eslint-disable-next-line no-undef
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    maxWidth: drawerWidth,
    height: 'auto',
    maxHeight: '100%',
    zIndex: theme.zIndex.drawer + 99,
    ...theme.mixins.scrollbar,
  },
  modal: {
    top: `${topBarHeight}px !important`,
    zIndex: '1000 !important',
  },
  backdrop: {
    top: topBarHeight,
  },
}));

const Sidebar = ({ mainMenuOpened, toggleMainMenu }) => {
  console.log('Sidebar rendering...');

  const classes = useStyles();
  const routes = getAllRoutes();

  const menu = (
    <List component="div">
      {routes.items.map((route, index) => {
        return (
          <SidebarItem
            key={index}
            index={index}
            route={route}
            toggleMainMenu={toggleMainMenu}
          />
        );
      })}
    </List>
  );

  return (
    <>
      <Hidden smDown>
        <Drawer
          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={mainMenuOpened}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop,
            },
            onBackdropClick: toggleMainMenu,
          }}
        >
          {menu}
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawer
          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={mainMenuOpened}
          onClose={toggleMainMenu}
          onOpen={toggleMainMenu}
          disableBackdropTransition={!iOS}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop,
            },
            onBackdropClick: toggleMainMenu,
          }}
        >
          {menu}
        </SwipeableDrawer>
      </Hidden>
    </>
  );
};

Sidebar.propTypes = {
  routes: PropTypes.array,
  mainMenuOpened: PropTypes.bool,
  toggleMainMenu: PropTypes.func,
};

export default memo(Sidebar);
