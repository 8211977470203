import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AccuvTypography, AccuvButton } from '@accuv/shared-components';
import logo from 'assets/error-pages/accuv-error-logo.svg';
import image from 'assets/error-pages/accuv-error-image.svg';
import { AuthContext } from 'providers/AuthProvider';
import { AppProvider } from 'providers/AppProvider';

const useStyles = makeStyles(() => ({
  frame: {
    minHeight: '100vh',
    width: '100vw',
    position: 'relative',
    boxSizing: 'border-box',
    padding: '5.729vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  accuvErrorLogo: {
    position: 'absolute',
    top: 0,
    right: 0,
    maxWidth: '70vw',
    width: 'calc(160px + 15vw)',
  },
  accuvErrorImage: {
    maxWidth: '80%',
    width: '33%',
    marginTop: '4vw',
    marginBottom: 20,
  },
  errorMessageTitle: {
    margin: 0,
    fontSize: 'calc(48px + 2vw)',
    lineHeight: 1,
    color: '#250E62',
    fontWeight: 300,

    '& strong': {
      fontWeight: 700,
    },
  },
  errorMessageSubtitle: {
    margin: '20px 0 0',
    fontSize: 'calc(30px + 1vw)',
    lineHeight: 1,
    color: '#5839A0',
    fontWeight: 700,
  },
  errorMessageCta: {
    margin: '80px 0 0',
    fontSize: 22,
    color: '#250E62',
  },
  decoration: {
    width: 300,
    maxWidth: '100%',
    height: 2,
    display: 'block',
    background: '#250E62',
    marginBBottom: 30,
  },
}));

const SessionExpired = () => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);

  const handleRedirectToSignin = () => {
    authContext.signinRedirect();
  };

  return (
    <AppProvider>
      <div className={classes.frame}>
        <img className={classes.accuvErrorLogo} src={logo} alt="accuv-logo" />
        <div className={classes.accuvErrorImageWrapper}>
          <img className={classes.accuvErrorImage} src={image} alt="error" />
        </div>
        <div className={classes.accuvErrorCopyWrapper}>
          <AccuvTypography className={classes.errorMessageTitle}>
            <strong>401</strong> Session Expired
          </AccuvTypography>
          <AccuvTypography className={classes.errorMessageSubtitle}>
            Your session has expired. Please login again.
          </AccuvTypography>
          <AccuvTypography className={classes.errorMessageCta}>
            <span className={classes.decoration}></span>
            <AccuvButton onClick={handleRedirectToSignin}>Go to Login page</AccuvButton>
          </AccuvTypography>
        </div>
      </div>
    </AppProvider>
  );
};

export default SessionExpired;
