import React, { useState } from 'react';
import clsx from 'clsx';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ListItemSecondaryAction, Menu, MenuItem } from '@material-ui/core';

import {
  AccuvListItem,
  AccuvListItemText,
  AccuvTypography,
  AccuvIconButton,
} from '@accuv/shared-components';

import { useToast, ADD } from 'providers/ToastProvider';
import ApplicationApi from 'services/Application/ApplicationApi';

import BackgroundJobStates from 'constants/BackgroundJobStates';

const useStyles = makeStyles((theme) => ({
  listItem: {
    background: '#F0F1F8',
    paddingRight: theme.spacing(10),
    borderBottom: `1px solid ${theme.palette.gray.border}`,
  },
  lastListItem: {
    borderBottom: 'none',
  },
  listItemText: {
    '& .mainspa-MuiListItemText-primary': {
      fontSize: 12,
      color: theme.palette.gray.text,
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .mainspa-MuiListItemText-secondary': {
      fontSize: 11,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  listItemRead: {
    background: theme.palette.white,
    '& .mainspa-MuiListItemText-primary': {
      fontWeight: 'normal',
    },
  },
  statusText: {
    fontSize: 11,
    minWidth: 50,
    padding: theme.spacing(0, 1),
    color: theme.palette.gray.text,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  statusTextRead: {
    fontWeight: 'normal',
  },
  runningColor: {
    color: theme.palette.secondary.main,
  },
  errorColor: {
    color: theme.palette.danger.main,
  },
  retryButton: {
    padding: theme.spacing(0.25, 0.5),
    minWidth: 0,
    fontSize: 14,
  },
  retryIcon: {
    fontSize: 16,
    marginRight: 2,
  },
}));

const applicationApi = new ApplicationApi();

const ExportRequestNew = ({
  index,
  onClick,
  exportItem,
  exportsLength,
  markAsReadCallback,
  markAsUnreadCallback,
}) => {
  const classes = useStyles();

  const { toastDispatch } = useToast();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMoreOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadExportedFile = () => {
    handleMoreOptionsClose();
    applicationApi
      .downloadExportedFile(exportItem.id)
      .then((file) => {
        let openIn = '_self';
        if (file.Url.toLowerCase().endsWith('.pdf')) openIn = '_blank';
        window.open(file.Url, openIn);
        return false;
      })
      .catch(() => {
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error fetching exported file data',
            type: 'error',
          },
        });
      });
  };

  const handleMarkNotificationAsRead = (read) => {
    handleMoreOptionsClose();
    if (read) {
      markAsUnreadCallback();
    } else {
      markAsReadCallback();
    }
  };

  const handleErrorDetails = () => {
    handleMoreOptionsClose();
    toastDispatch({
      type: ADD,
      payload: {
        content: {
          title: `Error on ${exportItem.title}`,
          description: exportItem.payload.error,
        },
        type: 'alert',
      },
    });
  };

  let subtitle = (
    <>
      <Moment format="lll">{exportItem.dateTime}</Moment>
      {exportItem.payload.programName
        ? ` - ${exportItem.payload.programName}`
        : exportItem.payload.program
        ? ` - ${exportItem.payload.program}`
        : null}
    </>
  );

  return (
    <>
      <AccuvListItem
        button
        key={index}
        className={clsx(
          classes.listItem,
          exportItem.read && classes.listItemRead,
          index === exportsLength - 1 && classes.lastListItem
        )}
        onClick={() => {
          onClick();
          if (exportItem.payload.status.toLowerCase() === BackgroundJobStates.COMPLETED) {
            handleDownloadExportedFile();
          } else if (
            exportItem.payload.status.toLowerCase() === BackgroundJobStates.FAILED
          ) {
            handleErrorDetails();
          }
        }}
      >
        <AccuvListItemText
          className={classes.listItemText}
          primary={exportItem.title}
          secondary={subtitle}
        />
        <AccuvTypography
          className={clsx(
            classes.statusText,
            exportItem.payload.status.toLowerCase() === BackgroundJobStates.RUNNING &&
              classes.runningColor,
            exportItem.payload.status.toLowerCase() === BackgroundJobStates.FAILED &&
              classes.errorColor,
            exportItem.read === true && classes.statusTextRead
          )}
        >
          {exportItem.payload.status}
        </AccuvTypography>
        <ListItemSecondaryAction>
          {/* <AccuvButton
              variant="outlined"
              color="secondary"
              className={classes.retryButton}
            >
              <ReplayIcon className={classes.retryIcon} /> Retry
            </AccuvButton> */}
          {exportItem.payload.status.toLowerCase() !== BackgroundJobStates.RUNNING ? (
            <AccuvIconButton
              edge="end"
              aria-label="more options"
              onClick={handleMoreOptionsClick}
            >
              <MoreVertIcon />
            </AccuvIconButton>
          ) : null}
        </ListItemSecondaryAction>
      </AccuvListItem>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMoreOptionsClose}
      >
        {exportItem.payload.status.toLowerCase() === BackgroundJobStates.FAILED && (
          <MenuItem onClick={handleErrorDetails}>Show error details</MenuItem>
        )}
        {exportItem.payload.status.toLowerCase() === BackgroundJobStates.COMPLETED && (
          <MenuItem onClick={handleDownloadExportedFile}>Download</MenuItem>
        )}
        {exportItem.read && (
          <MenuItem onClick={() => handleMarkNotificationAsRead(true)}>
            Mark as unread
          </MenuItem>
        )}
        {!exportItem.read && (
          <MenuItem onClick={() => handleMarkNotificationAsRead(false)}>
            Mark as read
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

ExportRequestNew.propTypes = {
  exportItem: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    dateTime: PropTypes.string,
    read: PropTypes.bool,
    payload: PropTypes.object,
  }),
  onClick: PropTypes.func,
  index: PropTypes.number,
  exportsLength: PropTypes.number,
  markAsUnreadCallback: PropTypes.func,
  markAsReadCallback: PropTypes.func,
};

export default ExportRequestNew;
