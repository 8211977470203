import React from 'react';
import PropTypes from 'prop-types';
import { AccuvLoadingIcon, AccuvStatusMessageBox } from '@accuv/shared-components';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { AppContext } from 'providers/AppProvider';
import { Unauthorized } from 'pages';
import { hasAccessToModule } from 'services/Authorization/UserAccess';

const styles = createStyles(() => ({
  loading: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  error: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

class MicroFrontend extends React.Component {
  static contextType = AppContext;
  state = this.context;

  constructor(props) {
    super(props);
    document.title = 'AccuV | ' + props.name;
    this.chunkCount = -1;
    this.unmounted = false;
    this.state = {
      status: 'loading',
      permissions: null,
    };
  }

  componentDidMount() {
    const { microId, host } = this.props;
    const scriptId = `micro-frontend-script-${microId}`;
    const styleClass = `micro-frontend-style-${microId}`;

    // We need to access to the permissions from the AppContext
    const [state] = this.context;
    this.setState({
      ...this.state,
      permissions: state.permissions,
    });

    const existingScript = document.getElementById(scriptId);
    //** please all code splitting css must use extention means: */
    //** a class name .container in a module call FSO in a component call Header */
    //** the class must names: .fso-header-container to prevent the override */
    if (this.props.loadType === 'not-optimized') {
      // This is a regular React build but in one file (without code splitting)
      fetch(`${host}/asset-manifest.json`, { cache: 'no-store' })
        .then((res) => res.json())
        .then((manifest) => {
          const mainCSS = manifest.files['main.css'];

          if (mainCSS) {
            // Create new link Element
            const link = document.createElement('link');

            // set the attributes for link element
            link.classList.add(styleClass);
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.crossOrigin = '';
            link.href = mainCSS.startsWith(host) ? mainCSS : `${host}${mainCSS}`;

            // Append link element to HTML head
            document.head.appendChild(link);
          }

          if (!existingScript) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.crossOrigin = '';
            const mainJS = manifest.files['main.js'];
            script.src = mainJS.startsWith(host) ? mainJS : `${host}${mainJS}`;
            script.onload = this.renderMicroFrontend;
            document.head.appendChild(script);
          } else {
            this.renderMicroFrontend();
          }
        })
        .catch((error) => {
          console.error(`Error loading micro-frontend ${microId} - ${error.message}`);
          this.setState({
            ...this.state,
            status: 'error',
          });
        });
    } else if (this.props.loadType === 'optimized') {
      // This is a regular React build using code splitting
      fetch(`${host}/asset-manifest.json`, { cache: 'no-store' })
        .then((res) => res.json())
        .then((manifest) => {
          this.chunkCount = -1;
          const manifestData = Object.keys(manifest['files']).filter((key) =>
            existingScript
              ? key === 'main.css'
              : (key.endsWith('.js') && !key.startsWith('static/js/')) ||
                (key.endsWith('.css') && !key.startsWith('static/css/'))
          );
          const manifestDataLength = manifestData.length;
          if (manifestDataLength === 0) {
            this.renderMicroFrontend();
          } else {
            this.chunkCount = manifestDataLength;
            manifestData.forEach((key) => {
              const fileValue = manifest['files'][key];
              if (key.endsWith('.js')) {
                const urlPath = fileValue.startsWith(host)
                  ? fileValue
                  : `${host}${fileValue}`;
                const script = document.createElement('script');
                if (key === 'main.js') {
                  script.id = scriptId;
                }
                script.crossOrigin = '';
                script.src = urlPath;
                script.onload = this.renderMicroFrontendOptimized;
                document.head.appendChild(script);
              } else {
                // Create new link Element
                const link = document.createElement('link');

                // set the attributes for link element
                //if (key === 'main.css') {
                link.classList.add(styleClass);
                //}

                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.crossOrigin = '';
                link.href = fileValue.startsWith(host)
                  ? fileValue
                  : `${host}${fileValue}`;

                // Append link element to HTML head
                document.head.appendChild(link);

                this.chunkCount--;

                if (this.chunkCount === 0) {
                  this.renderMicroFrontend();
                }
              }
            });
          }
        })
        .catch((error) => {
          console.error(`Error loading micro-frontend ${microId} - ${error.message}`);
          this.setState({ ...this.state, status: 'error' });
        });
    } else if (this.props.loadType === 'bundle') {
      // This is just a bundle file (no manifest)
      const script = document.createElement('script');

      script.id = scriptId;
      script.src = host;
      script.crossOrigin = '';
      script.onload = this.renderMicroFrontend;

      document.body.appendChild(script);
    } else {
      console.error(
        `Error loading micro-frontend ${microId} - You must define a loadType.`
      );
      this.setState({
        ...this.state,
        status: 'error',
      });
    }
  }

  componentDidUpdate() {
    const [state] = this.context;
    if (!this.state.permissions) {
      this.setState({
        ...this.state,
        permissions: state.permissions,
      });
    }

    if (this.state.status === 'done') {
      const { microId, history, basePath } = this.props;

      if (this.props.buildMode === 'regular') {
        window[`render${microId}`](`${microId}-container`, history, {
          basePath: basePath,
        });
      } else {
        window[microId].render(`${microId}-container`, history, { basePath: basePath });
      }
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
    const { microId } = this.props;

    // const scriptId = `micro-frontend-script-${microId}`;
    const styleClass = `micro-frontend-style-${microId}`;

    // const existingScript = document.getElementById(scriptId);

    // if (existingScript && this.props.loadType === 'optimized') {
    //   existingScript.removeEventListener('load', this.renderMicroFrontend);
    // }

    //* remove all css file (file has styleClass 'till now main.css') related to the micro frontend will unmounted
    const listLinks = document.getElementsByClassName(styleClass);
    for (let i = listLinks.length - 1; i >= 0; i--) {
      document.head.removeChild(listLinks[i]);
    }

    if (this.props.buildMode === 'regular') {
      if (window[`unmount${microId}`]) {
        window[`unmount${microId}`](`${microId}-container`);
      }
    } else {
      if (window[microId]) {
        window[microId].unMount(`${microId}-container`);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Only update if status change, this means the scripts or permissions have been loaded
    return (
      nextState.status !== this.state.status ||
      nextState.permissions !== this.state.permissions
    );
  }

  renderMicroFrontendOptimized = () => {
    if (!this.unmounted) {
      this.chunkCount--;
      if (this.chunkCount === 0) {
        this.renderMicroFrontend();
      }
    }
  };

  renderMicroFrontend = () => {
    if (!this.unmounted) {
      this.setState({ ...this.state, status: 'done' });
    }
  };

  render() {
    const { classes } = this.props;
    let microModule;
    if (this.state.status === 'loading' || !this.state.permissions) {
      microModule = (
        <div className={classes.loading}>
          <AccuvLoadingIcon></AccuvLoadingIcon>
        </div>
      );
    } else if (this.state.status === 'done' && this.state.permissions) {
      if (!hasAccessToModule(this.props.id, this.state.permissions)) {
        microModule = <Unauthorized></Unauthorized>;
      } else {
        microModule = <main id={`${this.props.microId}-container`} />;
      }
    } else if (this.state.status === 'error') {
      microModule = (
        <div className={classes.error}>
          <AccuvStatusMessageBox>
            An error occurred loading the module, please refresh the page.
          </AccuvStatusMessageBox>
        </div>
      );
    } else {
      microModule = (
        <div className={classes.error}>
          <AccuvStatusMessageBox>
            An unexpected error occurred, please try refreshing the page.
          </AccuvStatusMessageBox>
        </div>
      );
    }

    return microModule;
  }
}

MicroFrontend.defaultProps = {
  document,
  window,
  mode: 'library',
};

MicroFrontend.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  host: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  microId: PropTypes.string.isRequired,
  document: PropTypes.object,
  loadType: PropTypes.oneOf(['optimized', 'not-optimized', 'bundle']).isRequired,
  buildMode: PropTypes.oneOf(['library', 'regular']),
  window: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.any,
};

export default withStyles(styles)(MicroFrontend);
