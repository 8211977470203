/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-case-declarations */
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import ProgramService from 'services/Program/ProgramService';
import * as ActionTypes from './ActionTypes';
import NotificationService from 'services/Notification/NotificationService';
import NotificationTypes from 'constants/NotificationTypes';

// Default AccuV theme provider
import { AccuvThemeProvider, AccuvTheme } from '@accuv/shared-components';

// Context API
const AppContext = createContext();
const { Provider } = AppContext;

// Reducer for the app state
const appReducer = (state, action) => {
  let newState;
  switch (action.type) {
    case ActionTypes.TOGGLE_THEME_TYPE: // currently not used
      return { ...state, type: state.type === 'light' ? 'dark' : 'light' };

    case ActionTypes.TOGGLE_THEME_DIRECTION: // currently not used
      newState = {
        ...state,
      };
      newState.direction = state.direction === 'ltr' ? 'rtl' : 'ltr';
      return newState;

    case ActionTypes.TOGGLE_MAIN_MENU:
      newState = {
        ...state,
      };
      if (action.state !== undefined) {
        newState.mainMenuOpened = action.state;
      } else {
        newState.mainMenuOpened = !state.mainMenuOpened;
      }
      return newState;

    case ActionTypes.TOGGLE_USER_PANEL:
      return {
        ...state,
        userPanelOpened: !state.userPanelOpened,
        helpPanelOpened: false,
        notificationPanelOpened: false,
        programSelectorOpened: false,
      };

    case ActionTypes.TOGGLE_HELP_PANEL:
      return {
        ...state,
        helpPanelOpened: !state.helpPanelOpened,
        userPanelOpened: false,
        notificationPanelOpened: false,
        programSelectorOpened: false,
      };

    case ActionTypes.TOGGLE_NOTIFICATION_PANEL:
      return {
        ...state,
        notificationPanelOpened: !state.notificationPanelOpened,
        userPanelOpened: false,
        helpPanelOpened: false,
        programSelectorOpened: false,
      };

    case ActionTypes.TOGGLE_PROGRAM_SELECTOR:
      return {
        ...state,
        programSelectorOpened: !state.programSelectorOpened,
        notificationPanelOpened: false,
        userPanelOpened: false,
        helpPanelOpened: false,
      };

    case ActionTypes.TOGGLE_MONOLITHIC_MODULE:
      return {
        ...state,
        monolithicModule: {
          id: action.state.id,
          opened: action.state.opened,
        },
      };

    case ActionTypes.SELECT_PROGRAM:
      ProgramService.setSelectedProgram(action.state);
      return { ...state, program: action.state };

    case ActionTypes.PERMISSIONS_LOADED:
      return { ...state, permissions: action.state };

    case ActionTypes.GROUPS_LOADED:
      return { ...state, groups: action.state };

    case ActionTypes.DASHBOARDS_LOADED:
      return { ...state, dashboards: action.state };

    case ActionTypes.NOTIFICATIONS_REFRESHED:
      return { ...state, notifications: action.state, refreshNotifications: false };

    case ActionTypes.REFRESH_NOTIFICATIONS:
      //!below action changed to be able to refresh each block independently
      // return { ...state, refreshNotifications: true };
      return {
        ...state,
        refreshTodo: true,
        refreshRequest: true,
        refreshAnnouncement: true,
        refreshNotificationPane: true,
      };

    case ActionTypes.PROFILE_PICTURE_CHANGED:
      return {
        ...state,
        userProfilePicture: action.state,
      };

    case ActionTypes.PROFILE_PICTURE_REMOVED:
      return {
        ...state,
        userProfilePicture: '',
      };

    case ActionTypes.SELECT_GROUP:
      return { ...state, group: action.state };

    case ActionTypes.TODO_NOTIFICATIONS_FILTER_CHANGED:
      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          todo: { programs: action.state },
        },
      };

    case ActionTypes.REQUESTS_NOTIFICATIONS_FILTER_CHANGED:
      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          requests: { programs: action.state },
        },
      };

    case ActionTypes.CLEAR_TODO_NOTIFICATIONS_FILTER:
      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          todo: { programs: [] },
        },
        filteredTodoNotifications: null,
      };

    case ActionTypes.CLEAR_REQUESTS_NOTIFICATIONS_FILTER:
      return {
        ...state,
        notificationsFilter: {
          ...state.notificationsFilter,
          requests: { programs: [] },
        },
        filteredRequestsNotifications: null,
      };

    case ActionTypes.APPLY_NOTIFICATIONS_FILTER:
      if (action.state == NotificationTypes.TODO) {
        return {
          ...state,
          filteredTodoNotifications: NotificationService.getFilteredNotifications(
            state.notifications,
            state.notificationsFilter.todo,
            action.state
          ),
        };
      } else if (action.state == NotificationTypes.REQUESTS) {
        return {
          ...state,
          filteredRequestsNotifications: NotificationService.getFilteredNotifications(
            state.notifications,
            state.notificationsFilter.requests,
            action.state
          ),
        };
      } else {
        return {
          ...state,
        };
      }
    //new actions added for notifications pagination
    case ActionTypes.REFRESH_TODO_COMPLETED:
      return { ...state, refreshTodo: false };

    case ActionTypes.REFRESH_REQUEST_COMPLETED:
      return { ...state, refreshRequest: false };

    case ActionTypes.REFRESH_ANNOUNCEMENT_COMPLETED:
      return { ...state, refreshAnnouncement: false };

    case ActionTypes.REFRESH_NOTIFICATION_PANE_COMPLETED:
      return { ...state, refreshNotificationPane: false };

    case ActionTypes.SET_UNREAD_COUNT:
      return { ...state, unreadCount: action.payload };

    case ActionTypes.UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notificationsToUpdate: action.payload,
      };

    case ActionTypes.UPDATE_TODO_COMPLETED:
    case ActionTypes.UPDATE_REQUEST_COMPLETED:
    case ActionTypes.UPDATE_NOT_PANE_COMPLETED:
    case ActionTypes.UPDATE_ANNOUNCEMENT_COMPLETED: {
      //update bloack status based on the source
      if (action.type === ActionTypes.UPDATE_TODO_COMPLETED) {
        state.blocksUpdate.todoBlock = true;
      } else if (action.type === ActionTypes.UPDATE_REQUEST_COMPLETED) {
        state.blocksUpdate.requestBlock = true;
      } else if (action.type === ActionTypes.UPDATE_ANNOUNCEMENT_COMPLETED) {
        state.blocksUpdate.announcementBlock = true;
      } else if (action.type === ActionTypes.UPDATE_NOT_PANE_COMPLETED) {
        state.blocksUpdate.notificationPaneBlock = true;
      }

      let allUpdatesCompleted = true;

      for (const item in state.blocksUpdate) {
        if (state.blocksUpdate[item] === false) {
          allUpdatesCompleted = false;
          break;
        }
      }

      return {
        ...state,
        notificationsToUpdate: allUpdatesCompleted ? [] : state.notificationsToUpdate,
        blocksUpdate: allUpdatesCompleted
          ? {
              todoBlock: false,
              requestBlock: false,
              announcementBlock: false,
              notificationPaneBlock: false,
            }
          : state.blocksUpdate,
      };
    }

    default:
      return state;
  }
};

// CSS class name generation, we are using this in order to not collapse names with the micro-frontends
const generateClassName = createGenerateClassName({
  disableGlobal: false,
  productionPrefix: 'mainspa',
  seed: 'mainspa',
});

const AppProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery('@media (prefers-color-scheme: dark)');

  const initialState = {
    themeType: prefersDarkMode ? 'dark' : 'light',
    direction: 'ltr',
    mainMenuOpened: false,
    userPanelOpened: false,
    helpPanelOpened: false,
    notificationPanelOpened: false,
    programSelectorOpened: false,
    monolithicModule: {
      id: null,
      opened: false,
    },
    program: {
      id: ProgramService.getSelectedProgramId(),
      name: ProgramService.getSelectedProgramName(),
    },
    permissions: null,
    groups: null,
    dashboards: null,
    notifications: null,
    //!by default state is false (we have a refresh state for each block now)
    refreshNotifications: false, //OLD: true,
    userProfilePicture: null,
    group: null,
    notificationsFilter: { todo: { programs: [] }, requests: { programs: [] } },
    filteredTodoNotifications: null,
    filteredRequestsNotifications: null,
    //new states added for notification pagination
    refreshTodo: false,
    refreshRequest: false,
    refreshAnnouncement: false,
    refreshNotificationPane: false,
    unreadCount: 0,
    notificationsToUpdate: [],
    blocksUpdate: {
      todoBlock: false,
      requestBlock: false,
      announcementBlock: false,
      notificationPaneBlock: false,
    },
  };

  const [state, dispatch] = useReducer(appReducer, initialState);

  const theme = AccuvTheme;
  theme.direction = state.direction;
  theme.palette.type = state.type;

  useEffect(() => {
    document.body.dir = state.direction;
  }, [state.direction]);

  return (
    <AccuvThemeProvider>
      <StylesProvider generateClassName={generateClassName}>
        <Provider value={[state, dispatch]}>{children}</Provider>
      </StylesProvider>
    </AccuvThemeProvider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppProvider, AppContext };
export const useAppState = () => useContext(AppContext);
