import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { AccuvStatusMessageBox } from '@accuv/shared-components';

const styles = createStyles(() => ({
  error: {
    marginTop: '50%',
    marginBottom: '50%',
  },
  errorText: {
    padding: '0',
  },
}));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    //this.state = { error: null, errorInfo: null };
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error, errorInfo) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error, errorInfo: errorInfo };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.error(errorInfo);
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      // Error path
      return (
        <div className={classes.error}>
          <AccuvStatusMessageBox className={classes.errorText}>
            Sorry, something went wrong.
          </AccuvStatusMessageBox>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.any,
};

export default withStyles(styles)(ErrorBoundary);
