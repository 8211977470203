import env from '@beam-australia/react-env';

export const IDENTITY_CONFIG = {
  authority: env('IDENTITY_AUTH_ENDPOINT'), //(string): The URL of the OIDC provider.
  client_id: env('IDENTITY_CLIENT_ID'), //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: `${env('IDENTITY_CLIENT_BASE_ENDPOINT')}/signin-oidc`, //The URI of your client application to receive a response from the OIDC provider.
  automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silent_redirect_uri: `${env('IDENTITY_CLIENT_BASE_ENDPOINT')}/silentrenew`, //(string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri: `${env('IDENTITY_CLIENT_BASE_ENDPOINT')}/logout/callback`, // (string): The OIDC post-logout redirect URI.
  response_type: 'code', //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  scope: env('IDENTITY_SCOPES'), //(string, default: 'openid'): The scope being requested from the OIDC provider.
  filterProtocolClaims: true,
  revokeAccessTokenOnSignout: true,
  prompt: 'login',
  //audience: env('IDENTITY_AUTH_ENDPOINT'),
  //response_mode: 'query',
};

// meta data configuration
// export const METADATA_OIDC = {
//   issuer: process.env.REACT_APP_AUTH_URL,
//   jwks_uri:
//     process.env.REACT_APP_AUTH_URL + '/.well-known/openid-configuration/jwks',
//   authorization_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/authorize',
//   token_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/token',
//   userinfo_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/userinfo',
//   end_session_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/endsession',
//   check_session_iframe:
//     process.env.REACT_APP_AUTH_URL + '/connect/checksession',
//   revocation_endpoint: process.env.REACT_APP_AUTH_URL + '/connect/revocation',
//   introspection_endpoint:
//     process.env.REACT_APP_AUTH_URL + '/connect/introspect',
// };
