/* eslint-disable no-undef */
import React, { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { useAppState } from 'providers/AppProvider/AppProvider';
import { toggleMonolithicModule } from 'providers/AppProvider/ActionCreators';
import ProgramService from 'services/Program/ProgramService';
import { ProgramSelectorFullPage } from 'components';
import { Unauthorized } from 'pages';
import { AccuvLoadingIcon, AccuvStatusMessageBox } from '@accuv/shared-components';
import { makeStyles } from '@material-ui/core/styles';
import loadjs from 'loadjs';
import './mono-container';
import env from '@beam-australia/react-env';
import {
  hasAccessToModule,
  hasAccessToModuleWithoutProgram,
} from 'services/Authorization/UserAccess';

const mvcBasePath = env('MONOLITHIC_BASE_ENDPOINT');

const bundles = {
  'global.js': [
    `${mvcBasePath}/bundles/scripts`,
    `${mvcBasePath}/bundles/ko`,
    `${mvcBasePath}/bundles/Pod`,
    `${mvcBasePath}/bundles/FileManager`,
    `//amp.azure.net/libs/amp/2.3.6/azuremediaplayer.min.js`,
    `${mvcBasePath}/bundles/MediaPlayer`,
    `${mvcBasePath}/bundles/ModulesSharedScripts`,
  ],
  'global.css': [
    `css!${mvcBasePath}/Content/css`,
    `css!${mvcBasePath}/Content/kendo/css/bootstrap`,
    `css!${mvcBasePath}/Content/Pod`,
    `css!${mvcBasePath}/Content/libs/font-awesome/css/font-awesome.min.css`,
    `css!//amp.azure.net/libs/amp/2.3.6/skins/amp-default/azuremediaplayer.min.css`,
  ],
};

export const useStyles = makeStyles(() => ({
  loading: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  error: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const MonoFrontend = ({
  id,
  name,
  viewUrl,
  scriptBundle,
  cssBundle,
  didMount,
  willUnmount,
  programAgnostic,
  loadMessage,
}) => {
  console.log('MonoFrontend rendering...');
  document.title = 'AccuV | ' + name;
  const mvcBasePath = env('MONOLITHIC_BASE_ENDPOINT');
  const classes = useStyles();
  const selectedProgram = ProgramService.getSelectedProgramId();
  const monoEl = useRef();
  const [monoState, setMonoState] = useState({
    status: 'start',
    program: selectedProgram,
    scriptBundleWVersion: scriptBundle,
    cssBundleWVersion: cssBundle,
  });
  if (monoState.program !== selectedProgram) {
    setMonoState({
      ...monoState,
      status: 'start',
      program: selectedProgram,
    });
  }
  const [state, dispatch] = useAppState();

  //componentDidUpdate
  useEffect(() => {
    const element = monoEl.current;
    if (element) {
      element.addEventListener('loaded', didMount);
    }
    if (monoState.status === 'done' && monoState.program && programAgnostic === false) {
      if (state.monolithicModule.opened === false) {
        //We are notifying the layout that a mono module was opened mainly to show the program selector in the header
        dispatch(toggleMonolithicModule(id, true));
      }
    }
  });

  //componentDidMount
  // useEffect(() => {}, []);

  //componentWillUnmount
  useEffect(() => {
    const element = monoEl.current;
    // do any cleanup after module is unmount
    return function cleanup() {
      if (willUnmount) willUnmount();
      if (element) {
        element.removeEventListener('loaded', didMount);
      }
      dispatch(toggleMonolithicModule(id, false));
    };
  }, [dispatch, didMount, willUnmount, id]);
  //Check permission to the module
  if (
    state.permissions &&
    !hasAccessToModule(id, state.permissions, monoState.program) &&
    monoState.status !== 'unauthorized'
  ) {
    if (hasAccessToModuleWithoutProgram(id, state.permissions)) {
      let modulePrograms = state.permissions[id];
      return (
        <ProgramSelectorFullPage programs={modulePrograms}></ProgramSelectorFullPage>
      );
    } else {
      setMonoState({
        ...monoState,
        status: 'unauthorized',
      });
    }
  }
  if (state.permissions && !monoState.program && programAgnostic === false) {
    let modulePrograms = state.permissions[id];
    return <ProgramSelectorFullPage programs={modulePrograms}></ProgramSelectorFullPage>;
  }

  const loadBundles = () => {
    fetch(`${mvcBasePath}/version.manifest`, { cache: 'no-store' })
      .then((res) => {
        if (res.status == 404) return null;
        return res.text();
      })
      .then((manifest) => {
        if (manifest == null) {
          setMonoState((prevState) => {
            return {
              ...prevState,
              status: 'done',
            };
          });
        } else {
          let version = manifest.substring(9);
          let scriptPath =
            scriptBundle.substring(0, scriptBundle.indexOf('.') + 1) +
            version +
            scriptBundle.substring(scriptBundle.indexOf('.'));
          let cssPath =
            cssBundle.substring(0, cssBundle.indexOf('.') + 1) +
            version +
            cssBundle.substring(cssBundle.indexOf('.'));
          setMonoState((prevState) => {
            return {
              ...prevState,
              scriptBundleWVersion: scriptPath,
              cssBundleWVersion: cssPath,
              status: 'done',
            };
          });
        }
      })
      .catch((error) => {
        console.error(`Error loading mono-frontend ${id} - ${error.message}`);
        setMonoState({
          ...monoState,
          status: 'error',
        });
      });
  };

  const requireAssets = (bundleIds, successCallback, errorCallback) => {
    bundleIds.forEach(function (bundleId) {
      if (!loadjs.isDefined(bundleId)) {
        loadjs(bundles[bundleId], bundleId, {
          async: false,
          error: function (pathsNotFound) {
            console.log(`MonoLoader - Paths not found: ${pathsNotFound}`);
            errorCallback();
          },
        });
      } else {
        console.log(
          `MonoLoader - Global bundle ${bundleId} already defined, not need to reload!`
        );
      }
    });
    loadjs.ready(bundleIds, successCallback);
  };

  const loadGlobalScripts = () => {
    requireAssets(
      ['global.js', 'global.css'],
      function () {
        console.log(`MonoLoader: Global bundles loaded!`);
        loadBundles();
      },
      function () {
        console.error('MonoLoader: Error loading global bundles.');
        loadjs.reset();
        setMonoState({
          ...monoState,
          status: 'error',
        });
      }
    );
  };

  if (monoState.status === 'start') {
    setMonoState({
      ...monoState,
      status: 'loading',
    });
    setTimeout(function () {
      loadGlobalScripts();
    }, 0);
  }
  let monolithicModule;
  if (monoState.status === 'loading' || !state.permissions) {
    monolithicModule = (
      <div className={classes.loading}>
        <AccuvLoadingIcon></AccuvLoadingIcon>
      </div>
    );
  } else if (monoState.status === 'done') {
    console.log(
      `MonoFrontend Load Bundles: ${monoState.scriptBundleWVersion}, ${monoState.cssBundleWVersion}`
    );
    monolithicModule = (
      <mono-container
        id={id}
        name={name}
        viewUrl={`${mvcBasePath}${viewUrl}`}
        scriptBundle={`${mvcBasePath}${monoState.scriptBundleWVersion}`}
        cssBundle={`css!${mvcBasePath}${monoState.cssBundleWVersion}`}
        ref={monoEl}
        loadMessage={loadMessage}
      ></mono-container>
    );
  } else if (monoState.status === 'unauthorized') {
    monolithicModule = <Unauthorized></Unauthorized>;
  } else if (monoState.status === 'error') {
    monolithicModule = (
      <div className={classes.error}>
        <AccuvStatusMessageBox>
          An error occurred loading the module, please refresh the page.
        </AccuvStatusMessageBox>
      </div>
    );
  } else {
    monolithicModule = (
      <div className={classes.error}>
        <AccuvStatusMessageBox>
          An unexpected error occurred, please try refreshing the page.
        </AccuvStatusMessageBox>
      </div>
    );
  }

  return monolithicModule;
};

MonoFrontend.defaultProps = {
  programAgnostic: false,
};

MonoFrontend.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  viewUrl: PropTypes.string.isRequired,
  scriptBundle: PropTypes.string.isRequired,
  cssBundle: PropTypes.string.isRequired,
  didMount: PropTypes.func,
  willUnmount: PropTypes.func,
  programAgnostic: PropTypes.bool,
  bearer: PropTypes.string,
};

export default memo(MonoFrontend);
