import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppState } from 'providers/AppProvider';
import { makeStyles } from '@material-ui/core/styles';

import { AccuvAutocomplete, AccuvButton } from '@accuv/shared-components';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    paddingTop: theme.spacing(3),
    float: 'right',
  },
  clearButton: {
    marginLeft: theme.spacing(1),
  },
  filterButton: {
    minWidth: '100px',
  },
}));

const NotificationFilterNew = ({
  filterProgramVal,
  handleClosePopover,
  handleGetFilterValue,
}) => {
  const [state] = useAppState();

  const classes = useStyles();

  const [programsVal, setProgramsVal] = useState([]);

  const handleFilterValChange = (_event, value) => {
    setProgramsVal(value);
  };

  const handleClearClick = () => {
    handleClosePopover();
    setProgramsVal([]);
    handleGetFilterValue([]);
  };

  const handleFilterClick = () => {
    if (programsVal.length > 0) {
      handleClosePopover();
      handleGetFilterValue(programsVal);
    }
  };

  const getAllPrograms = () => {
    let allPrograms = [];
    for (let key in state.permissions) {
      let permission = state.permissions[key];
      for (let program in permission) {
        if (program == 'groupId') continue;
        if (!allPrograms.some((el) => el.programId === program))
          allPrograms.push({ programId: program, ProgramName: permission[program] });
      }
    }

    return allPrograms;
  };

  useEffect(() => {
    if (filterProgramVal) {
      setProgramsVal(filterProgramVal);
    }
  }, [filterProgramVal]);

  return (
    <div style={{ marginBottom: 20, maxWidth: 400 }}>
      <AccuvAutocomplete
        multiple
        label="Programs"
        variant="outlined"
        value={programsVal}
        disableCloseOnSelect
        options={getAllPrograms()}
        placeholder="Select Programs"
        onChange={handleFilterValChange}
        getOptionLabel={(option) => option.ProgramName}
      />
      <div className={classes.buttonContainer}>
        <AccuvButton
          color="primary"
          className={classes.clearButton}
          onClick={handleClearClick}
        >
          Clear
        </AccuvButton>
        <AccuvButton
          variant="contained"
          color="primary"
          className={classes.filterButton}
          disabled={programsVal === 0}
          onClick={handleFilterClick}
        >
          Filter
        </AccuvButton>
      </div>
    </div>
  );
};

NotificationFilterNew.propTypes = {
  handleClosePopover: PropTypes.func,
  handleGetFilterValue: PropTypes.func,
  filterProgramVal: PropTypes.any,
};

export default NotificationFilterNew;
