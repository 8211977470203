import React from 'react';

import { AuthConsumer } from 'providers/AuthProvider';

export const LogoutCallback = () => {
  return (
    <AuthConsumer>
      {({ signoutRedirectCallback }) => {
        signoutRedirectCallback();
        return (
          <div className="loader-container">
            <span className="loader-spinner"></span>
            <span className="loader-text">Logging out ...</span>
          </div>
        );
      }}
    </AuthConsumer>
  );
};
