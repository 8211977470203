const PROGRAM_ID_KEY = 'program';
const PROGRAM_NAME_KEY = 'program_name';

export default class ProgramService {
  static getSelectedProgramId = () => {
    let program = sessionStorage.getItem(PROGRAM_ID_KEY);
    if (!program) {
      program = localStorage.getItem(PROGRAM_ID_KEY);
      if (program) sessionStorage.setItem(PROGRAM_ID_KEY, program);
    }
    if (!program) console.info('Program not found!');
    return program;
  };

  static getSelectedProgramName = () => {
    let program = sessionStorage.getItem(PROGRAM_NAME_KEY);
    if (!program) {
      program = localStorage.getItem(PROGRAM_NAME_KEY);
      if (program) sessionStorage.setItem(PROGRAM_NAME_KEY, program);
    }
    if (!program) console.info('Program not found!');
    return program;
  };

  static setSelectedProgram = (program: { id: string; name: string }) => {
    if (!program) throw new Error('Program cannot be null');

    sessionStorage.setItem(PROGRAM_ID_KEY, program.id);
    sessionStorage.setItem(PROGRAM_NAME_KEY, program.name);
    localStorage.setItem(PROGRAM_ID_KEY, program.id);
    localStorage.setItem(PROGRAM_NAME_KEY, program.name);
    return program;
  };
}
