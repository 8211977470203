import { MenuItem } from 'typings/menu';
import { ApiBase } from '../API';
import env from '@beam-australia/react-env';

export interface IApplicationApi {
  getPermissions: () => Promise<any[]>;
  getDashboardsByProgram: (program: string) => Promise<any[]>;
  getDashboardsAll: () => Promise<any[]>;
  downloadExportedFile: (id: number) => Promise<any>;
  getUserBackgroundJob: (id: string) => Promise<any>;
}

const appServiceBasePath = env('APPLICATION_SERVICE_ENDPOINT');

class ApplicationApi extends ApiBase implements IApplicationApi {
  private readonly permissions: MenuItem[];

  constructor() {
    super(appServiceBasePath);
    this.permissions = [];
  }

  public getPermissions = async (): Promise<any[]> => {
    return this.instance.get('/api/MenuItems/GetMenuItemsWithGroup?all=true');
  };

  public getDashboardsByProgram = async (program: string): Promise<any[]> => {
    return this.instance.get(`/api/Dashboards?projectId=${program}`);
  };

  public getDashboardsAll = async (): Promise<any[]> => {
    return this.instance.get('/api/Dashboards?all=true');
  };

  public downloadExportedFile = async (id: number): Promise<any> => {
    return this.instance.get(`/api/UserBackgroundJobs/GetExportedFileUrl?id=${id}`);
  };

  public getUserBackgroundJob = async (id: string): Promise<any> => {
    return this.instance.get(`/api/UserBackgroundJobs/${id}`);
  };

  public retryBackgroundJob = async (
    blobName: string,
    queueName: string
  ): Promise<any> => {
    return this.instance.post(
      `/api/UserBackgroundJobs/RetryBackgroundJob?blobName=${blobName}&queueName=${queueName}`
    );
  };

  public cancellingBackgroundJob = async (id: number): Promise<any> => {
    return this.instance.put(`/api/UserBackgroundJobs/CancellingBackgroundJob?id=${id}`);
  };
}

export default ApplicationApi;
