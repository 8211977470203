import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Wrapper } from 'components';
import FullPageMenu from 'containers/Home/FullPageMenu/FullPageMenu';
import { AccuvTypography } from '@accuv/shared-components';
import AppConfig from 'constants/AppConfig';
import { useAuthContext } from 'providers/AuthProvider';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    minHeight: '100%',
  },
  message: {
    fontSize: 20,
    fontWeight: 'normal',
    marginBottom: 15,
  },
}));

const AppsPanel = () => {
  console.log('Apps rendering...');

  const classes = useStyles();

  const [userName, setUserName] = useState('');
  const authContext = useAuthContext();
  authContext.getUserName().then((username) => {
    if (username) {
      const firstName = username.split(' ')[0];
      setUserName(firstName);
    }
  });
  document.title = AppConfig.pageTitle;

  return (
    <Wrapper padding>
      <Container className={classes.content}>
        <AccuvTypography className={classes.message}>
          Hi {userName}, these are all the apps available for you!
        </AccuvTypography>
        <FullPageMenu></FullPageMenu>
      </Container>
    </Wrapper>
  );
};

export default AppsPanel;
