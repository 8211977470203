import React, { useEffect, useState, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMountEffect from 'hooks/mountEffect';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import {
  AccuvList,
  AccuvButton,
  AccuvDrawer,
  AccuvListItem,
  AccuvIconButton,
  AccuvTypography,
  AccuvLoadingIcon,
  AccuvStatusMessageBox,
} from '@accuv/shared-components';

import { NotificationItems } from './NotificationItems';

import {
  setUnreadCount,
  updateNotifications,
  refreshNotifications,
  toggleNotificationPanel,
  updateNotPaneBlockCompleted,
  refreshNotificationPaneCompleted,
} from 'providers/AppProvider/ActionCreators';

import { notificationCenterWidth, topBarHeight } from 'styleVariables';
import NotificationApi from 'services/Notification/NotificationApi';
import { useToast, ADD } from 'providers/ToastProvider';
import { useAppState } from 'providers/AppProvider';

import env from '@beam-australia/react-env';

import { connectToSignalr } from 'services/SignalR';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: notificationCenterWidth,
    maxWidth: notificationCenterWidth,
    top: `${topBarHeight}px !important`,
    height: `calc(100% - ${topBarHeight}px)`,
    zIndex: theme.zIndex.drawer + 99,
  },
  modal: {
    top: `${topBarHeight}px !important`,
    zIndex: theme.zIndex.drawer + 99,
  },
  backdrop: {
    top: `${topBarHeight}px !important`,
  },
  container: {
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
    zIndex: 1,
    flexGrow: 1,
    ...theme.mixins.scrollbar,
  },
  drawerTitle: {
    padding: theme.spacing(1.5, 2),
    borderBottom: `1px solid ${theme.palette.gray.border}`,
  },
  list: {
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  title: {
    display: 'inline',
    width: '50%',
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  skeletonItem: {
    borderBottom: `1px solid ${theme.palette.gray.border}`,
  },
  skeletonContainer: {
    width: '100%',
  },
  skeletonText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  error: {
    top: '40%',
    position: 'absolute',
    width: '100%',
  },
  moreHyperlinkSection: {
    textAlign: 'center',
    marginLeft: '4px',
    marginRight: '4px',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  moreHyperlink: {
    width: '100%',
  },
}));

const notificationApi = new NotificationApi();

function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

const pageSize = 50; //by default fetch 50 rows

const NotificationCenterNew = () => {
  const pushNotifications = env('PUSH_NOTIFICATIONS');
  const notificationHubName = env('NOTIFICATION_HUB_NAME');
  const notificationService = env('NOTIFICATION_SERVICE_ENDPOINT');
  const navigate = useNavigate();

  const notificationHub = `${notificationService}/${notificationHubName}`;

  const classes = useStyles();

  const [state, dispatch] = useAppState();

  const { toastDispatch } = useToast();

  const totalPages = useRef(0);
  const currentPage = useRef(1);
  const notificationsRefArray = useRef([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showChip, setShowChip] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [notificationsArray, setNotificationsArray] = useState(null);

  useMountEffect(() => {
    // Fetch user permissions
    if (pushNotifications === 'true') {
      if (!iOS()) {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            setShowChip(false);
          } else {
            setShowChip(true);
          }
        });
      }
    }
  });

  const loadNotifications = useCallback(
    async (filterValues) => {
      try {
        if (notificationsRefArray.current.length > 0) {
          let lastElementCreatedDate =
            notificationsRefArray.current[notificationsRefArray.current.length - 1];

          filterValues.toDate = lastElementCreatedDate.createdDate;
        }

        const data = await notificationApi.getFilteredNotifications(filterValues);

        let initNotifications = data.notifications;
        let notifications = initNotifications.filter((n) => n.delete !== true);
        notificationsRefArray.current.push(...notifications);
        if (totalPages.current === 0) {
          totalPages.current = data.totalPages;
        }
        setNotificationsArray(notificationsRefArray.current);
      } catch (error) {
        setNotificationsArray('error');
        toastDispatch({
          type: ADD,
          payload: {
            content: error.message,
            type: 'error',
          },
        });
      } finally {
        if (isLoadingMore) {
          setIsLoadingMore(false);
        }

        if (state.refreshNotificationPane) {
          dispatch(refreshNotificationPaneCompleted());
        }
      }
    },
    [state.refreshNotificationPane, isLoadingMore]
  );

  const handleMoreOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreOptionsClose = () => {
    setAnchorEl(null);
  };

  const markNotificationAsRead = (id) => {
    notificationApi
      .markAsRead(id)
      .then(() => {})
      .catch(() => {
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the notification as read',
            type: 'error',
          },
        });
      });
  };

  const handleNotificationToggle = () => {
    dispatch(toggleNotificationPanel());
  };

  const handleNotificationItemClick = (notification) => () => {
    if (notification.read === false) {
      markNotificationAsRead(notification.id);
    }
  };

  const markAllNotificationAsRead = (ids) => {
    notificationApi
      .markAllAsRead(ids)
      .then(() => {})
      .catch(() => {
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the notification as read',
            type: 'error',
          },
        });
      });
  };

  const handleMarkAllNotificationsAsRead = () => {
    handleMoreOptionsClose();

    if (Array.isArray(notificationsArray) && notificationsArray.length > 0) {
      const ids = notificationsArray
        .filter((not) => not.read === false)
        .map((opt) => opt.id);

      ids.length > 0 && markAllNotificationAsRead(ids);
    }
  };

  const handleRefreshNotifications = () => {
    handleMoreOptionsClose();
    dispatch(refreshNotifications());
  };

  const handleChipDelete = () => {
    setShowChip(false);
  };

  const getUnreadCount = async () => {
    try {
      const unreadCount = await notificationApi.getUnreadNotificationsCount();
      dispatch(setUnreadCount(unreadCount));
    } catch (error) {
      toastDispatch({
        type: ADD,
        payload: {
          content: error.message,
          type: 'error',
        },
      });
    }
  };

  const handleOnNotification = useCallback((notification) => {
    dispatch(updateNotifications([notification]));
    getUnreadCount();
  }, []);

  const handleOnNotificationList = (notificationList) => {
    dispatch(updateNotifications(notificationList));
    getUnreadCount();
  };

  const handleLoadMoreNotifications = () => {
    if (totalPages.current > 0 && currentPage.current < totalPages.current) {
      currentPage.current++;
      setIsLoadingMore(true);
    }
  };

  //when mounted connect to signalR
  useEffect(() => {
    connectToSignalr(notificationHub).then((connection) => {
      connection.off('Notification');
      connection.on('Notification', handleOnNotification);
      connection.off('NotificationList');
      connection.on('NotificationList', handleOnNotificationList);
    });

    //get count on the first load
    getUnreadCount();
  }, []);

  //when opened for the first time or on load more fetch data
  useEffect(() => {
    if (state.notificationPanelOpened) {
      if (
        (totalPages.current === 0 && !isLoadingMore) || //first load
        isLoadingMore //on more click
      ) {
        loadNotifications({ pageSize });
      }
    }
  }, [isLoadingMore, state.notificationPanelOpened]);

  //below function is executed when a notification should update due to a change (from the correpsondant block or from the pane)
  useEffect(() => {
    if (state.notificationsToUpdate && state.notificationsToUpdate.length > 0) {
      if (
        notificationsArray &&
        Array.isArray(notificationsArray) &&
        notificationsRefArray.current.length > 0
      ) {
        let newNotificationsArray = [...notificationsRefArray.current];

        state.notificationsToUpdate.map((x) => {
          let itemToUpdateIndex = newNotificationsArray.findIndex(
            (elem) => elem.id === x.id
          );

          //if item already exists in the shown notifications:update it
          if (itemToUpdateIndex > -1) {
            if (x.delete && x.type === 'todo') {
              x.payload.status = 'done';
            }

            newNotificationsArray[itemToUpdateIndex] = x;
          }
          //if it s a new notification: add it on the top:  createdDate === lastModifiedBy only on creation
          else if (x.lastModifiedDate === x.createdDate) {
            newNotificationsArray.unshift(x);
          }
          //else: it s an existing notification being updated no action taken because on load more it will be fetched with updated values
        });

        notificationsRefArray.current = newNotificationsArray;
        setNotificationsArray(newNotificationsArray);
      }

      dispatch(updateNotPaneBlockCompleted());
    }
  }, [state.notificationsToUpdate]);

  //below function is executed when we click on refresh from my activity or from the notification pane
  useEffect(() => {
    if (state.refreshNotificationPane) {
      currentPage.current = 1;
      notificationsRefArray.current = [];
      totalPages.current = 0;

      loadNotifications({ pageSize });

      getUnreadCount();
    }
  }, [state.refreshNotificationPane]);

  let showMore = currentPage.current < totalPages.current;
  let notificationsRender;

  if (!notificationsArray) {
    // Show the skeleton if the items are not available yet
    const skeletonItems = [1, 2, 3, 4, 5, 6, 7];
    notificationsRender = (
      <AccuvList className={classes.list}>
        {skeletonItems.map((index) => (
          <AccuvListItem className={classes.skeletonItem} key={index}>
            <AccuvTypography
              component="div"
              variant="caption"
              className={classes.skeletonContainer}
            >
              <Skeleton
                animation="wave"
                height={10}
                width="80%"
                className={classes.skeletonText}
              />
              <Skeleton
                animation="wave"
                height={10}
                width="40%"
                className={classes.skeletonText}
              />
              <Skeleton
                animation="wave"
                height={10}
                width="70%"
                className={classes.skeletonText}
              />
            </AccuvTypography>
          </AccuvListItem>
        ))}
      </AccuvList>
    );
  } else if (notificationsArray === 'error') {
    notificationsRender = (
      <div className={classes.error}>
        <AccuvStatusMessageBox>
          An error occurred loading the notifications, please refresh the page
        </AccuvStatusMessageBox>
      </div>
    );
  } else {
    if (notificationsArray.length > 0) {
      notificationsRender = (
        <AccuvList className={classes.list}>
          {notificationsArray.map((notification, index) => {
            let Notification = NotificationItems(notification.type);

            return (
              <Notification
                key={index}
                notification={notification}
                onClick={handleNotificationItemClick(notification)}
                notificationToggleCallback={handleNotificationToggle}
              />
            );
          })}

          {showMore && (
            <div className={classes.moreHyperlinkSection}>
              {isLoadingMore ? (
                <AccuvLoadingIcon size={20} />
              ) : (
                <AccuvButton
                  variant="outlined"
                  color="primary"
                  className={classes.moreHyperlink}
                  onClick={handleLoadMoreNotifications}
                >
                  LOAD MORE ...
                </AccuvButton>
              )}
            </div>
          )}
        </AccuvList>
      );
    } else {
      notificationsRender = (
        <div className={classes.error}>
          <AccuvStatusMessageBox>No New Notification</AccuvStatusMessageBox>
        </div>
      );
    }
  }

  return (
    <AccuvDrawer
      variant="persistent"
      anchor="right"
      open={state.notificationPanelOpened}
      PaperProps={{
        elevation: 8,
      }}
      ModalProps={{
        keepMounted: false,
        className: classes.modal,
        BackdropProps: {
          className: classes.backdrop,
        },
        onBackdropClick: handleNotificationToggle,
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <AccuvTypography className={classes.drawerTitle}>
        <div className={classes.title}>Notifications</div>
        {showChip && (
          <Tooltip
            title={
              <>
                <p>
                  In order to take advantage of the system push notifications, please
                  follow the instructions below:
                </p>
                <h2>For Chrome</h2>
                <ul>
                  <li>
                    Click on the <b>Site Information</b> symbol next to the website URL
                  </li>
                  <li>
                    You should now be able to see a <b>Notifications</b> field saying{' '}
                    <b>Block</b>
                  </li>
                  <li>
                    Select <b>Allow</b> on this site
                  </li>
                </ul>
              </>
            }
            aria-label="add"
          >
            <Chip
              className={classes.chip}
              label="Push notifications disabled"
              variant="outlined"
              color="primary"
              size="small"
              icon={<PriorityHighIcon />}
              onDelete={handleChipDelete}
            />
          </Tooltip>
        )}
      </AccuvTypography>
      <AccuvIconButton
        className={classes.closeButton}
        size="small"
        onClick={handleMoreOptionsClick}
      >
        <MoreVertIcon />
        {state.refreshNotificationPane && (
          <CircularProgress size={30} className={classes.buttonProgress} />
        )}
      </AccuvIconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMoreOptionsClose}
      >
        <MenuItem onClick={handleMarkAllNotificationsAsRead}>Mark all as read</MenuItem>
        <MenuItem onClick={handleRefreshNotifications}>Refresh</MenuItem>
        <MenuItem
          onClick={() => {
            handleMoreOptionsClose();
            navigate('/apps/notificationCenter');
          }}
        >
          Notification Center
        </MenuItem>
      </Menu>
      <div className={classes.container}>{notificationsRender}</div>
    </AccuvDrawer>
  );
};

export default NotificationCenterNew;
