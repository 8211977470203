import env from '@beam-australia/react-env';

const byPassModuleSecurity = env('BYPASS_MODULE_SECURITY');

export const createDictionary = (menusByPrograms: any[], dashboardPrograms: any[]) => {
  console.log('Creating menu hash...');
  var dictionary = {};
  const addModule = (
    moduleId: string | number,
    programId: string | number,
    programText: string,
    groupId: string | number
  ) => {
    if (!dictionary[moduleId]) {
      dictionary[moduleId] = {};
      dictionary[moduleId][programId] = programText;
      dictionary[moduleId]['groupId'] = [groupId];
    } else {
      if (!dictionary[moduleId][programId]) {
        dictionary[moduleId][programId] = programText;
      }
      if (!dictionary[moduleId]['groupId']) {
        dictionary[moduleId]['groupId'] = [groupId];
      } else if (dictionary[moduleId]['groupId'].indexOf(groupId) < 0) {
        dictionary[moduleId]['groupId'].push(groupId);
      }
    }
  };

  for (let i = 0; i < menusByPrograms.length; i++) {
    const program = menusByPrograms[i];
    for (let j = 0; j < program.length; j++) {
      const module = program[j];

      addModule(
        module.ModuleId,
        module.ProjectId,
        module.ProjectName,
        module.ModuleGroupId
      );
      // We add a -1 moduleId to store all the available programs, which is used in program selector
      // moduleId -1 is also used by Dashboards, which is inside Analytics group
      //addModule(-1, module.ProjectId, module.ProjectName, 0);
    }
  }

  //new dashboard access implementation, instead of adding all programs we are only adding the ones that the user have access to
  for (let i = 0; i < dashboardPrograms.length; i++) {
    addModule(-1, dashboardPrograms[i].ProjectId, dashboardPrograms[i].ProjectName, 0);
  }
  //console.log(dictionary);
  return dictionary;
};

export const hasAccessToModule = (id: number, dictionary: any[], program: string) => {
  if (byPassModuleSecurity === 'true' || id == -1) return true;

  //For the menus it's ok to have undefined program, we want to check access to the modules, program is selected later
  //console.log(`Checking permission for module ${id} program ${program}`);
  if (dictionary[id] === undefined) return false;
  const permission = program ? dictionary[id][program] : dictionary[id];
  if (permission) {
    return true;
  }
  return false;
};

export const hasAccessToModuleWithoutProgram = (id: number, dictionary: any[]) => {
  if (byPassModuleSecurity === 'true' || id == -1) return true;
  const permission = dictionary[id];
  if (permission) {
    return true;
  }
  return false;
};
export const moduleInGroup = (id: number, dictionary: any[], groupId: string) => {
  if (dictionary[id] === undefined) return false;
  //get the module's groupId array
  const groups = dictionary[id]['groupId'];
  if (groups != undefined && groups.indexOf(parseInt(groupId)) >= 0) return true;
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const generateDashboardPermissions = (dashboars: any[]) => {
  console.log(`Generate dashbaord items for  dashboars ${dashboars}`);
  let items: any[] = [];
  for (let item of dashboars) {
    if (item.type == 'Group') {
      items = items.concat(item.items);
    } else {
      items.push(item);
    }
  }
  return items;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const hasAccessToDashboard = (id: number, dictionary: any[], program: string) => {
  console.log(
    `Checking permission for dashboard ${id} program ${program} dictionary ${dictionary}`
  );
  if (dictionary === null) return false;
  if (dictionary[program] === undefined) return false;
  const items = generateDashboardPermissions(dictionary[program]);
  const permission = items.find((x: { id: number }) => x.id == id);
  if (permission) {
    return true;
  }
  return false;
};

export const _hasAccess = (route: any, menusByPrograms: any[]) => {
  if (byPassModuleSecurity === 'true') return true;
  for (let i = 0; i < menusByPrograms.length; i++) {
    const program = menusByPrograms[i];
    for (let j = 0; j < program.items.length; j++) {
      const module = program.items[j];
      if (module.moduleId === route.id) return true;
    }
  }
  return false;
};

export const createDashboardDictionary = (
  program: string,
  dashboardsState: any,
  dashboardsByPrograms: any[]
) => {
  console.log('Creating dashboard hash...');
  var dictionary = {
    ...dashboardsState,
  };
  dictionary[program] = dashboardsByPrograms;

  return dictionary;
};

export const moduleInRoute = (id: number, dictionary: any[]) => {
  const route = dictionary.find((element) => element.display && element.id == id);
  if (route === undefined) return null;
  return route;
};
