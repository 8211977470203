/* eslint-disable no-undef */

// Spacing
const drawerWidth = 420;
const notificationCenterWidth = 360;
const userPanelWidth = 300;
const userPanelHeight = 140;
const helpPanelWidth = 300;
const MobileBreakpoint = 960;
const topBarHeight = 48;

export {
  drawerWidth,
  notificationCenterWidth,
  MobileBreakpoint,
  topBarHeight,
  userPanelWidth,
  userPanelHeight,
  helpPanelWidth,
};
