import { ApiBase } from '../API';
import env from '@beam-australia/react-env';

export interface SearchResult {}

export interface ISearchApi {
  search: (criteria: string) => Promise<SearchResult[]>;
  searchExact: (criteria: string) => Promise<SearchResult>;
  searchPO: (poNumber: string) => Promise<any>;
}

// TODO: search service is not implemented yet
// const searchServiceBasePath = env('SEARCH_SERVICE_ENDPOINT');
const monoBasePath = env('MONOLITHIC_BASE_ENDPOINT');

class SearchApi extends ApiBase implements ISearchApi {
  private readonly searchResults: SearchResult[];

  constructor() {
    super(monoBasePath);
    this.searchResults = [];
  }

  public search = async (criteria: string): Promise<SearchResult[]> => {
    console.log(criteria);
    return Promise.resolve(this.searchResults);
  };

  public searchExact = async (criteria: string): Promise<SearchResult> => {
    console.log(criteria);
    return Promise.resolve({ found: true, PONumber: 'PO-2401549', projectId: 'VZW' });
  };

  public searchPO = async (poNumber: string): Promise<any> => {
    return this.instance.post<any>(`/VendorInvoice/SearchPONumber?poNumber=${poNumber}`);
  };
}

export default SearchApi;
