import React, { useCallback, useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem, ListItemSecondaryAction } from '@material-ui/core';

import {
  AccuvTabs,
  AccuvTab,
  AccuvList,
  AccuvButton,
  AccuvIconButton,
  AccuvLoadingIcon,
  AccuvStatusMessageBox,
} from '@accuv/shared-components';

import AnnouncementItem from './AnnouncementItem';
import AnnouncementTabPanel from './AnnouncementTabPanel';
import AnnouncementSkeleton from './AnnouncementSkeleton';

import { useToast, ADD } from 'providers/ToastProvider';
import NotificationApi from 'services/Notification/NotificationApi';

import {
  refreshAnnouncementCompleted,
  updateAnnoucementBlockCompleted,
} from 'providers/AppProvider/ActionCreators';

import { useAppState } from 'providers/AppProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  toolbarIconButton: {
    width: 24,
    height: 24,
    borderRadius: 0,
    position: 'relative',
    marginLeft: 15,
    '&:hover': {
      color: theme.palette.white,
      background: theme.palette.secondary.main,

      '&::before': {
        borderRadius: 0,
        transform: 'scale(1)',
      },
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: 360,
    overflowY: 'auto',
    ...theme.mixins.scrollbar,
  },
  message: {
    marginTop: 'calc(50% - 60px)',
  },
  messageText: {
    padding: '0',
  },
  tabs: {
    padding: theme.spacing(2, 1, 1, 2),
  },
  moreHyperlinkSection: {
    textAlign: 'center',
    marginLeft: '4px',
    marginRight: '4px',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  moreHyperlink: {
    width: '100%',
  },
}));

const notificationApi = new NotificationApi();

const pageSize = 50; //by default fetch 50 rows
const notificationType = 'announcement';

const AnnouncementsNew = () => {
  const classes = useStyles();

  const { toastDispatch } = useToast();

  const [state, dispatch] = useAppState();

  const totalPages = useRef(0);
  const currentPage = useRef(1);
  const announcementsRefArray = useRef([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [announcements, setAnnouncements] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleLoadMore = useCallback(() => {
    if (totalPages.current > 0 && currentPage.current < totalPages.current) {
      currentPage.current++;
      setIsLoadingMore(true);
    }
  }, []);

  const handleMoreOptionsClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMoreOptionsClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const markAllNotificationAsRead = (ids) => {
    notificationApi
      .markAllAsRead(ids)
      .then(() => {})
      .catch(() => {
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the notification as read',
            type: 'error',
          },
        });
      });
  };

  const handleMarkAllNotificationsAsRead = () => {
    handleMoreOptionsClose();
    if (Array.isArray(announcements) && announcements.length > 0) {
      const ids = announcements
        .filter((announcement) => announcement.read === false)
        .map((opt) => opt.id);
      ids.length > 0 && markAllNotificationAsRead(ids);
    }
  };

  const loadNotifications = useCallback(
    async (filterValues) => {
      try {
        if (announcementsRefArray.current.length > 0) {
          let lastElementCreatedDate =
            announcementsRefArray.current[announcementsRefArray.current.length - 1];

          filterValues.toDate = lastElementCreatedDate.createdDate;
        }

        const data = await notificationApi.getFilteredNotifications(filterValues);
        if (totalPages.current === 0) {
          totalPages.current = data.totalPages;
        }

        announcementsRefArray.current.push(...data.notifications);
        setAnnouncements(announcementsRefArray.current);
      } catch (error) {
        toastDispatch({
          type: ADD,
          payload: {
            content: error.message,
            type: 'error',
          },
        });
      } finally {
        if (isLoadingMore) {
          setIsLoadingMore(false);
        }

        if (state.refreshAnnouncement) {
          dispatch(refreshAnnouncementCompleted());
        }
      }
    },
    [state.refreshAnnouncement, isLoadingMore]
  );

  //below function is executed on the first load and on each more click to get data
  useEffect(() => {
    if (
      (totalPages.current === 0 && !isLoadingMore) || //first load
      isLoadingMore //on more click
    ) {
      loadNotifications({
        pageSize,
        typeStatus: [{ type: notificationType }],
      });
    }
  }, [isLoadingMore]);

  //below function is executed if any notification changed and we should update it (updates returned from signal r)
  useEffect(() => {
    if (state.notificationsToUpdate && state.notificationsToUpdate.length > 0) {
      if (
        announcements &&
        Array.isArray(announcements) &&
        announcementsRefArray.current.length > 0
      ) {
        let newAnnouncements = [...announcementsRefArray.current];
        let notificationsToUpdateAnnouncements = state.notificationsToUpdate.filter(
          (y) => y.type === 'announcement'
        );

        notificationsToUpdateAnnouncements.map((x) => {
          let itemToUpdateIndex = newAnnouncements.findIndex((elem) => elem.id === x.id);
          if (itemToUpdateIndex > -1) {
            newAnnouncements[itemToUpdateIndex] = x;
          }
          //if it s a new notification: add it on the top:  createdDate === lastModifiedBy only on creation
          else if (x.lastModifiedDate === x.createdDate) {
            newAnnouncements.unshift(x);
          }
        });

        announcementsRefArray.current = newAnnouncements;
        setAnnouncements(newAnnouncements);
      }

      dispatch(updateAnnoucementBlockCompleted());
    }
  }, [state.notificationsToUpdate]);

  //below function is executed if we click refresh notification from not pane or my activity section
  useEffect(() => {
    if (state.refreshAnnouncement) {
      currentPage.current = 1;
      announcementsRefArray.current = [];
      totalPages.current = 0;

      loadNotifications({
        pageSize,
        typeStatus: [{ type: notificationType }],
      });
    }
  }, [state.refreshAnnouncement]);

  let announcementActivePanel;

  let showMore = currentPage.current < totalPages.current;

  if (!announcements) {
    announcementActivePanel = <AnnouncementSkeleton />;
  } else if (Array.isArray(announcements) && announcements.length > 0) {
    let announcementItems;
    announcementItems = announcements.map((announcement, index) => (
      <AnnouncementItem
        key={`announcementItem_ ${announcement.id}`}
        index={index}
        announcement={announcement}
        isLastElement={index === announcements.length - 1}
      />
    ));

    if (announcementItems.length > 0) {
      announcementActivePanel = (
        <AccuvList dense className={classes.list}>
          {announcementItems}
          {showMore && (
            <div className={classes.moreHyperlinkSection}>
              {isLoadingMore ? (
                <AccuvLoadingIcon size={20} />
              ) : (
                <AccuvButton
                  variant="outlined"
                  color="primary"
                  className={classes.moreHyperlink}
                  onClick={handleLoadMore}
                >
                  LOAD MORE ...
                </AccuvButton>
              )}
            </div>
          )}
        </AccuvList>
      );
    }
  } else if (Array.isArray(announcements) && announcements.length === 0) {
    announcementActivePanel = (
      <div className={classes.message}>
        <AccuvStatusMessageBox className={classes.messageText}>
          No New Announcements
        </AccuvStatusMessageBox>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AccuvTabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
      >
        <AccuvTab label="Active" />
        <ListItemSecondaryAction>
          <AccuvIconButton
            edge="end"
            aria-label="more options"
            onClick={handleMoreOptionsClick}
            className={classes.toolbarIconButton}
          >
            <MoreVertIcon />
          </AccuvIconButton>
        </ListItemSecondaryAction>
      </AccuvTabs>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMoreOptionsClose}
      >
        <MenuItem onClick={handleMarkAllNotificationsAsRead}>Mark all as read</MenuItem>
      </Menu>
      <AnnouncementTabPanel value={0} index={0}>
        {announcementActivePanel}
      </AnnouncementTabPanel>
    </div>
  );
};

export default AnnouncementsNew;
