/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import MainApp from './App';
// import * as serviceWorker from './serviceWorker';
import {
  NotFound,
  BackendError,
  SessionExpired,
  UnderMaintenance,
  Unauthorized,
} from 'pages';
import { Callback } from 'components/Authentication/Callback';
import { Logout } from 'components/Authentication/Logout';
import { LogoutCallback } from 'components/Authentication/LogoutCallback';
import { SilentRenew } from 'components/Authentication/SilentRenew';
import PrivateRoute from 'components/Authentication/PrivateRoute';
import { AuthProvider } from 'providers/AuthProvider';

// console.log(`ENV: ${process.env.NODE_ENV}`);
// if (process.env.NODE_ENV !== 'production') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

ReactDOM.render(
  <AuthProvider>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/Error/404" element={<NotFound />} />
        <Route path="/Error/500" element={<BackendError />} />
        <Route path="/Error/sessionExpired" element={<SessionExpired />} />
        <Route path="/Error/unauthorized" element={<Unauthorized />} />
        <Route path="/underMaintenance" element={<UnderMaintenance />} />
        <Route path="/signin-oidc" element={<Callback />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/logout/callback" element={<LogoutCallback />} />
        <Route path="/silentrenew" element={<SilentRenew />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <MainApp />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  </AuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
