import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ProgramItem from './ProgramItem';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { drawerWidth, topBarHeight } from '../../styleVariables';
import { makeStyles } from '@material-ui/core/styles';
import { AccuvList, AccuvListItem, AccuvListItemText } from '@accuv/shared-components';
import env from '@beam-australia/react-env';
import { useAppState } from 'providers/AppProvider';
import { mockProgramsHash } from 'utils/mock';

// eslint-disable-next-line no-undef
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const useStyles = makeStyles((theme) => ({
  gridList: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(2),
  },
  gridListTitle: {
    '& .MuiTypography-root': {
      fontSize: 18,
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    maxWidth: drawerWidth,
    top: `${topBarHeight}px !important`,
    height: 'auto',
    maxHeight: '100%',
    zIndex: theme.zIndex.drawer + 99,
    ...theme.mixins.scrollbar,
  },
  modal: {
    top: `${topBarHeight}px !important`,
    zIndex: '1000 !important',
  },
  backdrop: {
    top: topBarHeight,
  },
}));

const ProgramSelectorPanel = ({ programSelectorOpened, toggleProgramSelector }) => {
  console.log('ProgramSelectorPanel rendering...');

  const classes = useStyles();
  const [state] = useAppState();
  const byPassModuleSecurity = env('BYPASS_MODULE_SECURITY');
  let programs;
  let programButtons = [];

  if (byPassModuleSecurity === 'true') {
    programs = mockProgramsHash;
  } else {
    if (!state.permissions) {
      return null;
    }
    programs = state.permissions[state.monolithicModule.id];
  }
  var index = 0;
  for (var key in programs) {
    var value = programs[key];
    if (typeof value === 'string') {
      index++;
      programButtons.push(<ProgramItem key={index} program={{ id: key, text: value }} />);
    }
  }

  const menu = (
    <List component="div" onClick={toggleProgramSelector}>
      <AccuvListItem>
        <AccuvListItemText className={classes.gridListTitle}>Programs</AccuvListItemText>
      </AccuvListItem>
      <AccuvList className={classes.gridList}>{programButtons}</AccuvList>
    </List>
  );

  return (
    <>
      <Hidden smDown>
        <Drawer
          variant="temporary"
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={programSelectorOpened}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop,
            },
            onBackdropClick: toggleProgramSelector,
          }}
        >
          {menu}
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawer
          variant="temporary"
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={programSelectorOpened}
          onClose={toggleProgramSelector}
          onOpen={toggleProgramSelector}
          disableBackdropTransition={!iOS}
          ModalProps={{
            keepMounted: false,
            className: classes.modal,
            BackdropProps: {
              className: classes.backdrop,
            },
            onBackdropClick: toggleProgramSelector,
          }}
        >
          {menu}
        </SwipeableDrawer>
      </Hidden>
    </>
  );
};

ProgramSelectorPanel.propTypes = {
  programSelectorOpened: PropTypes.bool,
  toggleProgramSelector: PropTypes.func,
};

export default memo(ProgramSelectorPanel);
