import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';
import { drawerWidth, topBarHeight } from 'styleVariables';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.default,
    minWidth: 0,
    width: '100%',
    position: 'relative',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      overflowY: 'auto',
      overflowX: 'hidden',
      maxHeight: `calc(100vh - ${topBarHeight}px)`,
      ...theme.mixins.scrollbar,
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '-webkit-overflow-scrolling': 'touch',
  },
  'content-left': {
    [theme.breakpoints.up('md')]: {
      marginLeft: -drawerWidth,
    },
  },
  'content-right': {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  'contentShift-right': {
    marginRight: 0,
  },
}));

const Workspace = ({ children }) => {
  console.log('Workspace rendering...');
  const classes = useStyles();

  return (
    <main id="main-spa-container" className={classNames(classes.content)}>
      {children}
    </main>
  );
};

Workspace.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(Workspace);
