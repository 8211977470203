import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  AccuvListItem,
  AccuvListItemText,
  AccuvIconButton,
  AccuvListItemIcon,
  AccuvIcon,
} from '@accuv/shared-components';
import { capitalize } from 'utils/helpers';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ListItemSecondaryAction, Menu, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  menuLink: {
    flex: '0 0 50%',
    '&:hover $actions': {
      visibility: 'visible',
    },
  },
  menuIconWrapper: {
    minWidth: 28,
  },
  menuIcon: {
    fontSize: 20,
    color: theme.palette.primary.main,
    marginRight: '5px',
  },
  menuTextWrapper: {
    '& .mainspa-MuiTypography-root': {
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  menuSubItem: {
    minHeight: 48,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1),
    paddingRight: 40,
  },
  actions: {
    visibility: 'hidden',
  },
  menuActive: {
    backgroundColor: theme.palette.action.hover,
  },
}));

const SubmenuItem = ({ subItem, fullRoute, toggleMainMenu }) => {
  // console.log('SubmenuItem rendering...');

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (route) => {
    console.info(`You clicked ${route}`);
    navigate(route);
    toggleMainMenu();
  };

  const handleMoreOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreOptionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={clsx(
        classes.menuLink,
        location.pathname === fullRoute && classes.menuActive
      )}
    >
      <AccuvListItem
        className={classes.menuSubItem}
        button
        data-test={subItem.name}
        onClick={() => handleClick(fullRoute)}
      >
        <AccuvListItemIcon className={clsx(classes.menuIconWrapper, classes.menuIcon)}>
          {subItem.iconId ? (
            <AccuvIcon icon={subItem.iconId} className={classes.menuIcon} />
          ) : (
            <subItem.icon className={classes.menuIcon} />
          )}
        </AccuvListItemIcon>
        <AccuvListItemText className={classes.menuTextWrapper}>
          {capitalize(subItem.name)}
        </AccuvListItemText>
        <ListItemSecondaryAction className={classes.actions}>
          <AccuvIconButton
            edge="end"
            aria-label="more options"
            onClick={handleMoreOptionsClick}
          >
            <MoreVertIcon />
          </AccuvIconButton>
        </ListItemSecondaryAction>
      </AccuvListItem>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMoreOptionsClose}
      >
        <Link to={fullRoute} target="_blank">
          <MenuItem>Open in new tab</MenuItem>
        </Link>
        <MenuItem>Learn more</MenuItem>
      </Menu>
    </div>
  );
};

SubmenuItem.propTypes = {
  subItem: PropTypes.object,
  fullRoute: PropTypes.string,
  toggleMainMenu: PropTypes.func,
};

export default React.memo(SubmenuItem);
