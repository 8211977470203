import React, { useState } from 'react';
import { AccuvTypography } from '@accuv/shared-components';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthContext } from 'providers/AuthProvider';
import { capitalize } from 'utils/helpers';

const useStyles = makeStyles(() => ({
  message: {
    fontSize: 20,
    fontWeight: 'normal',
    marginBottom: 15,
  },
}));

const Welcome = () => {
  const classes = useStyles();
  const [userName, setUserName] = useState('');

  const authContext = useAuthContext();
  authContext.getUserName().then((username) => {
    if (username) {
      const firstName = username.split(' ')[0];
      setUserName(firstName);
    }
  });

  return (
    <div data-test="welcome-container">
      <AccuvTypography className={classes.message}>
        Hi {capitalize(userName)}, Welcome!
      </AccuvTypography>
    </div>
  );
};

export default Welcome;
