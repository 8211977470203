import React, { useContext } from 'react';
import { createBrowserHistory } from 'history';
import RoutesBuilder from 'routes/RoutesBuilder';
import env from '@beam-australia/react-env';
import useMountEffect from 'hooks/mountEffect';
import './App.scss';

// App Layout
import Layout from 'hoc/Layout/Layout';

//Grafana Faro
import FaroProvider from 'providers/FaroProvider';

// AppProvider is a global state management implemented with useReducer and useContext
import { AppProvider } from 'providers/AppProvider';
import { CookiesProvider } from 'react-cookie';
import { ToastProvider } from 'providers/ToastProvider';
import { AuthContext } from 'providers/AuthProvider';
import { initializeFirebase } from 'services/Notification/FirebaseMessaging';
import { Faro, LogLevel } from '@grafana/faro-web-sdk';
declare global {
  interface Window {
    faro: Faro;
  }
}

const MainApp = () => {
  const authContext = useContext(AuthContext);
  const userProfile = authContext.getUserProfile();
  const userId = userProfile.accuv_username;
  const userName = userProfile.accuv_user_completeName;
  const userEmail = userProfile.email;
  const isLocalAuth = userProfile.idp == 'local';
  const history = createBrowserHistory();
  const pushNotifications = env('PUSH_NOTIFICATIONS');

  useMountEffect(() => {
    const faro = window.faro;
    if (faro) {
      faro.api.setUser({
        email: userEmail,
        id: userId,
        username: userName,
        attributes: {
          islocalauth: isLocalAuth.toString(),
        },
      });
      faro.api.pushLog(['Main SPA started!'], { level: LogLevel.INFO });
      faro.api.pushEvent('mainspa_mount', { userId: userId });
    }

    if (pushNotifications === 'true') {
      initializeFirebase(userId);
    }
  });

  return (
    <FaroProvider>
      <CookiesProvider>
        <AppProvider>
          <ToastProvider>
            <Layout
              history={history}
              userId={userId}
              userName={userName}
              userEmail={userEmail}
              isLocalAuth={isLocalAuth}
            >
              <RoutesBuilder></RoutesBuilder>
            </Layout>
          </ToastProvider>
        </AppProvider>
      </CookiesProvider>
    </FaroProvider>
  );
};

export default MainApp;
