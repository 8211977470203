const prefix = 'appState/';

export const TOGGLE_THEME_TYPE = `${prefix}themeType`;
export const TOGGLE_THEME_DIRECTION = `${prefix}themeDirection`;
export const TOGGLE_MAIN_MENU = `${prefix}toggleMainMenu`;
export const TOGGLE_USER_PANEL = `${prefix}toggleUserPanel`;
export const TOGGLE_HELP_PANEL = `${prefix}toggleHelpPanel`;
export const TOGGLE_NOTIFICATION_PANEL = `${prefix}toggleNotificationPanel`;
export const TOGGLE_PROGRAM_SELECTOR = `${prefix}toggleProgramSelector`;
export const TOGGLE_MONOLITHIC_MODULE = `${prefix}toggleMonolithicModule`;
export const SELECT_PROGRAM = `${prefix}selectProgram`;
export const PERMISSIONS_LOADED = `${prefix}permissionsLoaded`;
export const GROUPS_LOADED = `${prefix}groupsLoaded`;
export const DASHBOARDS_LOADED = `${prefix}dashboardsLoaded`;
export const NOTIFICATIONS_REFRESHED = `${prefix}notificationsRefreshed`;
export const REFRESH_NOTIFICATIONS = `${prefix}refreshNotifications`;
export const PROFILE_PICTURE_CHANGED = `${prefix}profilePictureChanged`;
export const PROFILE_PICTURE_REMOVED = `${prefix}profilePictureRemoved`;
export const SELECT_GROUP = `${prefix}selectGroup`;
export const TODO_NOTIFICATIONS_FILTER_CHANGED = `${prefix}todoNotificationsFilterChanged`;
export const APPLY_NOTIFICATIONS_FILTER = `${prefix}applyNotificationsFilter`;
export const CLEAR_TODO_NOTIFICATIONS_FILTER = `${prefix}clearTodoNotificationsFilter`;
export const REQUESTS_NOTIFICATIONS_FILTER_CHANGED = `${prefix}requestsNotificationsFilterChanged`;
export const CLEAR_REQUESTS_NOTIFICATIONS_FILTER = `${prefix}clearRequestsNotificationsFilter`;
export const REFRESH_TODO_COMPLETED = `${prefix}refreshTodoCompleted`;
export const REFRESH_REQUEST_COMPLETED = `${prefix}refreshRequestCompleted`;
export const REFRESH_ANNOUNCEMENT_COMPLETED = `${prefix}refreshAnnouncementCompleted`;
export const REFRESH_NOTIFICATION_PANE_COMPLETED = `${prefix}refreshNotificationPaneCompleted`;
export const SET_UNREAD_COUNT = `${prefix}setUnreadCount`;
export const UPDATE_NOTIFICATIONS = `${prefix}updateNotifications`;
export const UPDATE_TODO_COMPLETED = `${prefix}updateTodoCompleted`;
export const UPDATE_REQUEST_COMPLETED = `${prefix}updateRequestCompleted`;
export const UPDATE_ANNOUNCEMENT_COMPLETED = `${prefix}updateAnnouncementCompleted`;
export const UPDATE_NOT_PANE_COMPLETED = `${prefix}updateNotPaneCompleted`;
