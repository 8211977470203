/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AccuvListItem,
  AccuvListItemText,
  AccuvTypography,
} from '@accuv/shared-components';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import { truncateText } from 'utils/helpers';
import { Apps } from '../../../constants/Apps';
import { useAppState } from '../../../providers/AppProvider';
import { selectProgram } from '../../../providers/AppProvider/ActionCreators';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: `1px solid ${theme.palette.gray.border}`,
    background: '#F0F1F8',
  },
  listItemRead: {
    background: theme.palette.white,
  },
  listItemRight: {
    marginLeft: 'auto',
    paddingLeft: theme.spacing(1),
    textAlign: 'right',
    flex: '0 1 auto',
  },
  listItemText: {
    '& .mainspa-MuiListItemText-primary': {
      fontSize: 14,
      color: theme.palette.gray.text,
      fontWeight: 'bold',
    },
    '& .mainspa-MuiListItemText-secondary': {
      fontSize: 12,
      color: theme.palette.gray.text,
    },
  },
  listItemTextRead: {
    '& .mainspa-MuiListItemText-primary': {
      fontWeight: 'normal',
    },
    '& .mainspa-MuiListItemText-secondary': {
      color: theme.palette.gray.light,
    },
  },
  notificationTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  notificationTitleRead: {
    fontWeight: 'normal',
  },
  inlineFields: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginTop: theme.spacing(1),
  },
  noMarginTop: {
    marginTop: 0,
  },
  label: {
    fontSize: 11,
    color: theme.palette.gray.light,
    marginRight: theme.spacing(0.5),
    flex: '0 0 auto',
  },
  value: {
    fontSize: 11,
    color: theme.palette.gray.text,
    marginRight: theme.spacing(1),
    flex: '0 1 auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  date: {
    fontSize: 10,
    color: theme.palette.gray.text,
    marginLeft: 'auto',
    minWidth: 60,
  },
}));

const ToDoNotification = ({ notification, onClick, notificationToggleCallback }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [state, dispatch] = useAppState();

  return (
    <AccuvListItem
      button
      className={clsx(
        classes.listItem,
        notification.read === true && classes.listItemRead
      )}
      onClick={() => {
        onClick();
        console.log('TODO notification click');
        if (notification.payload.route) {
          const programId = notification.payload.program;
          const programName =
            state.permissions[Apps.Ids.POWA][notification.payload.program];
          dispatch(
            selectProgram({
              id: programId,
              name: programName,
            })
          );
          navigate(notification.payload.route);
          notificationToggleCallback();
        }
      }}
    >
      <AccuvListItemText
        className={clsx(
          classes.listItemText,
          notification.read === true && classes.listItemTextRead
        )}
        primary={notification.title}
        secondary={truncateText(notification.payload.description, 100, true)}
      ></AccuvListItemText>
      <div className={classes.listItemRight}>
        <AccuvTypography className={classes.date}>
          <Moment fromNow>{notification.dateTime}</Moment>
        </AccuvTypography>
      </div>
    </AccuvListItem>
  );
};

ToDoNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    dateTime: PropTypes.string,
    read: PropTypes.bool,
    payload: PropTypes.object,
  }),
  onClick: PropTypes.func,
  notificationToggleCallback: PropTypes.func,
};

export default ToDoNotification;
