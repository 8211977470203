import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AccuvList,
  AccuvButton,
  AccuvLoadingIcon,
  AccuvStatusMessageBox,
} from '@accuv/shared-components';

import ImportRequest from '../Requests/Import';
import RequestsSkeleton from './RequestsSkeleton';
import ImportResultsPanel from '../Requests/ImportResultsPanel';

import NotificationApi from 'services/Notification/NotificationApi';

import { useAppState } from 'providers/AppProvider';
import { useToast, ADD } from 'providers/ToastProvider';

import {
  refreshRequestCompleted,
  updateReuqestBlockCompleted,
} from 'providers/AppProvider/ActionCreators';

import BackgroundJobStates from 'constants/BackgroundJobStates';

const notificationApi = new NotificationApi();

const pageSize = 50; //by default fetch 50 rows
const notificationType = 'import';

const useStyles = makeStyles((theme) => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: 360,
    overflowY: 'auto',
    ...theme.mixins.scrollbar,
  },
  message: {
    marginTop: 'calc(50% - 60px)',
  },
  messageText: {
    padding: '0',
  },
  moreHyperlinkSection: {
    textAlign: 'center',
    marginLeft: '4px',
    marginRight: '4px',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  moreHyperlink: {
    width: '100%',
  },
}));

const ImportPanelComponent = (props) => {
  const [state, dispatch] = useAppState();

  const classes = useStyles();

  const { toastDispatch } = useToast();

  const currentPage = useRef(1);
  const totalPages = useRef(0);
  const importsRefArray = useRef([]);

  const [imports, setImports] = useState(null);
  const [importResults, setImportResults] = useState({
    open: false,
    importItem: null,
  });
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleLoadMore = useCallback(() => {
    if (totalPages.current > 0 && currentPage.current < totalPages.current) {
      currentPage.current++;
      setIsLoadingMore(true);
    }
  }, []);

  const handleShowResults = (notification) => {
    if (notification.payload.status.toLowerCase() === BackgroundJobStates.COMPLETED) {
      setImportResults({ open: true, importItem: notification });
    }
  };

  const handleCloseImportResults = () => {
    setImportResults({ open: false, importItem: null });
  };

  const markNotificationAsReadAsync = async (id) => {
    return notificationApi.markAsRead(id).catch((error) => {
      console.error(error);
      toastDispatch({
        type: ADD,
        payload: {
          content: 'Error trying to mark the notification as read',
          type: 'error',
        },
      });
    });
  };

  const markNotificationAsRead = (id) => {
    notificationApi
      .markAsRead(id)
      .then((notification) => {
        console.log(`Notification ${notification.id} read!`);
      })
      .catch((error) => {
        console.error(error);
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the notification as read',
            type: 'error',
          },
        });
      });
  };

  const markNotificationAsUnread = (id) => {
    notificationApi
      .markAsUnread(id)
      .then((notification) => {
        console.log(`Notification ${notification.id} marked as unread!`);
      })
      .catch((error) => {
        console.error(error);
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the export as unread',
            type: 'error',
          },
        });
      });
  };

  const handleMarkNotificationAsUnread = (notification) => () => {
    if (notification.read === true) {
      markNotificationAsUnread(notification.id);
    }
  };

  const handleImportItemClick = (notification) => () => {
    if (notification.read === false) {
      // It has to be done in this way to avoid an infinite loop
      markNotificationAsReadAsync(notification.id).then((notification) => {
        setTimeout(() => {
          handleShowResults(notification);
        }, 500);
      });
    } else {
      handleShowResults(notification);
    }
  };

  const handleMarkNotificationAsRead = (notification) => () => {
    if (notification.read === false) {
      markNotificationAsRead(notification.id);
    }
  };

  const filterNotificationsByProgram = () => {
    let not;

    if (props.filterProgramVal.length > 0) {
      not = importsRefArray.current.filter(
        (n) =>
          props.filterProgramVal.filter((p) => p.programId === n.payload.program).length >
          0
      );
    } else {
      not = importsRefArray.current;
    }

    return not;
  };

  const loadNotifications = useCallback(
    async (filterValues) => {
      try {
        if (importsRefArray.current.length > 0) {
          let lastElementCreatedDate =
            importsRefArray.current[importsRefArray.current.length - 1];

          filterValues.toDate = lastElementCreatedDate.createdDate;
        }

        const data = await notificationApi.getFilteredNotifications(filterValues);
        importsRefArray.current.push(...data.notifications);
        if (totalPages.current === 0) {
          totalPages.current = data.totalPages;
        }
        setImports(filterNotificationsByProgram());
      } catch (error) {
        toastDispatch({
          type: ADD,
          payload: {
            content: error.message,
            type: 'error',
          },
        });
      } finally {
        if (isLoadingMore) {
          setIsLoadingMore(false);
        }

        if (state.refreshRequest) {
          dispatch(refreshRequestCompleted());
        }
      }
    },
    [filterNotificationsByProgram, state.refreshRequest, isLoadingMore]
  );

  const markAllNotificationAsRead = (ids) => {
    notificationApi
      .markAllAsRead(ids)
      .then(() => {})
      .catch(() => {
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the notifications as read',
            type: 'error',
          },
        });
      })
      .finally(() => {
        props.markAllReadCallback();
      });
  };

  useEffect(() => {
    if (
      (totalPages.current === 0 && !isLoadingMore) || //first load
      isLoadingMore //on more click
    ) {
      loadNotifications({
        pageSize,
        typeStatus: [{ type: notificationType }],
      });
    }
  }, [isLoadingMore]);

  useEffect(() => {
    if (state.refreshRequest) {
      currentPage.current = 1;
      importsRefArray.current = [];
      totalPages.current = 0;

      loadNotifications({
        pageSize,
        typeStatus: [{ type: notificationType }],
      });
    }
  }, [state.refreshRequest]);

  useEffect(() => {
    if (state.notificationsToUpdate && state.notificationsToUpdate.length > 0) {
      if (imports && Array.isArray(imports) && importsRefArray.current.length > 0) {
        let newImports = [...importsRefArray.current];
        let notificationsToUpdateImport = state.notificationsToUpdate.filter(
          (y) => y.type === 'import'
        );

        notificationsToUpdateImport.map((x) => {
          let itemToUpdateIndex = newImports.findIndex((elem) => elem.id === x.id);
          if (itemToUpdateIndex > -1) {
            newImports[itemToUpdateIndex] = x;
          }
          //if it s a new notification: add it on the top:  createdDate === lastModifiedBy only on creation
          else if (x.lastModifiedDate === x.createdDate) {
            newImports.unshift(x);
          }
        });

        importsRefArray.current = newImports;
        setImports(newImports);
      }

      dispatch(updateReuqestBlockCompleted());
    }
  }, [state.notificationsToUpdate]);

  useEffect(() => {
    try {
      if (totalPages.current !== 0) {
        let filteredNotificationsByProgram = filterNotificationsByProgram();
        setImports(filteredNotificationsByProgram);
      }
    } catch (error) {
      setImports([]);
    }
  }, [props.filterProgramVal]);

  useEffect(() => {
    if (props.markAllReadFromParent) {
      if (Array.isArray(imports) && imports.length > 0) {
        const ids = imports.filter((not) => not.read === false).map((opt) => opt.id);
        ids.length > 0 && markAllNotificationAsRead(ids);
      }
    }
  }, [props.markAllReadFromParent]);

  let showMore = currentPage.current < totalPages.current;

  let importPanel;

  if (!imports) {
    // Show the skeleton if the items are not available yet
    importPanel = <RequestsSkeleton />;
  } else if (Array.isArray(imports) && imports.length > 0) {
    let importItems = imports.map((importItem, index) => {
      return (
        <ImportRequest
          key={index}
          importItem={importItem}
          index={index}
          importsLength={imports.length}
          onClick={handleImportItemClick(importItem)}
          markAsUnreadCallback={handleMarkNotificationAsUnread(importItem)}
          markAsReadCallback={handleMarkNotificationAsRead(importItem)}
        />
      );
    });
    importPanel = (
      <AccuvList dense className={classes.list}>
        {importItems}
        {showMore && (
          <div className={classes.moreHyperlinkSection}>
            {isLoadingMore ? (
              <AccuvLoadingIcon size={20} />
            ) : (
              <AccuvButton
                variant="outlined"
                color="primary"
                className={classes.moreHyperlink}
                onClick={handleLoadMore}
              >
                LOAD MORE ...
              </AccuvButton>
            )}
          </div>
        )}
      </AccuvList>
    );
  } else if (Array.isArray(imports) && imports.length === 0) {
    importPanel = (
      <div className={classes.message}>
        <AccuvStatusMessageBox className={classes.messageText}>
          No Pending Import Request
        </AccuvStatusMessageBox>
      </div>
    );
  } else {
    importPanel = (
      <div className={classes.message}>
        <AccuvStatusMessageBox className={classes.messageText}>
          Something went wrong
        </AccuvStatusMessageBox>
      </div>
    );
  }

  return (
    <>
      {importPanel}
      <ImportResultsPanel
        open={importResults.open}
        importItem={importResults.importItem}
        handleClose={handleCloseImportResults}
      />
    </>
  );
};

ImportPanelComponent.propTypes = {
  refreshNotifications: PropTypes.any,
  filterProgramVal: PropTypes.any,
  markAllReadFromParent: PropTypes.any,
  markAllReadCallback: PropTypes.func,
};

export default ImportPanelComponent;
