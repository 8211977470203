import {
  TOGGLE_THEME_TYPE,
  TOGGLE_THEME_DIRECTION,
  TOGGLE_MAIN_MENU,
  TOGGLE_USER_PANEL,
  TOGGLE_HELP_PANEL,
  TOGGLE_NOTIFICATION_PANEL,
  TOGGLE_PROGRAM_SELECTOR,
  TOGGLE_MONOLITHIC_MODULE,
  SELECT_PROGRAM,
  PERMISSIONS_LOADED,
  GROUPS_LOADED,
  DASHBOARDS_LOADED,
  NOTIFICATIONS_REFRESHED,
  REFRESH_NOTIFICATIONS,
  PROFILE_PICTURE_CHANGED,
  PROFILE_PICTURE_REMOVED,
  SELECT_GROUP,
  TODO_NOTIFICATIONS_FILTER_CHANGED,
  APPLY_NOTIFICATIONS_FILTER,
  CLEAR_TODO_NOTIFICATIONS_FILTER,
  REQUESTS_NOTIFICATIONS_FILTER_CHANGED,
  CLEAR_REQUESTS_NOTIFICATIONS_FILTER,
  //new actions to handle pagination behavior
  SET_UNREAD_COUNT,
  UPDATE_NOTIFICATIONS,
  REFRESH_TODO_COMPLETED,
  REFRESH_REQUEST_COMPLETED,
  REFRESH_ANNOUNCEMENT_COMPLETED,
  REFRESH_NOTIFICATION_PANE_COMPLETED,
  UPDATE_TODO_COMPLETED,
  UPDATE_REQUEST_COMPLETED,
  UPDATE_ANNOUNCEMENT_COMPLETED,
  UPDATE_NOT_PANE_COMPLETED,
} from './ActionTypes';
import { INotification } from 'typings/notification';
import { INotificationFilter } from 'typings/notificationFilter';

export const toggleThemeType = () => ({ type: TOGGLE_THEME_TYPE });
export const toggleThemeDirection = () => ({ type: TOGGLE_THEME_DIRECTION });
export const toggleMainMenu = (open: boolean) => ({
  type: TOGGLE_MAIN_MENU,
  state: open,
});
export const toggleUserPanel = () => ({ type: TOGGLE_USER_PANEL });
export const toggleHelpPanel = () => ({ type: TOGGLE_HELP_PANEL });
export const toggleNotificationPanel = () => ({ type: TOGGLE_NOTIFICATION_PANEL });
export const toggleProgramSelector = () => ({ type: TOGGLE_PROGRAM_SELECTOR });
export const toggleMonolithicModule = (id: any, opened: boolean) => ({
  type: TOGGLE_MONOLITHIC_MODULE,
  state: { id: id, opened: opened },
});
export const selectProgram = (program: string) => ({
  type: SELECT_PROGRAM,
  state: program,
});
export const permissionsLoaded = (items: Array<any>) => ({
  type: PERMISSIONS_LOADED,
  state: items,
});
export const groupsLoaded = (groups: Array<any>) => ({
  type: GROUPS_LOADED,
  state: groups,
});
export const dashboardsLoaded = (dashboards: Array<any>) => ({
  type: DASHBOARDS_LOADED,
  state: dashboards,
});
export const notificationsRefreshed = (notifications: Array<INotification>) => ({
  type: NOTIFICATIONS_REFRESHED,
  state: notifications,
});
export const refreshNotifications = () => ({ type: REFRESH_NOTIFICATIONS });

export const todoNotificationsFilterChanged = (
  notificationFilter: INotificationFilter
) => ({
  type: TODO_NOTIFICATIONS_FILTER_CHANGED,
  state: notificationFilter,
});

export const requestsNotificationsFilterChanged = (
  notificationFilter: INotificationFilter
) => ({
  type: REQUESTS_NOTIFICATIONS_FILTER_CHANGED,
  state: notificationFilter,
});

export const clearTodoNotificationsFilter = () => ({
  type: CLEAR_TODO_NOTIFICATIONS_FILTER,
});

export const clearRequestsNotificationsFilter = () => ({
  type: CLEAR_REQUESTS_NOTIFICATIONS_FILTER,
});

export const updateUserProfilePicture = (picture: string) => ({
  type: PROFILE_PICTURE_CHANGED,
  state: picture,
});

export const deleteUserProfilePicture = () => ({
  type: PROFILE_PICTURE_REMOVED,
});

export const selectGroup = (group: any) => ({
  type: SELECT_GROUP,
  state: group,
});

export const applyNotificationsFilter = (notificationType: string) => ({
  type: APPLY_NOTIFICATIONS_FILTER,
  state: notificationType,
});

//new actions to handle pagination behavior
export const updateNotifications = (notifications: any) => ({
  type: UPDATE_NOTIFICATIONS,
  payload: notifications,
});

export const refreshTodoCompleted = () => ({
  type: REFRESH_TODO_COMPLETED,
});

export const refreshRequestCompleted = () => ({
  type: REFRESH_REQUEST_COMPLETED,
});

export const refreshAnnouncementCompleted = () => ({
  type: REFRESH_ANNOUNCEMENT_COMPLETED,
});

export const refreshNotificationPaneCompleted = () => ({
  type: REFRESH_NOTIFICATION_PANE_COMPLETED,
});

export const setUnreadCount = (count) => ({
  type: SET_UNREAD_COUNT,
  payload: count,
});

export const updateTodoBlockCompleted = () => ({
  type: UPDATE_TODO_COMPLETED,
});

export const updateReuqestBlockCompleted = () => ({
  type: UPDATE_REQUEST_COMPLETED,
});
export const updateAnnoucementBlockCompleted = () => ({
  type: UPDATE_ANNOUNCEMENT_COMPLETED,
});

export const updateNotPaneBlockCompleted = () => ({
  type: UPDATE_NOT_PANE_COMPLETED,
});
