import React from 'react';
import { Container, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Wrapper } from 'components';
import Welcome from './Welcome/Welcome';
import FullPageMenu from './FullPageMenu/FullPageMenu';
import ModulePageMenu from './FullPageMenu/ModulePageMenu';

// import MyActivity from './MyActivity/MyActivity';
import MyActivityNew from './MyActivity/MyActivityNew';
import AppConfig from 'constants/AppConfig';
import { useAppState } from 'providers/AppProvider';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    minHeight: '100%',
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  const [state] = useAppState();

  document.title = AppConfig.pageTitle;
  return (
    <Wrapper padding>
      <Container className={classes.content}>
        <Welcome></Welcome>
        <FullPageMenu></FullPageMenu>
        <Collapse in={state.group ? true : false}>
          <ModulePageMenu></ModulePageMenu>
        </Collapse>
        {/* <MyActivity></MyActivity> */}
        <MyActivityNew />
      </Container>
    </Wrapper>
  );
};

export default LandingPage;
