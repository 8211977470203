import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  toggleNotificationPanel,
  refreshNotifications,
} from 'providers/AppProvider/ActionCreators';
import {
  AccuvTypography,
  AccuvDrawer,
  AccuvList,
  AccuvListItem,
  AccuvIconButton,
  AccuvStatusMessageBox,
} from '@accuv/shared-components';
import Skeleton from '@material-ui/lab/Skeleton';
import { notificationCenterWidth, topBarHeight } from 'styleVariables';
import { NotificationItems } from './NotificationItems';
import NotificationApi from 'services/Notification/NotificationApi';
import { useToast, ADD } from 'providers/ToastProvider';
import { useAppState } from 'providers/AppProvider';
import useMountEffect from 'hooks/mountEffect';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuItem } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import env from '@beam-australia/react-env';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: notificationCenterWidth,
    maxWidth: notificationCenterWidth,
    top: `${topBarHeight}px !important`,
    height: `calc(100% - ${topBarHeight}px)`,
    zIndex: theme.zIndex.drawer + 99,
  },
  modal: {
    top: `${topBarHeight}px !important`,
    zIndex: theme.zIndex.drawer + 99,
  },
  backdrop: {
    top: `${topBarHeight}px !important`,
  },
  container: {
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
    zIndex: 1,
    flexGrow: 1,
    ...theme.mixins.scrollbar,
  },
  drawerTitle: {
    padding: theme.spacing(1.5, 2),
    borderBottom: `1px solid ${theme.palette.gray.border}`,
  },
  list: {
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  title: {
    display: 'inline',
    width: '50%',
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  skeletonItem: {
    borderBottom: `1px solid ${theme.palette.gray.border}`,
  },
  skeletonContainer: {
    width: '100%',
  },
  skeletonText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  error: {
    top: '40%',
    position: 'absolute',
    width: '100%',
  },
}));

const notificationApi = new NotificationApi();

function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

function NotificationCenter() {
  console.log('NotificationCenter rendering...');
  const classes = useStyles();
  const [state, dispatch] = useAppState();
  const { toastDispatch } = useToast();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showChip, setShowChip] = useState(false);
  const pushNotifications = env('PUSH_NOTIFICATIONS');

  useMountEffect(() => {
    // Fetch user permissions
    if (pushNotifications === 'true') {
      if (!iOS()) {
        Notification.requestPermission().then((permission) => {
          console.log('Notification permission:', permission);
          if (permission === 'granted') {
            setShowChip(false);
          } else {
            setShowChip(true);
          }
        });
      }
    }
  });

  const handleMoreOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreOptionsClose = () => {
    setAnchorEl(null);
  };

  const markNotificationAsRead = (id) => {
    notificationApi
      .markAsRead(id)
      .then((notification) => {
        console.log(`Notification ${notification.id} read!`);
      })
      .catch((error) => {
        console.error(error);
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the notification as read',
            type: 'error',
          },
        });
      });
  };

  const markAllNotificationAsRead = (ids) => {
    notificationApi
      .markAllAsRead(ids)
      .then((notifications) => {
        console.log(`Notifications read!`, notifications);
      })
      .catch((error) => {
        console.error(error);
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error trying to mark the notification as read',
            type: 'error',
          },
        });
      });
  };

  const handleNotificationToggle = () => {
    dispatch(toggleNotificationPanel());
  };

  const handleNotificationItemClick = (notification) => {
    console.log(`Notification click: ${notification.id}`);
    if (notification.read === false) {
      markNotificationAsRead(notification.id);
    }
  };

  const handleMarkAllNotificationsAsRead = () => {
    console.log(`handleMarkAllNotificationsAsRead click`);
    handleMoreOptionsClose();
    const notifications = state.notifications;
    if (Array.isArray(notifications) && notifications.length > 0) {
      const ids = notifications
        .filter((notification) => notification.read === false)
        .map((opt) => opt.id);
      ids.length > 0 && markAllNotificationAsRead(ids);
    }
  };

  const handleRefreshNotifications = () => {
    console.log('NotificationCenter Refresh Notifications click');
    handleMoreOptionsClose();
    dispatch(refreshNotifications());
  };

  const handleChipDelete = () => {
    console.log('NotificationCenter handleChipDelete');
    setShowChip(false);
  };

  let notifications;
  if (!state.notifications) {
    // Show the skeleton if the items are not available yet
    const skeletonItems = [1, 2, 3, 4, 5, 6, 7];
    notifications = (
      <AccuvList className={classes.list}>
        {skeletonItems.map((index) => (
          <AccuvListItem className={classes.skeletonItem} key={index}>
            <AccuvTypography
              component="div"
              variant="caption"
              className={classes.skeletonContainer}
            >
              <Skeleton
                animation="wave"
                height={10}
                width="80%"
                className={classes.skeletonText}
              />
              <Skeleton
                animation="wave"
                height={10}
                width="40%"
                className={classes.skeletonText}
              />
              <Skeleton
                animation="wave"
                height={10}
                width="70%"
                className={classes.skeletonText}
              />
            </AccuvTypography>
          </AccuvListItem>
        ))}
      </AccuvList>
    );
  } else if (state.notifications === 'error') {
    notifications = (
      <div className={classes.error}>
        <AccuvStatusMessageBox>
          An error occurred loading the notifications, please refresh the page
        </AccuvStatusMessageBox>
      </div>
    );
  } else {
    if (state.notifications.length > 0) {
      notifications = (
        <AccuvList className={classes.list}>
          {state.notifications.map((notification, index) => {
            let Notification = NotificationItems(notification.type);

            return (
              <Notification
                key={index}
                notification={notification}
                onClick={() => handleNotificationItemClick(notification)}
                notificationToggleCallback={handleNotificationToggle}
              />
            );
          })}
        </AccuvList>
      );
    } else {
      notifications = (
        <div className={classes.error}>
          <AccuvStatusMessageBox>No New Notification</AccuvStatusMessageBox>
        </div>
      );
    }
  }

  return (
    <AccuvDrawer
      variant="persistent"
      anchor="right"
      open={state.notificationPanelOpened}
      PaperProps={{
        elevation: 8,
      }}
      ModalProps={{
        keepMounted: false,
        className: classes.modal,
        BackdropProps: {
          className: classes.backdrop,
        },
        onBackdropClick: handleNotificationToggle,
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <AccuvTypography className={classes.drawerTitle}>
        <div className={classes.title}>Notifications</div>
        {showChip && (
          <Tooltip
            title={
              <>
                <p>
                  In order to take advantage of the system push notifications, please
                  follow the instructions below:
                </p>
                <h2>For Chrome</h2>
                <ul>
                  <li>
                    Click on the <b>Site Information</b> symbol next to the website URL
                  </li>
                  <li>
                    You should now be able to see a <b>Notifications</b> field saying{' '}
                    <b>Block</b>
                  </li>
                  <li>
                    Select <b>Allow</b> on this site
                  </li>
                </ul>
              </>
            }
            aria-label="add"
          >
            <Chip
              className={classes.chip}
              label="Push notifications disabled"
              variant="outlined"
              color="primary"
              size="small"
              icon={<PriorityHighIcon />}
              onDelete={handleChipDelete}
            />
          </Tooltip>
        )}
      </AccuvTypography>

      <AccuvIconButton
        className={classes.closeButton}
        size="small"
        onClick={handleMoreOptionsClick}
      >
        <MoreVertIcon />
        {state.refreshNotifications && (
          <CircularProgress size={30} className={classes.buttonProgress} />
        )}
      </AccuvIconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMoreOptionsClose}
      >
        <MenuItem onClick={handleMarkAllNotificationsAsRead}>Mark all as read</MenuItem>
        <MenuItem onClick={handleRefreshNotifications}>Refresh</MenuItem>
      </Menu>
      <div className={classes.container}>{notifications}</div>
    </AccuvDrawer>
  );
}

NotificationCenter.propTypes = {};

export default NotificationCenter;
