import React from 'react';
import PropTypes from 'prop-types';
import {
  AccuvListItem,
  AccuvListItemIcon,
  AccuvListItemText,
  AccuvIcon,
} from '@accuv/shared-components';
import { capitalize } from 'utils/helpers';
import { makeStyles } from '@material-ui/core/styles';
import { useAppState } from 'providers/AppProvider';
import { selectProgram } from 'providers/AppProvider/ActionCreators';
import ProgramService from 'services/Program/ProgramService';
import programIconMap from './ProgramIconMap';

const useStyles = makeStyles((theme) => ({
  menuWrapper: {
    flex: '0 0 50%',
  },
  menuIcon: {
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  menuTextWrapper: {
    '& .MuiTypography-root': {
      fontSize: 14,
      lineHeight: '16px',
    },
  },
  menuIconWrapper: {
    minWidth: 28,
  },
  menuItem: {
    minHeight: 44,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 1),
  },
}));

const ProgramItem = ({ program }) => {
  //console.log('ProgramItem rendering...');
  const classes = useStyles();
  const [, dispatch] = useAppState();
  const selectedProgram = ProgramService.getSelectedProgramId();

  const handleClick = (e, programId) => {
    // alert(`Program selected: ${programId} / ${e.currentTarget.textContent}`);
    dispatch(
      selectProgram({
        id: programId,
        name: e.currentTarget.textContent,
      })
    );
  };

  return (
    <div className={classes.menuWrapper}>
      <AccuvListItem
        className={classes.menuItem}
        button
        data-test={program.text}
        onClick={(event) => handleClick(event, program.id)}
        selected={selectedProgram === program.id}
      >
        <AccuvListItemIcon className={(classes.menuIconWrapper, classes.menuIcon)}>
          {programIconMap[program.id] ? (
            <AccuvIcon icon={programIconMap[program.id]} />
          ) : (
            <AccuvIcon icon="default" />
          )}
        </AccuvListItemIcon>
        <AccuvListItemText className={classes.menuTextWrapper}>
          {capitalize(program.text)}
        </AccuvListItemText>
      </AccuvListItem>
    </div>
  );
};

ProgramItem.propTypes = {
  program: PropTypes.object,
};

export default ProgramItem;
