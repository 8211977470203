import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { NotAuthorized } from 'components';
import { NotFound } from 'pages';
import routes from 'routes/Routes';

const RoutesBuilder = () => {
  const routing = (
    <Routes>
      {routes.items.map((item, index) =>
        item.type === 'submenu' ? (
          <Route end path={item.routerPath} element={item.component} key={index}>
            {item.children.map((subItem, subIndex) => (
              <Route
                path={subItem.routerPath}
                element={subItem.component}
                key={subIndex}
              />
            ))}
          </Route>
        ) : (
          <Route path={item.routerPath} element={item.component} key={index} />
        )
      )}
      {/* Render the component inside the workspace */}
      <Route path="/notAuthorized" element={<NotAuthorized />} />
      {/* Navigate to the full page*/}
      <Route path="/*" element={<NotFound />} />
      {/* <Navigate path="*" to="/Error/404" replace /> */}
    </Routes>
  );

  return <>{routing}</>;
};

export default RoutesBuilder;
