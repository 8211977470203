import moment from 'moment';

const date = Date.now();

export const mockNotifications = [
  {
    id: '1',
    type: 'export',
    title: 'Project Management - Jobs Export',
    dateTime: moment(new Date(date - 1000 * 60 * 60)).format('MMMM Do YYYY, h:mm:ss a'),
    read: false,
    userId: 'gabriel.cerutti',
    payload: {
      status: 'running',
    },
  },
  {
    id: '2',
    type: 'export',
    title: 'Project Management - Jobs Export',
    dateTime: moment(new Date(date - 1000 * 60 * 60)).format('MMMM Do YYYY, h:mm:ss a'),
    read: false,
    userId: 'gabriel.cerutti',
    payload: {
      status: 'completed',
      filePath:
        'ProjectManagementNDPATT/NDPATT_MT_gabriel_cerutti_07_29_2020_05_03_31.CSV',
    },
  },
  {
    id: '3',
    type: 'export',
    title: 'Project Management - Jobs Export',
    dateTime: moment(new Date(date - 1000 * 60 * 60)).format('MMMM Do YYYY, h:mm:ss a'),
    read: false,
    userId: 'gabriel.cerutti',
    payload: {
      status: 'failed',
      errorMessage: 'Something went wrong',
      errorDetails: 'Something went wrong bla bla bla',
    },
  },
  {
    id: '4',
    type: 'import',
    title: 'Project Management - Jobs Import',
    dateTime: moment(new Date(date - 1000 * 60 * 60)).format('MMMM Do YYYY, h:mm:ss a'),
    read: false,
    userId: 'gabriel.cerutti',
    payload: {
      status: 'running',
    },
  },
  {
    id: '5',
    type: 'import',
    title: 'Project Management - Jobs Import',
    dateTime: moment(new Date(date - 1000 * 60 * 60)).format('MMMM Do YYYY, h:mm:ss a'),
    read: false,
    userId: 'gabriel.cerutti',
    payload: {
      status: 'error',
    },
  },
  {
    id: '5',
    type: 'import',
    title: 'Project Management - Jobs Import',
    dateTime: moment(new Date(date - 1000 * 60 * 60)).format('MMMM Do YYYY, h:mm:ss a'),
    read: false,
    userId: 'gabriel.cerutti',
    payload: {
      status: 'completed',
    },
  },
  {
    id: '6',
    type: 'announcement',
    title: 'SYSTEM UPDATE',
    dateTime: moment(new Date(date - 1000 * 60 * 60)).format('MMMM Do YYYY, h:mm:ss a'),
    read: true,
    payload: {
      body: 'Scheduled Maintenance for AccuV platform on Fri, May 12, 2020 from 8am to 9am',
      priority: 'low',
    },
  },
  {
    id: '7',
    type: 'announcement',
    title: 'SYSTEM WARNING',
    dateTime: moment(new Date(date - 1000 * 60 * 60)).format('MMMM Do YYYY, h:mm:ss a'),
    read: false,
    payload: {
      body: 'AccuV platform has been upgraded with release version (R 4.19.0)',
      priority: 'medium',
    },
  },
  {
    id: '8',
    type: 'announcement',
    title: 'SYSTEM ALERT',
    dateTime: moment(new Date(date - 1000 * 60 * 60)).format('MMMM Do YYYY, h:mm:ss a'),
    read: true,
    payload: {
      body: 'We have detected a security issue, and all the pásswords have been reset, please contact your manager for more information on resetting your credentials',
      priority: 'high',
    },
  },
  {
    id: '9',
    type: 'pendingApproval',
    title: 'PO Approval: Pending Approval',
    dateTime: '2:26pm',
    read: false,
    payload: {
      jobNumber: 'MTSRA374002 - Home Cost Center',
      vendor: 'Kaseya US LLC',
      amount: '4797.59',
    },
  },
  {
    id: '10',
    type: 'pendingApproval',
    title: 'Closeout Approval: Pending Approval',
    dateTime: '12:45am',
    read: false,
    payload: {
      jobNumber: 'TSLLCS238302-A',
      vendor: 'Kaseya US LLC',
      amount: '12.59',
    },
  },
  {
    id: '11',
    type: 'pendingApproval',
    title: 'VQA Approval: Pending Approval',
    dateTime: '06/19/2020',
    read: true,
    payload: {
      jobNumber: 'SGA95000 - Home Cost Center',
      vendor: 'Kaseya US LLC',
      amount: '4797.59',
    },
  },
  {
    id: '12',
    type: 'pendingApproval',
    title: 'Document Approval: Pending Approval',
    dateTime: '05/27/2020',
    read: true,
    payload: {
      jobNumber: 'MTSRA374002 - Home Cost Center',
      vendor: 'Kaseya US LLC',
      amount: '4797.59',
    },
  },
  {
    id: '13',
    type: 'pendingApproval',
    title: 'VQA Approval: Pending Approval',
    dateTime: '06/19/2020',
    read: true,
    payload: {
      jobNumber: 'SGA95000 - Home Cost Center',
      vendor: 'Kaseya US LLC',
      amount: '4797.59',
    },
  },
  {
    id: '14',
    type: 'pendingApproval',
    title: 'Document Approval: Pending Approval',
    dateTime: '05/27/2020',
    read: true,
    payload: {
      jobNumber: 'MTSRA374002 - Home Cost Center',
      vendor: 'Kaseya US LLC',
      amount: '4797.59',
    },
  },
];
