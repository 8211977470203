import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: `0px ${theme.spacing(1)}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 0 auto',
    flexDirection: 'column',
    minHeight: '100%',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '32px',
    fontWeight: 900,
  },
}));

const MonoFrontendMock = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <Typography className={classes.subtitle}>
        This will be a monolithic-frontend for {props.name}!
      </Typography>
    </div>
  );
};

MonoFrontendMock.propTypes = {
  name: PropTypes.string,
};

export default MonoFrontendMock;
