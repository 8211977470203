import { INotification } from 'typings/notification';
import { ApiBase } from '../API';
import env from '@beam-australia/react-env';

export interface INotificationApi {
  getNotifications: (userId: string, dateTime: Date) => Promise<INotification[]>;
  markAsRead: (id: number) => Promise<INotification>;
  markAsUnread: (id: number) => Promise<INotification>;
  markAllAsRead: (ids: string[]) => Promise<any>;
  AddNewNotification(notifications: any[], notification: any): any[];
  subscribeToPushNotifications: (userDevice: any) => Promise<any>;
}

const notificationServiceBasePath = env('NOTIFICATION_SERVICE_ENDPOINT');

class NotificationApi extends ApiBase implements INotificationApi {
  private readonly notifications: INotification[];

  constructor() {
    super(notificationServiceBasePath);
    this.notifications = [];
  }

  public markAsRead = async (id: any) => {
    return this.setNotificationRead(id, true);
  };

  public markAsUnread = async (id: number) => {
    return this.setNotificationRead(id, false);
  };

  public getNotifications = async (
    userId: string,
    dateTime: Date
  ): Promise<INotification[]> => {
    return this.instance.get<INotification[]>('/api/notifications', {
      params: {
        userId: userId,
        dateTime: dateTime,
      },
    });
  };

  private setNotificationRead = async (id: number, read: boolean) => {
    return this.instance.patch<INotification>(
      `/api/notifications/${id}`,
      [
        {
          op: 'replace',
          path: '/read',
          value: read,
        },
      ],
      {
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
      }
    );
  };

  public markAllAsRead = async (ids: string[]): Promise<any> => {
    return this.instance.put<any>(`/api/Notifications/MarkAsReadBulk`, ids, {
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
    });
  };

  public AddNewNotification = (notifications: any[], notification: any): any[] => {
    const newNotifications = [...notifications];
    const index = notifications.findIndex((n) => n.id === notification.id);
    console.log('new notification id:', notification.id);
    console.log('new notification:', notification);
    if (index !== -1) {
      //if the notification has delete flag and todo type, update
      if (notification.delete && notification.type === 'todo') {
        console.log('notification todo delete:', notification);
        newNotifications[index].payload.status = 'done';
      } else newNotifications[index] = notification;
    } else {
      newNotifications.unshift(notification);
    }
    return newNotifications;
  };

  public subscribeToPushNotifications = async (userDevice: any) => {
    return this.instance.post<void>('/api/PushNotifications/Subscribe', userDevice);
  };

  public getFilteredNotifications = (filterObject) => {
    return this.instance.post<any>('/api/Notifications/GetFilteredNotifications', {
      ...filterObject,
    });
  };

  public getUnreadNotificationsCount = () => {
    return this.instance.post<any>('/api/Notifications/GetUnreadNotificationsCount');
  };
}

export default NotificationApi;
