import React from 'react';
import PropTypes from 'prop-types';
import {
  AccuvList,
  AccuvListItem,
  AccuvListItemText,
  AccuvStatusMessageBox,
} from '@accuv/shared-components';
import { capitalize } from 'utils/helpers';
import { makeStyles } from '@material-ui/core/styles';
import { hasAccessToModule } from 'services/Authorization/UserAccess';
import Skeleton from '@material-ui/lab/Skeleton';
import SubmenuItem from './SubmenuItem';

const useStyles = makeStyles((theme) => ({
  gridList: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(2),
  },
  gridListTitle: {
    '& .mainspa-MuiTypography-root': {
      fontSize: 18,
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
  },
  menuLink: {
    flex: '0 0 50%',
  },
  menuSubItemSkeleton: {
    minHeight: 48,
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0.5, 1),
  },
}));

const SidebarSubmenu = ({ route, permissions, toggleMainMenu, groups }) => {
  console.log('SidebarSubmenu rendering...');

  const classes = useStyles();

  if (!permissions) {
    const skeletonItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    return (
      <div>
        <AccuvListItem data-test={route.name}>
          <AccuvListItemText className={classes.gridListTitle}>
            {capitalize(route.name)}
          </AccuvListItemText>
        </AccuvListItem>
        <AccuvList className={classes.gridList}>
          {skeletonItems.map((subitem, index) => (
            <div className={classes.menuLink} key={index}>
              <Skeleton
                variant="rect"
                key={index}
                className={classes.menuSubItemSkeleton}
              />
            </div>
          ))}
        </AccuvList>
      </div>
    );
  } else if (permissions === 'error') {
    return (
      <div className={classes.error}>
        <AccuvStatusMessageBox>
          An error occurred, please try again.
        </AccuvStatusMessageBox>
      </div>
    );
  }

  return (
    <div>
      <AccuvListItem data-test={route.name}>
        <AccuvListItemText className={classes.gridListTitle}>
          Applications
        </AccuvListItemText>
      </AccuvListItem>
      <AccuvList className={classes.gridList}>
        {route.children.map((subitem, index) => {
          let fullRoute = `/${route.path ? route.path : ''}/${
            subitem.path ? subitem.path : ''
          }`;
          if (subitem.id === -1) {
            let dashboard = groups.find((g) => g.Id === -1);
            if (!dashboard) subitem.display = false;
          }
          if (subitem.display && hasAccessToModule(subitem.id, permissions)) {
            return (
              <SubmenuItem
                key={index}
                subItem={subitem}
                fullRoute={fullRoute}
                toggleMainMenu={toggleMainMenu}
              ></SubmenuItem>
            );
          } else {
            return null;
          }
        })}
      </AccuvList>
    </div>
  );
};

SidebarSubmenu.propTypes = {
  route: PropTypes.object,
  permissions: PropTypes.any,
  toggleMainMenu: PropTypes.func,
  groups: PropTypes.any,
};

export default React.memo(SidebarSubmenu);
