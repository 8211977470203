import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AccuvListItem,
  AccuvListItemIcon,
  AccuvListItemText,
  AccuvTypography,
  AccuvIcon,
} from '@accuv/shared-components';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { truncateText } from 'utils/helpers';
import { Apps } from '../../../../constants/Apps';
import { useAppState } from '../../../../providers/AppProvider';
import { selectProgram } from '../../../../providers/AppProvider/ActionCreators';

const useStyles = makeStyles((theme) => ({
  listItem: {
    background: '#F0F1F8',
    borderBottom: `1px solid ${theme.palette.gray.border}`,
  },
  lastListItem: {
    borderBottom: 'none',
  },
  listItemIconWrapper: {
    minWidth: 40,
  },
  colorPrimary: {
    color: theme.palette.secondary.main,
  },
  colorSecondary: {
    color: theme.palette.primary.alt,
  },
  colorGray: {
    color: theme.palette.gray.text,
  },
  colorAccent: {
    color: theme.palette.accent.main,
  },
  inlineFields: {
    display: 'flex',
  },
  listItemText: {
    '& .mainspa-MuiListItemText-primary': {
      fontSize: 12,
      color: theme.palette.gray.text,
      fontWeight: 'bold',
    },
    '& .mainspa-MuiListItemText-secondary': {
      fontSize: 12,
      color: theme.palette.gray.text,
    },
  },
  listItemTextRead: {
    '& .mainspa-MuiListItemText-primary': {
      fontWeight: 'normal',
    },
    '& .mainspa-MuiListItemText-secondary': {
      color: theme.palette.gray.light,
    },
  },
  listItemRead: {
    background: theme.palette.white,
    '& .mainspa-MuiListItemText-primary': {
      fontWeight: 'normal',
    },
  },
  listItemLeft: {
    flex: '1 1 auto',
  },
  listItemRight: {
    marginLeft: 'auto',
    paddingLeft: theme.spacing(1),
    textAlign: 'right',
    flex: '0 1 auto',
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.gray.text,
    flex: '1 1 auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    fontSize: 12,
    marginRight: theme.spacing(0.5),
    flex: '0 0 auto',
  },
  value: {
    fontSize: 12,
    color: theme.palette.gray.text,
    marginRight: theme.spacing(1),
    flex: '0 1 auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  date: {
    fontSize: 10,
    color: theme.palette.gray.text,
    marginLeft: theme.spacing(1),
    minWidth: 60,
  },
}));

function getIconColorClass(color, classes) {
  switch (color) {
    case 'primary':
      return classes.colorPrimary;
    case 'secondary':
      return classes.colorSecondary;
    case 'accent':
      return classes.colorAccent;
    case 'gray':
      return classes.colorGray;
    default:
      return classes.colorGray;
  }
}

const ToDo = ({ todo, onClick, index, todosLength }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [state, dispatch] = useAppState();
  return (
    <AccuvListItem
      button
      key={index}
      className={clsx(
        classes.listItem,
        todo.read && classes.listItemRead,
        index === todosLength - 1 && classes.lastListItem
      )}
      onClick={() => {
        onClick();
        console.log('Todo click');
        console.log(todo);
        if (todo.payload.route) {
          const programId = todo.payload.program;
          const programName = state.permissions[Apps.Ids.POWA][todo.payload.program];
          dispatch(
            selectProgram({
              id: programId,
              name: programName,
            })
          );
          const poNumber = todo.payload.poNumber ? todo.payload.poNumber : '';
          navigate(`${todo.payload.route}?poNumber=${poNumber}`);
        }
      }}
    >
      <AccuvListItemIcon
        className={clsx(
          classes.listItemIconWrapper,
          getIconColorClass(todo.payload.iconColor, classes)
        )}
      >
        {todo.payload.type ? (
          <AccuvIcon icon={todo.payload.type} className={classes.listItemIcon} />
        ) : (
          <AccuvIcon icon="administration" className={classes.listItemIcon} />
        )}
      </AccuvListItemIcon>
      <AccuvListItemText
        className={clsx(
          classes.listItemText,
          todo.read === true && classes.listItemTextRead
        )}
        primary={todo.title}
        secondary={truncateText(todo.payload.description, 130, true)}
      ></AccuvListItemText>
      <div className={classes.listItemRight}>
        <AccuvTypography className={classes.date}>
          <Moment format="lll">{todo.dateTime}</Moment>
        </AccuvTypography>
      </div>
    </AccuvListItem>
  );
};

ToDo.propTypes = {
  todo: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    dateTime: PropTypes.string,
    read: PropTypes.bool,
    payload: PropTypes.object,
  }),
  onClick: PropTypes.func,
  index: PropTypes.number,
  todosLength: PropTypes.number,
};

export default React.memo(ToDo);
