import NotificationTypes from 'constants/NotificationTypes';

export default class NotificationService {
  static getFilteredNotifications = (notifications: any[], filter: any, type: string) => {
    try {
      var not = notifications.filter(
        (n) =>
          (type == NotificationTypes.TODO
            ? n.type === NotificationTypes.TODO
            : n.type === NotificationTypes.IMPORT ||
              n.type === NotificationTypes.EXPORT) &&
          filter.programs.filter(
            (p: { programId: string }) => p.programId == n.payload.program
          ).length > 0
      );
      return not;
    } catch (ex) {
      console.log('ex', ex);
      return [];
    }
  };
}
