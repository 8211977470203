import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { AccuvListItem, AccuvTypography } from '@accuv/shared-components';

const useStyles = makeStyles((theme) => ({
  skeletonItem: {
    borderBottom: `1px solid ${theme.palette.gray.border}`,
    minHeight: '50px',
  },
  skeletonContainer: {
    width: '100%',
  },
  skeletonText: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

const RequestsSkeleton = () => {
  const classes = useStyles();
  let skeletonItems = [1, 2, 3, 4, 5, 6].map((index) => (
    <AccuvListItem className={classes.skeletonItem} key={index}>
      <AccuvTypography
        component="div"
        variant="caption"
        className={classes.skeletonContainer}
      >
        <Skeleton
          animation="wave"
          height={10}
          width="80%"
          className={classes.skeletonText}
        />
        <Skeleton
          animation="wave"
          height={10}
          width="40%"
          className={classes.skeletonText}
        />
      </AccuvTypography>
    </AccuvListItem>
  ));

  return <>{skeletonItems}</>;
};

export default RequestsSkeleton;
