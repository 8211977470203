/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
// Components
import { PowerBiDashboard, PowerBiDashboardMenu } from 'components';
import LandingPage from 'containers/Home/LandingPage';
// import ModulePageMenu from 'containers/Home/FullPageMenu/ModulePageMenu';
import AppsPanel from 'containers/Apps/AppsPanel';
import { Apps } from 'constants/Apps';
import {
  ProjectManagement,
  CostCenterManagement,
  FieldServiceManagement,
  ScopeManagement,
  CloseoutManagement,
  AdminCenter,
  WorkOrderManagement,
  UserManagement,
  LogisticsManagement,
  SiteCandidateManagement,
  DocumentManagement,
  DailyFieldReport,
  JobSafetyAnalysis,
  PoApproval,
  CloseoutApproval,
  DashboardManagement,
  ClientInvoiceManagement,
  ClientInvoiceManagementPurchaseOrder,
  ClientInvoiceManagementInvoice,
  ClientInvoiceManagementSubmitInvoice,
  VendorCreation,
  VendorInvoiceManagement,
  VendorInvoiceManagementPO,
  VendorInvoiceManagementInvoice,
  VendorInvoiceManagementSubmitInvoice,
  VendorInvoiceManagementVendorPortal,
  SupplyChain,
  CrewForemanAssignment,
  AssignSites,
  FieldServiceOperations,
  UserLogout,
  NotificationCenter,
  MicroScopeManagement,
  FieldServiceOperationsQuestionnaire,
  Workflow,
} from './Apps';
import {
  ProjectManagementImportResults,
  CosCenterManagementImportResults,
  ScopeManagementImportResults,
  ClientInvoiceManagementImportResults,
  ItemManagementImportResults,
  WorkOrderImportResults,
} from 'components/BulkImport/ImportResults';
// Icons
import { Home } from '@material-ui/icons';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import WorkflowIcon from 'components/Icons/Workflow';

const Dashboard = () => <PowerBiDashboard></PowerBiDashboard>;

const DashboardMenu = () => (
  <PowerBiDashboardMenu id={Apps.Ids.NO_SEC}></PowerBiDashboardMenu>
);

const routes = {
  items: [
    {
      routerPath: '/',
      path: '/',
      name: 'Home',
      type: 'homeLink',
      icon: Home,
      display: true,
      component: <LandingPage></LandingPage>,
    },
    {
      routerPath: 'apps',
      path: 'apps',
      name: 'Apps',
      type: 'submenu',
      icon: WidgetsOutlinedIcon,
      children: [
        {
          routerPath: '',
          path: '',
          name: 'Apps',
          icon: WidgetsOutlinedIcon,
          display: false,
          component: <AppsPanel></AppsPanel>,
        },
        {
          id: Apps.Ids.PM,
          type: 'mono',
          routerPath: 'projectManagement',
          path: 'projectManagement',
          name: 'Schedule',
          icon: WidgetsOutlinedIcon,
          iconId: 'project-management',
          display: true,
          component: <ProjectManagement></ProjectManagement>,
        },
        {
          id: Apps.Ids.PM,
          type: 'mono',
          routerPath: 'projectManagement/:jobSid',
          path: 'projectManagement',
          name: 'Schedule',
          icon: WidgetsOutlinedIcon,
          iconId: 'project-management',
          display: false,
          component: <ProjectManagement></ProjectManagement>,
        },
        {
          id: Apps.Ids.PM,
          type: 'mono',
          routerPath: 'projectManagement/jobsImportResults/:id',
          path: 'projectManagement/jobsImportResults',
          name: 'Project Management',
          icon: WidgetsOutlinedIcon,
          iconId: 'project-management',
          display: false,
          component: (
            <ProjectManagementImportResults
              standalone={true}
            ></ProjectManagementImportResults>
          ),
        },
        {
          id: Apps.Ids.SCOPM,
          type: 'mono',
          routerPath: 'scopeManagement',
          path: 'scopeManagement',
          name: 'Scope',
          icon: WidgetsOutlinedIcon,
          iconId: 'scope-management',
          display: true,
          component: <ScopeManagement></ScopeManagement>,
        },
        {
          id: Apps.Ids.SCOPM,
          type: 'mono',
          routerPath: 'scopeManagement/ScopeManagementImportResults/:id',
          path: 'scopeManagement/ScopeManagementImportResults',
          name: 'Scope Management',
          icon: WidgetsOutlinedIcon,
          iconId: 'project-management',
          display: false,
          component: (
            <ScopeManagementImportResults
              standalone={true}
            ></ScopeManagementImportResults>
          ),
        },
        {
          id: Apps.Ids.WO,
          type: 'mono',
          routerPath: 'workOrder/WorkOrderImportResults/:id',
          path: 'workOrder/WorkOrderImportResults',
          name: 'Work Order',
          icon: WidgetsOutlinedIcon,
          iconId: 'project-management',
          display: false,
          component: <WorkOrderImportResults standalone={true}></WorkOrderImportResults>,
        },
        {
          id: Apps.Ids.CCM,
          type: 'mono',
          routerPath: 'costCenterManagement',
          path: 'costCenterManagement',
          name: 'Cost Center and Job',
          icon: WidgetsOutlinedIcon,
          iconId: 'cost-center-management',
          display: true,
          component: <CostCenterManagement></CostCenterManagement>,
        },
        {
          id: Apps.Ids.CCM,
          type: 'mono',
          routerPath: 'costCenterManagement/costCenterManagementImportResults/:id',
          path: 'costCenterManagement/costCenterManagementImportResults',
          name: 'Cost Center Management',
          icon: WidgetsOutlinedIcon,
          iconId: 'cost-center-management',
          display: false,
          component: (
            <CosCenterManagementImportResults
              standalone={true}
            ></CosCenterManagementImportResults>
          ),
        },
        {
          id: Apps.Ids.SCM,
          type: 'mono',
          routerPath: 'siteCandidateManagement',
          path: 'siteCandidateManagement',
          name: 'Site Candidate',
          icon: WidgetsOutlinedIcon,
          iconId: 'site-candidate-management',
          display: true,
          component: <SiteCandidateManagement></SiteCandidateManagement>,
        },
        {
          id: Apps.Ids.DM,
          type: 'mono',
          routerPath: 'documentManagement',
          path: 'documentManagement',
          name: 'Document Library',
          icon: WidgetsOutlinedIcon,
          iconId: 'deliverable-management',
          display: true,
          component: <DocumentManagement></DocumentManagement>,
        },
        {
          id: Apps.Ids.CIM,
          type: 'mono',
          routerPath: 'clientManagement',
          path: 'clientManagement',
          name: 'Client Invoice',
          icon: WidgetsOutlinedIcon,
          iconId: 'client-management',
          display: true,
          component: <ClientInvoiceManagement></ClientInvoiceManagement>,
        },
        {
          id: Apps.Ids.CIM,
          type: 'mono',
          routerPath: 'clientManagement/submitInvoice',
          path: 'clientManagement/submitInvoice',
          name: 'Client Invoice Management Submit Invoice',
          icon: WidgetsOutlinedIcon,
          iconId: 'client-management',
          display: false,
          component: (
            <ClientInvoiceManagementSubmitInvoice></ClientInvoiceManagementSubmitInvoice>
          ),
        },
        {
          id: Apps.Ids.CIM,
          type: 'mono',
          routerPath: 'clientManagement/submitInvoice/SubmitInvoiceImportResults/:id',
          path: 'clientManagement/submitInvoice/SubmitInvoiceImportResults',
          name: 'Client Invoice Management Submit Invoice',
          icon: WidgetsOutlinedIcon,
          iconId: 'client-management',
          display: false,
          component: (
            <ClientInvoiceManagementImportResults
              standalone={true}
            ></ClientInvoiceManagementImportResults>
          ),
        },
        {
          id: Apps.Ids.CIM,
          type: 'mono',
          routerPath: 'clientManagement/purchaseOrders',
          path: 'clientManagement/purchaseOrders',
          name: 'Client Invoice Management - Purchase Orders',
          icon: WidgetsOutlinedIcon,
          iconId: 'client-management',
          display: false,
          component: (
            <ClientInvoiceManagementPurchaseOrder></ClientInvoiceManagementPurchaseOrder>
          ),
        },
        {
          id: Apps.Ids.CIM,
          type: 'mono',
          routerPath: 'clientManagement/invoices',
          path: 'clientManagement/invoices',
          name: 'Client Invoice Management - Invoices',
          icon: WidgetsOutlinedIcon,
          iconId: 'client-management',
          display: false,
          component: <ClientInvoiceManagementInvoice></ClientInvoiceManagementInvoice>,
        },
        {
          id: Apps.Ids.VI,
          type: 'mono',
          routerPath: 'vendorCreation',
          path: 'vendorCreation',
          name: 'Vendor Creation',
          icon: WidgetsOutlinedIcon,
          iconId: 'vendor-management',
          display: false,
          component: <VendorCreation></VendorCreation>,
        },
        {
          id: Apps.Ids.VI,
          type: 'mono',
          routerPath: 'vendorManagement',
          path: 'vendorManagement',
          name: 'Vendor Portal',
          icon: WidgetsOutlinedIcon,
          iconId: 'vendor-management',
          display: true,
          component: <VendorInvoiceManagement></VendorInvoiceManagement>,
        },
        {
          id: Apps.Ids.VI,
          type: 'mono',
          routerPath: 'vendorManagement/invoices',
          path: 'vendorManagement/invoices',
          name: 'Vendor Management Invoices',
          icon: WidgetsOutlinedIcon,
          iconId: 'vendor-management',
          display: false,
          component: <VendorInvoiceManagementInvoice></VendorInvoiceManagementInvoice>,
        },
        {
          id: Apps.Ids.VI,
          type: 'mono',
          routerPath: 'vendorManagement/submitInvoice',
          path: 'vendorManagement/submitInvoice',
          name: 'Vendor Management',
          icon: WidgetsOutlinedIcon,
          iconId: 'vendor-management',
          display: false,
          component: (
            <VendorInvoiceManagementSubmitInvoice></VendorInvoiceManagementSubmitInvoice>
          ),
        },
        {
          id: Apps.Ids.VI,
          type: 'mono',
          routerPath: 'vendorManagement/vendorProfile',
          path: 'vendorManagement/vendorProfile',
          name: 'Vendor Portal',
          icon: WidgetsOutlinedIcon,
          iconId: 'vendor-management',
          display: false,
          component: (
            <VendorInvoiceManagementVendorPortal></VendorInvoiceManagementVendorPortal>
          ),
        },
        {
          id: Apps.Ids.VI,
          type: 'mono',
          routerPath: 'vendorManagement/purchaseOrders',
          path: 'vendorManagement/purchaseOrders',
          name: 'Vendor Management',
          icon: WidgetsOutlinedIcon,
          iconId: 'vendor-management',
          display: false,
          component: <VendorInvoiceManagementPO></VendorInvoiceManagementPO>,
        },
        {
          id: Apps.Ids.VI,
          type: 'mono',
          routerPath: 'vendorManagement/purchaseOrders/:poNumber',
          path: 'vendorManagement/purchaseOrders',
          name: 'Vendor Management',
          icon: WidgetsOutlinedIcon,
          iconId: 'vendor-management',
          display: false,
          component: <VendorInvoiceManagementPO></VendorInvoiceManagementPO>,
        },
        {
          id: Apps.Ids.CM,
          type: 'mono',
          routerPath: 'closeoutManagement',
          path: 'closeoutManagement',
          name: 'Closeout',
          icon: WidgetsOutlinedIcon,
          iconId: 'closeout-management',
          display: true,
          component: <CloseoutManagement></CloseoutManagement>,
        },
        {
          id: Apps.Ids.WO,
          type: 'mono',
          routerPath: 'workOrder',
          path: 'workOrder',
          name: 'Work Order',
          icon: WidgetsOutlinedIcon,
          iconId: 'work-order',
          display: true,
          component: <WorkOrderManagement></WorkOrderManagement>,
        },
        {
          id: Apps.Ids.LM,
          type: 'mono',
          routerPath: 'logisticsManagement',
          path: 'logisticsManagement',
          name: 'Logistics',
          icon: WidgetsOutlinedIcon,
          iconId: 'logistics-management',
          display: true,
          component: <LogisticsManagement></LogisticsManagement>,
        },
        {
          id: Apps.Ids.DFR,
          type: 'mono',
          routerPath: 'dailyFieldReport',
          path: 'dailyFieldReport',
          name: 'Daily Field Report',
          icon: WidgetsOutlinedIcon,
          iconId: 'daily-field-report',
          display: true,
          component: <DailyFieldReport></DailyFieldReport>,
        },
        {
          id: Apps.Ids.DFR,
          type: 'mono',
          routerPath: 'jobSafetyAnalysis',
          path: 'jobSafetyAnalysis',
          name: 'Job Safety Analysis',
          icon: WidgetsOutlinedIcon,
          iconId: 'job-safety-analysis',
          display: true,
          component: <JobSafetyAnalysis></JobSafetyAnalysis>,
        },
        {
          id: Apps.Ids.FSM,
          type: 'mono',
          routerPath: 'fieldServiceManagement',
          path: 'fieldServiceManagement',
          name: 'Field Service',
          icon: WidgetsOutlinedIcon,
          iconId: 'field-service-management',
          display: true,
          component: <FieldServiceManagement></FieldServiceManagement>,
        },
        {
          id: Apps.Ids.POWA,
          type: 'mono',
          routerPath: 'poApproval',
          path: 'poApproval',
          name: 'PO Approval (Mine)',
          icon: WidgetsOutlinedIcon,
          iconId: 'purchase-orders',
          display: true,
          component: <PoApproval kpiValue={'POWaitingForMyApproval'}></PoApproval>,
        },
        {
          id: Apps.Ids.POWA,
          type: 'mono',
          routerPath: 'poApproval/:poNumber',
          path: 'poApproval',
          name: 'PO Approval (Mine)',
          icon: WidgetsOutlinedIcon,
          iconId: 'purchase-orders',
          display: false,
          component: <PoApproval kpiValue={'POWaitingForApproval'}></PoApproval>,
        },
        {
          id: Apps.Ids.POWA,
          type: 'mono',
          routerPath: 'poApprovalAll',
          path: 'poApprovalAll',
          name: 'PO Approval (All)',
          icon: WidgetsOutlinedIcon,
          iconId: 'purchase-orders',
          display: true,
          component: <PoApproval kpiValue={'POWaitingForApproval'}></PoApproval>,
        },
        {
          id: Apps.Ids.POWA,
          type: 'mono',
          routerPath: 'poAll',
          path: 'poAll',
          name: 'PO (ALL)',
          icon: WidgetsOutlinedIcon,
          iconId: 'purchase-orders',
          display: true,
          component: <PoApproval kpiValue={'AllPOs'}></PoApproval>,
        },
        {
          id: Apps.Ids.POWA,
          type: 'mono',
          routerPath: 'closeoutApproval',
          path: 'closeoutApproval',
          name: 'Closeout Cost Approval (Mine)',
          icon: WidgetsOutlinedIcon,
          iconId: 'closeout-management',
          display: true,
          component: (
            <CloseoutApproval
              kpiValue={'CloseoutWaitingForMyApproval'}
            ></CloseoutApproval>
          ),
        },
        {
          id: Apps.Ids.POWA,
          type: 'mono',
          routerPath: 'closeoutApprovalAll',
          path: 'closeoutApprovalAll',
          name: 'Closeout Cost Approval (All)',
          icon: WidgetsOutlinedIcon,
          iconId: 'closeout-management',
          display: true,
          component: <CloseoutApproval kpiValue={'CloseoutAll'}></CloseoutApproval>,
        },
        {
          id: Apps.Ids.VI,
          type: 'mono',
          routerPath: 'purchaseOrder/:ponumber',
          path: 'purchaseOrder',
          name: 'Purchase Order',
          icon: WidgetsOutlinedIcon,
          iconId: 'purchase-orders',
          display: false,
          component: <PoApproval kpiValue={'POWaitingForMyApproval'}></PoApproval>,
        },
        {
          id: Apps.Ids.UAM,
          type: 'micro',
          routerPath: 'adminCenter/*',
          path: 'adminCenter',
          name: 'Admin Center',
          icon: WidgetsOutlinedIcon,
          iconId: 'administration',
          display: true,
          component: <AdminCenter></AdminCenter>,
        },
        {
          id: Apps.Ids.SCMIM,
          type: 'micro',
          routerPath: 'supplyChain/main/*',
          path: 'supplyChain/main',
          name: 'Supply Chain 2.0',
          icon: WidgetsOutlinedIcon,
          iconId: 'supply-chain',
          display: true,
          component: <SupplyChain></SupplyChain>,
        },
        {
          id: Apps.Ids.FSO,
          type: 'micro',
          routerPath: 'fieldServiceOperations/*',
          path: 'fieldServiceOperations',
          name: 'Field Service Operation 2.0',
          icon: WidgetsOutlinedIcon,
          iconId: 'field-service-management',
          display: true,
          component: <FieldServiceOperations></FieldServiceOperations>,
        },
        {
          id: Apps.Ids.WFL,
          type: 'micro',
          routerPath: 'workflow/*',
          path: 'workflow',
          name: 'Workflow',
          icon: WorkflowIcon,
          display: true,
          component: <Workflow></Workflow>,
        },
        {
          id: Apps.Ids.FSOQUESTIONNAIRE,
          type: 'micro',
          routerPath: 'questionnaire/*',
          path: 'questionnaire',
          name: 'Questionnaire',
          icon: WidgetsOutlinedIcon,
          iconId: 'field-service-management',
          display: true,
          component: (
            <FieldServiceOperationsQuestionnaire></FieldServiceOperationsQuestionnaire>
          ),
        },
        {
          id: Apps.Ids.SCMIM,
          type: 'mono',
          routerPath: 'supplyChain/main/itemManagementImportResult/:id',
          path: 'supplyChain/main/itemManagementImportResult',
          name: 'Supply Chain',
          icon: WidgetsOutlinedIcon,
          iconId: 'supply-chain',
          display: false,
          component: (
            <ItemManagementImportResults standalone={true}></ItemManagementImportResults>
          ),
        },
        {
          id: Apps.Ids.USRM,
          type: 'mono',
          routerPath: 'userManagement',
          path: 'userManagement',
          name: 'User Access',
          icon: WidgetsOutlinedIcon,
          iconId: 'administration',
          display: true,
          component: <UserManagement></UserManagement>,
        },
        {
          id: Apps.Ids.USRM,
          type: 'mono',
          routerPath: 'userLogout',
          path: 'userLogout',
          name: 'User Logout',
          display: false,
          component: <UserLogout></UserLogout>,
        },
        {
          id: Apps.Ids.CFA,
          type: 'mono',
          routerPath: 'CrewForemanAssignment',
          path: 'CrewForemanAssignment',
          name: 'Crew Foreman Assignment',
          icon: WidgetsOutlinedIcon,
          iconId: 'administration',
          display: true,
          component: <CrewForemanAssignment></CrewForemanAssignment>,
        },
        {
          id: Apps.Ids.PBI,
          type: 'mono',
          routerPath: 'dashboards/management',
          path: 'dashboards/management',
          name: 'Dashboard Access',
          icon: WidgetsOutlinedIcon,
          iconId: 'administration',
          display: true,
          component: <DashboardManagement></DashboardManagement>,
        },
        {
          id: Apps.Ids.NO_SEC,
          type: 'other',
          routerPath: 'dashboards/:programId/:reportType/:reportId',
          path: 'dashboards',
          name: 'Dashboards',
          display: false,
          component: <Dashboard></Dashboard>,
        },
        {
          id: Apps.Ids.NO_SEC,
          type: 'other',
          routerPath: 'dashboards',
          path: 'dashboards',
          name: 'Dashboards',
          icon: WidgetsOutlinedIcon,
          iconId: 'dashboards',
          display: true,
          component: <DashboardMenu></DashboardMenu>,
        },
        {
          id: Apps.Ids.PM,
          type: 'mono',
          routerPath: 'assignSites',
          path: 'assignSites',
          name: 'assignSites',
          icon: WidgetsOutlinedIcon,
          iconId: 'closeout-management',
          display: false,
          component: <AssignSites></AssignSites>,
        },
        {
          id: Apps.Ids.SCOP2,
          type: 'micro',
          routerPath: 'microScopeManagement/*',
          path: 'microScopeManagement',
          name: 'Scope 2.0',
          icon: WidgetsOutlinedIcon,
          iconId: 'scope-management',
          display: true,
          component: <MicroScopeManagement></MicroScopeManagement>,
        },
        {
          id: Apps.Ids.NC,
          type: 'micro',
          routerPath: 'notificationCenter/*',
          path: 'notificationCenter',
          name: 'Notification Center',
          icon: WidgetsOutlinedIcon,
          iconId: 'administration',
          display: false,
          component: <NotificationCenter></NotificationCenter>,
        },
      ],
    },
    // {
    //   routerPath: 'dashboards',
    //   path: 'dashboards',
    //   name: 'Dashboards',
    //   type: 'submenu',
    //   icon: WidgetsOutlinedIcon,
    //   iconId: 'dashboards',
    //   display: true,
    //   children: [
    //     {
    //       id: Apps.Ids.PBI,
    //       type: 'mono',
    //       routerPath: 'management',
    //       path: 'management',
    //       name: 'Dashboard Management',
    //       icon: WidgetsOutlinedIcon,
    //       iconId: 'administration',
    //       display: true,
    //       component: <DashboardManagement></DashboardManagement>,
    //     },
    //     {
    //       id: Apps.Ids.NO_SEC,
    //       type: 'other',
    //       routerPath: '',
    //       path: '',
    //       name: 'Dashboards',
    //       icon: WidgetsOutlinedIcon,
    //       iconId: 'dashboards',
    //       display: true,
    //       component: <DashboardMenu></DashboardMenu>,
    //     },
    //     {
    //       id: Apps.Ids.NO_SEC,
    //       type: 'other',
    //       routerPath: ':programId/:reportType/:reportId',
    //       path: '',
    //       name: 'Dashboards',
    //       display: false,
    //       component: <Dashboard></Dashboard>,
    //     },
    //   ],
    // },

    //#region Menu samples

    // {
    //   name: 'Errors',
    //   type: 'submenu',
    //   icon: WidgetsOutlinedIcon,
    //   children: [
    //     {
    //       routerPath: '/404',
    //       name: '404',
    //       display: true,
    //       icon: WidgetsOutlinedIcon,
    //       component: <NotFound></NotFound>,
    //     },
    //     {
    //       routerPath: '/500',
    //       name: 'Error',
    //       display: true,
    //       icon: WidgetsOutlinedIcon,
    //       component: <BackendError></BackendError>,
    //     },
    //   ],
    // },
    // {
    //   routerPath: '/',
    //   path: '/',
    //   name: 'A Link',
    //   type: 'link',
    //   icon: WidgetsOutlinedIcon,
    //   display: true,
    //   component: <LandingPage></LandingPage>,
    // },
    // {
    //   routerPath: '/',
    //   path: '/',
    //   name: 'An external Link',
    //   type: 'External',
    //   icon: WidgetsOutlinedIcon,
    //   display: true,
    //   component: <LandingPage></LandingPage>,
    // },
    // {
    //   routerPath: '/',
    //   path: '/',
    //   name: 'Another Link',
    //   type: 'External',
    //   icon: WidgetsOutlinedIcon,
    //   display: true,
    //   component: <LandingPage></LandingPage>,
    // },

    //#endregion Menu samples
  ],
};

export default routes;

export function getAppsRoutes() {
  let apps = routes.items.filter((route) => {
    return route.name === 'Apps';
  });
  if (apps.length === 0) throw new Error('Apps routes not found in the configuration');
  let orderedApps = apps[0].children.sort((a, b) => a.name.localeCompare(b.name));
  return orderedApps;
}

export function getDashboardsRoutes() {
  let apps = routes.items.filter((route) => {
    return route.name === 'Dashboards';
  });
  if (apps.length === 0)
    throw new Error('Dashboards routes not found in the configuration');
  return apps[0].children;
}

export function getAllRoutes() {
  let apps = routes.items.filter((route) => {
    return route.name === 'Apps';
  });
  if (apps.length > 0) apps[0].children.sort((a, b) => a.name.localeCompare(b.name));
  return routes;
}
