/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AccuvListItem,
  AccuvListItemText,
  AccuvTypography,
} from '@accuv/shared-components';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: `1px solid ${theme.palette.gray.border}`,
    background: '#F0F1F8',
  },
  listItemRead: {
    background: theme.palette.white,
  },
  notificationTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  notificationBody: {
    fontSize: 12,
    color: theme.palette.gray.light,
  },
  notificationTitleRead: {
    fontWeight: 'normal',
  },
  date: {
    fontSize: 10,
    color: theme.palette.gray.text,
    textAlign: 'right',
  },
}));

const GenericNotification = ({ notification, onClick, notificationToggleCallback }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <AccuvListItem
      button
      className={clsx(
        classes.listItem,
        notification.read === true && classes.listItemRead
      )}
      onClick={() => {
        onClick();
        if (notification.payload.route) {
          navigate(notification.payload.route);
          notificationToggleCallback();
        }
        console.log('Generic notification click');
      }}
    >
      <AccuvListItemText>
        <AccuvTypography
          className={clsx(
            classes.notificationTitle,
            notification.read === true && classes.notificationTitleRead
          )}
        >
          {notification.title}
        </AccuvTypography>
        <AccuvTypography className={classes.notificationBody}>
          {notification.payload.description}
        </AccuvTypography>
        <AccuvTypography className={classes.date}>
          <Moment fromNow>{notification.dateTime}</Moment>
        </AccuvTypography>
      </AccuvListItemText>
    </AccuvListItem>
  );
};

GenericNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    dateTime: PropTypes.string,
    read: PropTypes.bool,
    payload: PropTypes.object,
  }),
  onClick: PropTypes.func,
  notificationToggleCallback: PropTypes.func,
};

export default GenericNotification;
