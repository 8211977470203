import React, { useContext, useState, memo, useCallback } from 'react';
import { AuthContext } from 'providers/AuthProvider';
import ProgramService from 'services/Program/ProgramService';
import { useAppState } from 'providers/AppProvider';
import {
  toggleMainMenu,
  toggleUserPanel,
  toggleHelpPanel,
  toggleNotificationPanel,
  toggleProgramSelector,
} from 'providers/AppProvider/ActionCreators';
import { getInitials } from 'utils/helpers';
import clsx from 'clsx';
import {
  AccuvAppBar,
  AccuvIconButton,
  AccuvAvatar,
  AccuvToolbar,
  AccuvButton,
  AccuvBadge,
} from '@accuv/shared-components';
import { SearchBar } from 'components/Search';
import { topBarHeight } from '../../styleVariables';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import SearchApi from 'services/Search/SearchApi';
import { useToast, ADD } from 'providers/ToastProvider';
import { useNavigate } from 'react-router-dom';

// Material UI Icons
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import HelpIcon from '@material-ui/icons/Help';
import AppsIcon from '@material-ui/icons/Apps';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: '0 1px 8px rgba(0,0,0,.3)',
    position: 'relative',
    zIndex: theme.zIndex.drawer + 100,
    [theme.breakpoints.down('md')]: {
      position: 'sticky',
    },
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  toolBar: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  toolBarTop: {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: topBarHeight,
  },
  branding: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    height: topBarHeight,
    display: 'flex',
  },
  logo: {
    margin: 'auto',
    width: '80px',
    cursor: 'pointer',
  },
  avatar: {
    height: 30,
    width: 30,
    fontSize: 14,
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
  },
  dropdownFilter: {
    position: 'absolute',
    right: 0,
    top: -6,
  },
  toolbarIconButton: {
    width: topBarHeight,
    height: topBarHeight,
    borderRadius: 0,
    position: 'relative',

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: topBarHeight,
      height: topBarHeight,
      background: theme.palette.white,
      borderRadius: '50%',
      transform: 'scale(0)',
      transition: theme.transitions.create('all', {
        duration: '.3s',
        easing: theme.transitions.easing.easeOut,
        delay: 0,
      }),
    },
    '&:hover': {
      color: theme.palette.primary.main,

      '&::before': {
        borderRadius: 0,
        transform: 'scale(1)',
      },
      '& .mainspa-MuiBadge-badge': {
        transform: 'scale(0.70) translate(35%, -35%)',
        color: theme.palette.white,
      },
    },
    '& .mainspa-MuiIconButton-label': {
      position: 'relative',
      zIndex: 1,
    },
  },
  toolbarIconButtonCircle: {
    width: topBarHeight,
    height: topBarHeight,
    borderRadius: 0,
    position: 'relative',

    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 3,
      left: 3,
      width: topBarHeight - 6,
      height: topBarHeight - 6,
      background: theme.palette.white,
      borderRadius: '50%',
      transform: 'scale(0)',
      transition: theme.transitions.create('all', {
        duration: '.3s',
        easing: theme.transitions.easing.easeOut,
        delay: 0,
      }),
    },
    '&:hover': {
      color: theme.palette.primary.main,

      '&::before': {
        transform: 'scale(1)',
      },
    },
    '& .mainspa-MuiIconButton-label': {
      position: 'relative',
      zIndex: 1,
    },
  },
  toolbarIconButtonOpen: {
    color: theme.palette.primary.main,

    '&::before': {
      borderRadius: 0,
      transform: 'scale(1)',
    },
    '& .mainspa-MuiBadge-badge': {
      transform: 'scale(0.70) translate(35%, -35%) !important',
      color: theme.palette.white,
    },
  },
  badge: {
    '& .mainspa-MuiBadge-badge': {
      background: theme.palette.danger.main,
      transform: 'scale(0.85) translate(40%, -40%)',
    },
  },
  buttonMargin: {
    marginLeft: theme.spacing(1),
  },
  programSelectorButton: {
    marginRight: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  searchWrapper: {
    flex: '1 1 auto',
    boxSizing: ' border-box',
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    height: '30px',
  },
  search: {},
}));

const searchApi = new SearchApi();

const Header = ({ logo, logoAltText, toggleFullscreen }) => {
  // console.log('Header rendering...');
  const classes = useStyles();
  const [state, dispatch] = useAppState();
  const { toastDispatch } = useToast();
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [userInitials, setUserInitials] = useState('NN');
  const [search, setSearch] = useState({ searching: false, results: null });
  const navigate = useNavigate();

  const options = [
    {
      title: 'PO Number',
      value: 'poNumber',
    },
  ];

  const authContext = useContext(AuthContext);
  authContext.getUserName().then((username) => {
    setUserInitials(getInitials(username));
  });

  const handleSearchExpandToggle = () => setSearchExpanded(!searchExpanded);

  const handleMainMenuToggle = () => {
    dispatch(toggleMainMenu());
    if (searchExpanded) handleSearchExpandToggle();
  };

  const handleUserPanelToggle = () => {
    dispatch(toggleUserPanel());
  };

  const handleHelpPanelToggle = useCallback(() => {
    dispatch(toggleHelpPanel());
  }, []);

  const handleNotificationToggle = () => {
    dispatch(toggleNotificationPanel());
    if (searchExpanded) handleSearchExpandToggle();
  };

  const handleProgramSelectorToggle = () => {
    dispatch(toggleProgramSelector());
  };

  const handleSearch = (value, type) => {
    console.log(`Search criteria: ${value} | ${type} `);
    setSearch({ ...search, searching: true });

    searchApi
      .searchPO(value)
      .then((result) => {
        console.log(result);
        let results = [];
        if (result.found === true) {
          results = [
            {
              title: `${result.PONumber} | ${result.projectName}`,
              route: `/apps/vendorManagement/purchaseOrders/${result.PONumber}`,
              program: result.projectId,
              programName: result.projectName,
            },
          ];
        }
        setSearch({
          ...search,
          searching: false,
          results: results,
        });
      })
      .catch((error) => {
        console.error(error);
        toastDispatch({
          type: ADD,
          payload: {
            content: 'An error occurred, please try again',
            type: 'error',
          },
        });
        setSearch({
          ...search,
          searching: false,
          results: null,
        });
      });
  };

  const handleCancelSearch = () => {
    console.log('Canceling search...');
    setSearch({ ...search, searching: false, results: null });
  };

  let notificationsIcon = <NotificationsIcon />;

  let notReadCount = state.unreadCount;
  if (notReadCount > 0) {
    notificationsIcon = (
      <AccuvBadge badgeContent={notReadCount} className={classes.badge}>
        <NotificationsIcon />
      </AccuvBadge>
    );
  }

  return (
    <AccuvAppBar position="static" className={classes.appBar}>
      <AccuvToolbar className={classes.toolBarTop}>
        {/* Menu button */}
        <AccuvIconButton
          className={clsx(
            classes.toolbarIconButton,
            state.mainMenuOpened && classes.toolbarIconButtonOpen
          )}
          size="small"
          color="inherit"
          aria-label="open drawer"
          onClick={handleMainMenuToggle}
          data-test="menu-button"
        >
          <AppsIcon />
        </AccuvIconButton>

        {/* Accuv icon */}
        <div className={classes.branding}>
          <img
            src={logo}
            alt={logoAltText}
            className={classes.logo}
            onClick={() => {
              navigate('/');
            }}
          />
        </div>

        {/* Search - START */}
        <Hidden xsDown>
          <div className={classes.searchWrapper}>
            <SearchBar
              className={classes.search}
              cancelOnEscape={true}
              onRequestSearch={handleSearch}
              onCancelSearch={handleCancelSearch}
              options={options}
              searching={search.searching}
              results={search.results}
            />
          </div>
        </Hidden>

        <Hidden smUp>
          <span className="flexSpacer" />
        </Hidden>

        <Hidden smUp>
          <AccuvIconButton
            color="inherit"
            className={classes.toolbarIconButton}
            onClick={handleSearchExpandToggle}
            aria-expanded={searchExpanded}
            aria-label="Show searchbar"
          >
            <SearchIcon />
          </AccuvIconButton>
        </Hidden>
        {/* Search - END */}

        {/* Full screen button */}
        <Hidden xsDown>
          <Tooltip title="Full screen" enterDelay={700}>
            <AccuvIconButton
              size="small"
              className={clsx(classes.toolbarIconButton, classes.fullscreenButton)}
              color="inherit"
              onClick={toggleFullscreen}
            >
              <FullscreenIcon />
            </AccuvIconButton>
          </Tooltip>
        </Hidden>

        {/* Notifications button */}
        <Tooltip title="Notifications" enterDelay={700}>
          <AccuvIconButton
            className={clsx(
              classes.toolbarIconButton,
              classes.buttonMargin,
              state.notificationPanelOpened && classes.toolbarIconButtonOpen
            )}
            id="notificationsButton"
            size="small"
            color="inherit"
            onClick={handleNotificationToggle}
          >
            {notificationsIcon}
          </AccuvIconButton>
        </Tooltip>

        {/* Help button */}
        <Tooltip title="Help" enterDelay={700}>
          <AccuvIconButton
            aria-label="Help"
            aria-haspopup="true"
            color="inherit"
            onClick={handleHelpPanelToggle}
            id="helpPanelButton"
            className={clsx(
              classes.toolbarIconButton,
              classes.buttonMargin,
              state.helpPanelOpened && classes.toolbarIconButtonOpen
            )}
            size="small"
          >
            <HelpIcon />
          </AccuvIconButton>
        </Tooltip>

        {/* User panel button */}
        <Tooltip title="My account" enterDelay={700}>
          <AccuvIconButton
            aria-label="User Settings"
            aria-haspopup="true"
            color="inherit"
            onClick={handleUserPanelToggle}
            id="userPanelButton"
            className={clsx(
              classes.toolbarIconButton,
              classes.buttonMargin,
              state.userPanelOpened && classes.toolbarIconButtonOpen
            )}
            size="small"
          >
            <AccuvAvatar className={classes.avatar} src={state.userProfilePicture}>
              {userInitials}
            </AccuvAvatar>
          </AccuvIconButton>
        </Tooltip>

        {/* Program selector button */}
        <Tooltip title="Program selector" enterDelay={700}>
          <AccuvButton
            aria-label="Program Selector"
            aria-haspopup="true"
            color="inherit"
            onClick={handleProgramSelectorToggle}
            className={clsx(
              classes.buttonMargin,
              classes.programSelectorButton,
              !state.monolithicModule.opened && classes.hide
            )}
            size="small"
          >
            {ProgramService.getSelectedProgramName()}
          </AccuvButton>
        </Tooltip>
      </AccuvToolbar>

      {/* Search for small screens */}
      <Hidden smUp>
        <Collapse in={searchExpanded} timeout="auto" unmountOnExit>
          <AccuvToolbar className={classes.toolBar}>
            <div className={classes.searchWrapper}>
              <SearchBar
                className={classes.search}
                cancelOnEscape={true}
                onRequestSearch={handleSearch}
                onCancelSearch={handleCancelSearch}
                options={options}
                searching={search.searching}
                results={search.results}
              />
            </div>
          </AccuvToolbar>
        </Collapse>
      </Hidden>
    </AccuvAppBar>
  );
};

Header.propTypes = {
  logo: PropTypes.string,
  logoAltText: PropTypes.string,
  toggleFullscreen: PropTypes.func,
};

export default memo(Header);
