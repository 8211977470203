import { ApiBase } from '../API';
import env from '@beam-australia/react-env';

export interface SearchResult {}

export interface IUserAccessApi {
  updateUserProfilePicture: (image: string, fileName: string) => Promise<string>;
  deleteUserProfilePicture: () => Promise<void>;
  getUserProfilePicture: () => Promise<string>;
}

// TODO: search service is not implemented yet
// const searchServiceBasePath = env('SEARCH_SERVICE_ENDPOINT');
const userAccessApiBasePath = env('USERACCESS_ENDPOINT');

class UserAccessApi extends ApiBase implements IUserAccessApi {
  constructor() {
    super(userAccessApiBasePath);
  }

  public updateUserProfilePicture = async (
    image: string,
    fileName: string
  ): Promise<string> => {
    return this.instance.post<any>(`/UserAccessProfile/UpdateUserProfilePicture`, {
      image: `${image}`,
      fileName: `${fileName}`,
    });
  };

  public deleteUserProfilePicture = async (): Promise<void> => {
    return this.instance.post<any>(`/UserAccessProfile/DeleteUserProfilePicture`);
  };

  public getUserProfilePicture = async (): Promise<string> => {
    return this.instance.get<any>(`/UserAccessProfile/GetUserProfilePicture`);
  };
}

export default UserAccessApi;
