/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IDENTITY_CONFIG } from 'constants/AuthConfig';
import { UserManager, WebStorageStateStore, Log, User } from 'oidc-client';
import env from '@beam-australia/react-env';
import ProgramService from 'services/Program/ProgramService';
import VendorCompany from 'constants/VendorCompany';
import { useCookies } from 'react-cookie';
import { monoApp } from 'services/API';

const ACCESS_TOKEN_KEY = 'bearer';
const ID_TOKEN_KEY = 'id_token';
const EXPIRES_AT_KEY = 'expires_at';
// prettier-ignore
const USER_PROFILE_KEY = `oidc.user:${env('IDENTITY_AUTH_ENDPOINT')}:${env('IDENTITY_CLIENT_ID')}`;

/**
 * Main component to handle the authentication flow
 * It's using the oidc-client library, for more information please refer to the following link
 * github.com/IdentityModel/oidc-client-js/wiki
 */
export default class AuthService {
  UserManager: UserManager;
  UserProfile: any;
  CurrentUser: any;
  removeCookie: any;

  constructor() {
    // console.log('IDENTITY_CONFIG', IDENTITY_CONFIG);
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      // metadata: {
      //   ...METADATA_OIDC,
      // },
    });

    Log.logger = console;
    Log.level = Log.ERROR;

    this.UserManager.events.addUserLoaded((user: User) => {
      console.log('User loaded!');
      console.log(user);

      this.CurrentUser = user;

      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen();
      }

      localStorage.setItem(ACCESS_TOKEN_KEY, user.access_token);
      localStorage.setItem(ID_TOKEN_KEY, user.id_token ?? '');
      localStorage.setItem(EXPIRES_AT_KEY, user.expires_at.toString());
      localStorage.setItem(USER_PROFILE_KEY, JSON.stringify(user.profile));

      if (window.location.href.indexOf('') !== -1) {
        this.navigateToScreen();
      }
    });
    this.UserManager.events.addSilentRenewError((e) => {
      console.log('Silent renew error', e.message);
    });
    this.UserManager.events.addAccessTokenExpired(() => {
      console.log('Token expired');
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {});
  };

  checkUserSession = () => {
    //Check if session is still active
    this.UserManager.getUser().then((user) => {
      if (!user) {
        console.info('User not found, redirect to signin page...');
        return this.UserManager.signinRedirect();
      }
    });
  };

  getUserProfile = () => {
    if (this.UserProfile) return this.UserProfile;

    const userProfile = localStorage.getItem(USER_PROFILE_KEY);
    if (!userProfile) {
      console.info('User not found, redirect to signin page...');
      return this.UserManager.signinRedirect();
    }
    this.UserProfile = JSON.parse(userProfile);
    return this.UserProfile;
  };

  getUserName = async () => {
    const userProfile = await this.getUserProfile();
    if (!userProfile) return '';
    return userProfile.accuv_user_completeName;
  };

  getUserEmail = async () => {
    const userProfile = await this.getUserProfile();
    if (!userProfile) return '';
    return userProfile.email;
  };

  static getAccessToken = () => {
    const access_token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!access_token) {
      console.warn('Access token not found!');
      window.location.href = '/Account/ExpiredSession';
    } else return access_token;
  };

  parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = () => {
    if (
      window.location.pathname.toLowerCase().indexOf('/account/expiredsession') === -1
    ) {
      localStorage.setItem('redirectUri', window.location.pathname);
    }
    if (window.location.pathname.toLowerCase().indexOf('/error/sessionexpired') !== -1) {
      localStorage.setItem('redirectUri', '/');
    }
    this.cleanLocalStorage();
    this.UserManager.signinRedirect({});
  };

  navigateToScreen = () => {
    let redirectUri = localStorage.getItem('redirectUri');
    // if isVendorLogin, go to vendor profile creation screen directly
    let userId = this.CurrentUser.profile.accuv_username;
    let vendorLogin = VendorCompany.find((v) => v.userId == userId);
    if (vendorLogin) {
      redirectUri = `/apps/vendorCreation?company=${vendorLogin.company}`;
      ProgramService.setSelectedProgram({
        id: vendorLogin.programId,
        name: vendorLogin.programName,
      });
    }
    if (redirectUri) {
      window.location.replace(redirectUri);
    } else {
      window.location.replace('/');
    }
  };

  isAuthenticated = () => {
    // const oidcStorage = JSON.parse(
    //   sessionStorage.getItem(
    //     `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
    //   )
    // );
    // return (!!oidcStorage && !!oidcStorage.access_token)

    const expiresAtJson = localStorage.getItem(EXPIRES_AT_KEY);
    let expiresAt = expiresAtJson ? JSON.parse(expiresAtJson) : '';
    let tokenValid = new Date().getTime() < expiresAt * 1000;
    const access_token = localStorage.getItem(ACCESS_TOKEN_KEY);
    return !!access_token && !!tokenValid;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log('Signed in silently!', user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback().catch((err) => {
      console.log(err);
    });
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest().catch((err) => {
      console.log(err);
    });
  };

  logout = async () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem(ID_TOKEN_KEY),
    }).catch((error: any) => this.handleError(error));
    this.UserManager.clearStaleState();
    this.cleanLocalStorage();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace('/');
    });
    this.UserManager.clearStaleState();
  };

  handleError = (error: any) => {
    console.error(error);
  };

  cleanLocalStorage = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(ID_TOKEN_KEY);
    localStorage.removeItem(EXPIRES_AT_KEY);
    localStorage.removeItem(USER_PROFILE_KEY);
  };
}
