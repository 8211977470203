import { Component, React } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

const label = {
  fontSize: '10px',
};

const progressBar = {
  height: '6px',
  borderRadius: '4px',
};

class LinearProgressBarWithLabel extends Component {
  render() {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress
            style={progressBar}
            variant={this.props.variant || 'determinate'}
            value={this.props.value}
            color={this.props.color || 'secondary'}
          />
        </Box>
        <Box minWidth={15}>
          <Typography style={label} variant="body2" color="textSecondary">{`${Math.round(
            this.props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
}

LinearProgressBarWithLabel.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.number,
};

export default LinearProgressBarWithLabel;
