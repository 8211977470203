/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AccuvListItem,
  AccuvListItemIcon,
  AccuvListItemText,
  AccuvTypography,
} from '@accuv/shared-components';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { truncateText } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: `1px solid ${theme.palette.gray.border}`,
    background: '#F0F1F8',
  },
  listItemRead: {
    background: theme.palette.white,
  },
  listItemAnnouncements: {
    alignItems: 'flex-start',
    minHeight: '75px',
  },
  notificationTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  notificationBody: {
    fontSize: 12,
    color: theme.palette.gray.light,
  },
  notificationTitleRead: {
    fontWeight: 'normal',
  },
  inlineFields: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginTop: theme.spacing(1),
  },
  link: {
    color: theme.palette.secondary.main,
    fontSize: 12,
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
  },
  disabledLink: {
    pointerEvents: 'none',
    color: theme.palette.gray.disabled,
  },
  noMarginTop: {
    marginTop: 0,
  },
  exportDateTime: {
    fontSize: 12,
    color: theme.palette.gray.light,
  },
  exportStatus: {
    marginLeft: 'auto',
    fontSize: 10,
    textTransform: 'capitalize',
  },
  announcementIconWrapper: {
    minWidth: 24,
    marginTop: 6,
  },
  infoPriority: {
    color: theme.palette.info.main,
    fontSize: 22,
  },
  lowPriority: {
    color: theme.palette.success.main,
    fontSize: 16,
  },
  mediumPriority: {
    color: theme.palette.warning.main,
    fontSize: 16,
  },
  highPriority: {
    color: theme.palette.danger.main,
    fontSize: 16,
  },
  label: {
    fontSize: 11,
    color: theme.palette.gray.light,
    marginRight: theme.spacing(0.5),
    flex: '0 0 auto',
  },
  value: {
    fontSize: 11,
    color: theme.palette.gray.text,
    marginRight: theme.spacing(1),
    flex: '0 1 auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  date: {
    fontSize: 10,
    color: theme.palette.gray.text,
    marginLeft: 'auto',
  },
}));

function getIcon(priority, classes) {
  switch (priority) {
    case 'info':
      return <InfoIcon className={classes.infoPriority} />;
    case 'low':
      return <CheckCircleIcon className={classes.lowPriority} />;
    case 'medium':
      return <ErrorIcon className={classes.mediumPriority} />;
    case 'high':
      return <ReportProblemOutlinedIcon className={classes.highPriority} />;
    default:
      return <CheckCircleIcon />;
  }
}

const AnnouncementNotification = ({ notification, onClick }) => {
  const classes = useStyles();

  return (
    <AccuvListItem
      button
      className={clsx(
        classes.listItem,
        classes.listItemAnnouncements,
        notification.read === true && classes.listItemRead
      )}
      onClick={() => {
        onClick();
        if (notification.payload.route) {
          window.open(notification.payload.route, '_blank');
        }
        console.log('Announcement notification click');
      }}
    >
      <AccuvListItemIcon className={classes.announcementIconWrapper}>
        {getIcon(notification.payload.priority, classes)}
      </AccuvListItemIcon>
      <AccuvListItemText>
        <AccuvTypography
          className={clsx(
            classes.notificationTitle,
            notification.read === true && classes.notificationTitleRead
          )}
        >
          {notification.title}
        </AccuvTypography>
        <AccuvTypography className={classes.notificationBody}>
          {truncateText(notification.payload.description, 100, true)}
        </AccuvTypography>
      </AccuvListItemText>
    </AccuvListItem>
  );
};

AnnouncementNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    dateTime: PropTypes.string,
    read: PropTypes.bool,
    payload: PropTypes.object,
  }),
  onClick: PropTypes.func,
};

export default AnnouncementNotification;
