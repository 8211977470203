import React, { useContext } from 'react';
import AuthService from 'services/Authentication/AuthService';
import PropTypes from 'prop-types';

export const AuthContext = React.createContext({
  signinRedirectCallback: () => {},
  logout: () => {},
  signoutRedirectCallback: () => {},
  isAuthenticated: () => {},
  signinRedirect: () => {},
  signinSilentCallback: () => {},
  createSigninRequest: () => {},
  getUserProfile: () => {
    return {};
  },
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = (props) => {
  let authService = new AuthService();

  return (
    <AuthContext.Provider value={authService}>{props.children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element,
};
