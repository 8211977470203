import { HubConnectionState, IHttpConnectionOptions } from '@microsoft/signalr';
import { createConnection } from './createConnection';
import { lookup, invalidate, cache } from './cache';

// const signalrEndpoint = 'https://localhost:44345';

const connectToSignalr = async (hubFullUrl: string, options: IHttpConnectionOptions) => {
  // const hubUrl = `${signalrEndpoint}/${hub}`;
  const hubUrl = hubFullUrl;
  let connection = lookup(hubUrl);

  if (!connection) {
    connection = createConnection(hubUrl, options);
    // when the connection closes
    connection.onclose(() => {
      // remove the connection from the cache
      invalidate(hubUrl);
    });
    // add the connection to the cache
    cache(hubUrl, connection);
  }

  try {
    if (connection.state === HubConnectionState.Disconnected) {
      await connection.start();
      console.log(`SignalR connection established to hub ${hubUrl}`);
    }
    console.assert(connection.state === HubConnectionState.Connected);
  } catch (err) {
    console.assert(connection.state === HubConnectionState.Disconnected);
    console.error('SignalR Connection Error: ', err);
    //Do we want to try to connect automatically after 5 seconds?
    setTimeout(() => connectToSignalr(hubUrl, options), 5000);
  }

  return connection;
};

export { connectToSignalr };
