/* eslint-disable no-undef */
export default [
  {
    title: 'Project Mgmt - Jobs Export',
    subtitle: 'May 4, 2020 10:27am (23sec) - AT&T Turf',
    status: 'Running',
    read: false,
  },
  {
    title: 'Project Mgmt - Jobs Export',
    subtitle: 'Apr 12, 2020 2:27pm (23sec) - Nexius',
    status: 'Completed',
    read: false,
  },
  {
    title: 'Scope Mgmt - Jobs Export',
    subtitle: 'Mar 25, 2020 12:45am (269sec) - Demo',
    status: 'Completed',
    read: true,
  },
  {
    title: 'Work Order Mgmt - Jobs Export',
    subtitle: 'May 4, 2020 10:27am (0.00sec) - Verizon',
    status: 'Error',
    read: true,
  },
  {
    title: 'Purchase Orders - Invoices',
    subtitle: 'May 4, 2020 10:27am (23sec) - AT&T Turf',
    status: 'Completed',
    read: true,
  },
  {
    title: 'Work Order Mgmt - Jobs Export',
    subtitle: 'May 4, 2020 10:27am (23sec) - AT&T Turf',
    status: 'Completed',
    read: true,
  },
];
