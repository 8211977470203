import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  AccuvContainer,
  AccuvButton,
  AccuvStatusMessageBox,
  AccuvIcon,
} from '@accuv/shared-components';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { useAppState } from 'providers/AppProvider';
import { selectGroup } from 'providers/AppProvider/ActionCreators';
import { getAppsRoutes } from 'routes/Routes';
import { moduleInRoute } from 'services/Authorization/UserAccess';

const useStyles = makeStyles((theme) => ({
  root: {},
  flexContainer: {
    padding: '0px',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    marginLeft: -9,
    marginRight: -9,
    marginBottom: 30,
  },
  blockMenuItemRootSelected: {
    boxShadow: 'none !important',
    border: '1px solid #5839A0 !important',
    background: '#FFF !important',
    color: '#404040 !important',
  },
  blockMenuItemRoot: {
    marginLeft: 9,
    marginRight: 9,
    marginTop: 5,
    marginBottom: 15,
    height: 100,
    borderRadius: 5,
    flex: '0 0 140px',
    background: '#FFF',
    color: '#404040',
    border: '1px solid #FFFFFF',
    transition: theme.transitions.create('all', {
      duration: '.3s',
      easing: theme.transitions.easing.easeOut,
      delay: 0,
    }),
    '&:last-child': {
      marginRight: 0,
    },
    '& .mainspa-MuiButton-label': {
      display: 'block',
    },
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px #FFFFFF, inset 0 -1px 0 #FFFFFF',
      border: '1px solid #5839A0',
      background: '#FFF',
      color: '#404040',
    },
  },
  blockMenuItemSkeleton: {
    marginLeft: 9,
    marginRight: 9,
    marginBottom: 15,
    height: 100,
    borderRadius: 5,
    flex: '0 0 140px',
    background: '#FFF',
    color: '#404040',
    transition: theme.transitions.create('all', {
      duration: '.3s',
      easing: theme.transitions.easing.easeOut,
      delay: 0,
    }),
    '&:last-child': {
      marginRight: 0,
    },
    '& .mainspa-MuiButton-label': {
      display: 'block',
    },
  },
  iconContainer: {
    height: 50,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  labelContainer: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelInnerContainer: {
    fontSize: 13,
    lineHeight: 1.2,
    overflow: 'hidden',
    textAlign: 'center',
    whiteSpace: 'normal',
    textTransform: 'capitalize',
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: 35,
  },
  skeleton: {
    width: '100%',
  },
}));

const FullPageMenu = () => {
  const classes = useStyles();
  const [state, dispatch] = useAppState();
  const groups = state.groups;
  const group = state.group;
  const apps = getAppsRoutes();
  let groupsButtons;
  let menu;
  const handelGroupSelector = (selectedGroup) => {
    if (group && group.Id == selectedGroup.Id) {
      selectedGroup = null;
    }
    dispatch(selectGroup(selectedGroup));
  };
  const createNavButton = (index, route, parent) => {
    return (
      <AccuvButton
        component={Link}
        to={`/${parent}/${route.path}`}
        key={index}
        variant="contained"
        color="secondary"
        className={classes.blockMenuItemRoot}
        classes={{
          containedPrimary: clsx(classes.buttonBlockMenuItem),
        }}
      >
        <div className={clsx(classes.iconContainer, classes.icon)}>
          {route.iconId ? (
            <AccuvIcon icon={route.iconId} className={classes.icon} />
          ) : (
            <route.icon className={classes.icon} />
          )}
        </div>
        <div className={classes.labelContainer}>
          <div className={classes.labelInnerContainer}>{route.name}</div>
        </div>
      </AccuvButton>
    );
  };
  const createGroupButton = (index, group) => {
    return (
      <AccuvButton
        key={index}
        onClick={() => handelGroupSelector(group)}
        variant="contained"
        color="secondary"
        className={clsx(
          classes.blockMenuItemRoot,
          state.group && state.group.Id == group.Id
            ? classes.blockMenuItemRootSelected
            : ''
        )}
        classes={{
          containedPrimary: clsx(classes.buttonBlockMenuItem),
        }}
      >
        <div className={clsx(classes.iconContainer, classes.icon)}>
          {group.Icon ? (
            <AccuvIcon icon={group.Icon} className={classes.icon} />
          ) : (
            <AccuvIcon icon="default" className={classes.icon} />
          )}
        </div>
        <div className={classes.labelContainer}>
          <div className={classes.labelInnerContainer}>{group.Name}</div>
        </div>
      </AccuvButton>
    );
  };

  if (!state.groups) {
    // Show the skeleton if the items are not available yet
    const skeletonItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    menu = skeletonItems.map((index) => (
      <Skeleton variant="rect" key={index} className={classes.blockMenuItemSkeleton} />
    ));
  } else if (state.permissions === 'error') {
    return (
      <div className={classes.error}>
        <AccuvStatusMessageBox>
          An error occurred loading the menu, please try again.
        </AccuvStatusMessageBox>
      </div>
    );
  } else {
    groupsButtons = groups.map((group, index) => {
      if (group.IsGroup) {
        return createGroupButton(index, group);
      } else {
        const route = moduleInRoute(group.Id, apps);
        return route ? createNavButton(index, route, 'apps') : null;
      }
    });
    menu = groupsButtons;
  }

  return (
    <>
      <AccuvContainer className={classes.flexContainer}>{menu}</AccuvContainer>
    </>
  );
};

FullPageMenu.propTypes = {};

export default FullPageMenu;
