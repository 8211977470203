const VendorCompany = [
  {
    userId: 'vendor.vlx',
    company: 'VLX',
    programId: 'vlx',
    programName: 'Velex',
  },
  {
    userId: 'vendor.allios',
    company: 'ALLIOS',
    programId: 'allios',
    programName: 'Allios',
  },
  {
    userId: 'vendor.gc',
    company: 'NEXS_INT',
    programId: 'nexs_int',
    programName: 'Nexius',
  },
  {
    userId: 'vendor.vlxsi',
    company: 'VLX_SI',
    programId: 'vlxsi',
    programName: 'Velex SI',
  },
];

export default VendorCompany;
