/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import {
  AccuvDialog,
  AccuvDialogContent,
  AccuvStatusMessageBox,
  AccuvDialogActions,
  AccuvButton,
} from '@accuv/shared-components';
import { makeStyles } from '@material-ui/core/styles';
import { ImportResultsComponents } from 'components/BulkImport/ImportResults';

const useStyles = makeStyles((theme) => ({
  monoContainer: {
    display: 'none',
  },
  message: {
    paddingBottom: 0,
  },
}));

const ImportResultsPanel = ({ importItem, open, handleClose }) => {
  const classes = useStyles();

  if (!importItem) return null;

  let ImportResultsComponent = ImportResultsComponents(importItem.title);
  let importResults = null;

  if (ImportResultsComponent) {
    importResults = (
      <ImportResultsComponent
        id={importItem.id}
        handleClose={handleClose}
        type={importItem.title}
      ></ImportResultsComponent>
    );
  }

  return (
    <AccuvDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AccuvDialogContent primaryTextColor>
        <AccuvStatusMessageBox className={classes.message} loading={!!importResults}>
          {importResults
            ? 'Loading import results...'
            : 'import results not implemented yet'}
        </AccuvStatusMessageBox>
        <div className={classes.monoContainer}>{importResults}</div>
      </AccuvDialogContent>
      <AccuvDialogActions>
        <AccuvButton onClick={handleClose} variant="text" color="primary">
          Close
        </AccuvButton>
      </AccuvDialogActions>
    </AccuvDialog>
  );
};

ImportResultsPanel.propTypes = {
  importItem: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default React.memo(ImportResultsPanel);
