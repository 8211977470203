import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import {
  AccuvContainer,
  AccuvButton,
  AccuvTypography,
  AccuvAvatar,
} from '@accuv/shared-components';
import { Container } from '@material-ui/core';
import { Wrapper } from 'components';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ProgramService from 'services/Program/ProgramService';
import { getInitials } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {},
  flexContainer: {
    padding: '0px',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    marginLeft: -9,
    marginRight: -9,
    marginBottom: 30,
  },
  blockMenuItemRoot: {
    marginLeft: 9,
    marginRight: 9,
    marginTop: 5,
    marginBottom: 15,
    height: 100,
    borderRadius: 5,
    flex: '0 0 140px',
    background: '#FFF',
    color: '#404040',
    border: '1px solid #FFFFFF',
    transition: theme.transitions.create('all', {
      duration: '.3s',
      easing: theme.transitions.easing.easeOut,
      delay: 0,
    }),
    '&:last-child': {
      marginRight: 0,
    },
    '& .mainspa-MuiButton-label': {
      display: 'block',
    },
    '&:hover': {
      boxShadow: 'inset 0 0 0 1px #FFFFFF, inset 0 -1px 0 #FFFFFF',
      border: '1px solid #5839A0',
      background: '#FFF',
      color: '#404040',
    },
  },
  blockMenuItemSkeleton: {
    marginLeft: 9,
    marginRight: 9,
    marginBottom: 15,
    height: 100,
    borderRadius: 5,
    flex: '0 0 140px',
    background: '#FFF',
    color: '#404040',
    transition: theme.transitions.create('all', {
      duration: '.3s',
      easing: theme.transitions.easing.easeOut,
      delay: 0,
    }),
    '&:last-child': {
      marginRight: 0,
    },
    '& .mainspa-MuiButton-label': {
      display: 'block',
    },
  },
  iconContainer: {
    height: 50,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  labelContainer: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelInnerContainer: {
    fontSize: 13,
    lineHeight: 1.2,
    overflow: 'hidden',
    textAlign: 'center',
    whiteSpace: 'normal',
    textTransform: 'capitalize',
  },
  icon: {
    color: '#5839A0',
    fontSize: 35,
  },
  skeleton: {
    width: '100%',
  },
  avatar: {
    height: 30,
    width: 30,
    fontSize: 14,
    color: '#5839A0', //theme.palette.primary.main,
    backgroundColor: '#eef',
  },
  content: {
    padding: `40px ${theme.spacing(1)}px`,
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    minHeight: '100%',
  },
  message: {
    fontSize: 20,
    fontWeight: 'normal',
    marginBottom: 15,
  },
  message2: {
    fontSize: 15,
    fontWeight: 'normal',
    marginBottom: 15,
  },
}));

const PowerBiSelectorFullPage = ({ items }) => {
  const selectedProgram = ProgramService.getSelectedProgramId();
  const classes = useStyles();

  const addButton = (item) => {
    return (
      <AccuvButton
        component={Link}
        to={`/apps/dashboards/${selectedProgram}/${
          item.value.startsWith('Dashboard') ? 'dashboard' : 'report'
        }/${item.id}`}
        key={item.text}
        variant="contained"
        color="secondary"
        className={classes.blockMenuItemRoot}
        classes={{
          containedPrimary: clsx(classes.buttonBlockMenuItem),
        }}
      >
        <div className={clsx(classes.iconContainer, classes.icon)}>
          <AccuvAvatar className={classes.avatar}>
            {getInitials(item.title ? item.title : item.text)}
          </AccuvAvatar>
        </div>
        <div className={classes.labelContainer}>
          <div className={classes.labelInnerContainer}>
            {item.title ? item.title : item.text}
          </div>
        </div>
      </AccuvButton>
    );
  };

  if (items && items.length == 0) {
    // TODO: make it looks better
    return (
      <Wrapper padding>
        <Container className={classes.content}>
          <div data-test="welcome-container">
            <AccuvTypography className={classes.message}>
              Seems like you do not have any dashboard for this program, try switching to
              another one.
            </AccuvTypography>
          </div>
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper padding>
      <Container className={classes.content}>
        <div data-test="welcome-container">
          <AccuvTypography className={classes.message}>
            Please select a Dashboard
          </AccuvTypography>
        </div>

        <AccuvContainer className={classes.flexContainer}>
          {items
            .filter((item) => !item.items)
            .map((item) => {
              const theItem = item;
              return addButton(theItem);
            })}
        </AccuvContainer>

        <AccuvContainer className={classes.flexContainer}>
          {items
            .filter((item) => item.items)
            .map((item) => {
              const theItem = item;
              return (
                <>
                  <AccuvContainer>
                    <AccuvTypography className={classes.message2}>
                      {item.title ? item.title : item.text}
                    </AccuvTypography>
                  </AccuvContainer>
                  {theItem.items.map((i) => {
                    const theItem2 = i;
                    return addButton(theItem2);
                  })}
                </>
              );
            })}
        </AccuvContainer>
      </Container>
    </Wrapper>
  );
};

PowerBiSelectorFullPage.propTypes = {
  items: PropTypes.array,
  programs: PropTypes.object,
};

export default PowerBiSelectorFullPage;
