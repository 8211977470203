/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AccuvListItem,
  AccuvListItemText,
  AccuvTypography,
  AccuvLink,
} from '@accuv/shared-components';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { ImportResultsRoutes } from 'components/BulkImport/ImportResults';
import BackgroundJobStates from 'constants/BackgroundJobStates';
import { useToast, ADD } from 'providers/ToastProvider';
import ApplicationApi from 'services/Application/ApplicationApi';
import LinearProgressBarWithLabel from '../../Progress/LinearProgressBarWithLabel';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: `1px solid ${theme.palette.gray.border}`,
    background: '#F0F1F8',
  },
  listItemRead: {
    background: theme.palette.white,
  },
  notificationTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  notificationTitleRead: {
    fontWeight: 'normal',
  },
  inlineFields: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
    marginTop: theme.spacing(1),
  },
  link: {
    color: theme.palette.secondary.main,
    fontSize: 12,
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
  },
  disabledLink: {
    pointerEvents: 'none',
    color: theme.palette.gray.disabled,
  },
  exportDateTime: {
    fontSize: 12,
    color: theme.palette.gray.light,
  },
  exportStatus: {
    marginLeft: 'auto',
    fontSize: 10,
    textTransform: 'capitalize',
  },
}));

const progressBar = {
  height: '6px',
  borderRadius: '4px',
};

const applicationApi = new ApplicationApi();

const ImportNotification = ({ notification, onClick }) => {
  const classes = useStyles();
  const { toastDispatch } = useToast();

  let importResultsRoute = '';
  // Check if the title starts with "Firming PO"
  if (notification.title.startsWith('Firming PO')) {
    importResultsRoute = `${ImportResultsRoutes['Scoping Management - Firming PO']}/${notification.id}`;
  } else {
    // Use the ImportResultsRoutes object to map other titles
    importResultsRoute = `${ImportResultsRoutes[notification.title]}/${notification.id}`;
  }

  const errorDetails = () => {
    toastDispatch({
      type: ADD,
      payload: {
        content: {
          title: `Error on ${notification.title}`,
          description: notification.payload.error,
        },
        type: 'alert',
      },
    });
  };

  const cancel = () => {
    console.log(`process for id: ${notification.id} was sent to be cancelled`);
    applicationApi
      .cancellingBackgroundJob(notification.id)
      .then((x) => {
        console.log(`${x}`);
      })
      .catch((error) => {
        console.error(error);
        toastDispatch({
          type: ADD,
          payload: {
            content: 'Error cancelling the import',
            type: 'error',
          },
        });
      });
  };

  return (
    <AccuvListItem
      className={clsx(
        classes.listItem,
        notification.read === true && classes.listItemRead
      )}
      onClick={() => {
        onClick();
        console.log('Import notification click');
      }}
    >
      <AccuvListItemText>
        <AccuvTypography
          className={clsx(
            classes.notificationTitle,
            notification.read === true && classes.notificationTitleRead
          )}
        >
          {notification.title}
        </AccuvTypography>
        <AccuvTypography className={classes.exportDateTime}>
          <Moment fromNow>{notification.dateTime}</Moment>
          {notification.payload.programName
            ? ` - ${notification.payload.programName}`
            : notification.payload.program
            ? ` - ${notification.payload.program}`
            : null}
        </AccuvTypography>
        {(notification.payload.status.toLowerCase() === BackgroundJobStates.RUNNING ||
          notification.payload.status.toLowerCase() === BackgroundJobStates.RETRYING) &&
          notification.payload.percentage > 0 && (
            <LinearProgressBarWithLabel value={notification.payload.percentage} />
          )}
        {(notification.payload.status.toLowerCase() === BackgroundJobStates.RUNNING ||
          notification.payload.status.toLowerCase() === BackgroundJobStates.RETRYING) &&
          notification.payload.percentage == 0 && (
            <LinearProgress
              style={progressBar}
              variant="indeterminate"
              color="secondary"
            />
          )}
        {notification.payload.status.toLowerCase() === BackgroundJobStates.COMPLETED && (
          <LinearProgressBarWithLabel value={100} />
        )}
        {(notification.payload.status.toLowerCase() === BackgroundJobStates.CANCELLING ||
          notification.payload.status.toLowerCase() === BackgroundJobStates.ENQUEUED) && (
          <LinearProgress style={progressBar} variant="indeterminate" color="secondary" />
        )}
        <div className={classes.inlineFields}>
          {notification.payload.status.toLowerCase() !== BackgroundJobStates.FAILED &&
            notification.payload.results && (
              <Link
                className={clsx(
                  classes.link,
                  notification.payload.status.toLowerCase() !==
                    BackgroundJobStates.COMPLETED && classes.disabledLink
                )}
                to={importResultsRoute}
                target="_blank"
              >
                Results
              </Link>
            )}
          {notification.payload.status.toLowerCase() === BackgroundJobStates.FAILED && (
            <AccuvLink className={classes.link} underline="none" onClick={errorDetails}>
              Error Details
            </AccuvLink>
          )}
          {notification.payload.cancellationEnabled &&
            (notification.payload.status.toLowerCase() === BackgroundJobStates.ENQUEUED ||
              notification.payload.status.toLowerCase() ===
                BackgroundJobStates.RUNNING) && (
              <AccuvLink
                className={
                  notification.payload.isCancellationRequested
                    ? classes.disabledLink
                    : classes.link
                }
                underline="none"
                onClick={cancel}
              >
                Cancel
              </AccuvLink>
            )}
          <AccuvTypography className={classes.exportStatus}>
            {notification.payload.status}
          </AccuvTypography>
        </div>
      </AccuvListItemText>
    </AccuvListItem>
  );
};

ImportNotification.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    dateTime: PropTypes.string,
    read: PropTypes.bool,
    payload: PropTypes.object,
  }),
  onClick: PropTypes.func,
};

export default ImportNotification;
